import React from "react";
import * as Helpers from "libs/Helpers";

class UserPanel extends React.Component {
  render() {
    const user = this.props.data;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-xs-3">
            <div className="data-label">First Name</div>
            <div className="static-field">{user.firstName}</div>
          </div>
          <div className="col-xs-3">
            <div className="data-label">Middle Name</div>
            <div className="static-field">{user.middleName}</div>
          </div>
          <div className="col-xs-6">
            <div className="data-label">Last Name</div>
            <div className="static-field">{user.lastName}</div>
          </div>
        </div>

        <div className="row mgt">
          <div className="col-xs-6">
            <div className="data-label">Email</div>
            <div className="static-field">
              {
                user.email != null && user.email !== "" &&
                (<a href={`mailto:${user.email}`}>{user.email}</a>)
              }
              {
                (user.email == null || user.email === "") &&
                (<span>---</span>)
              }
            </div>
          </div>
          <div className="col-sm-6">
            <div className="data-label">Mobile</div>
            <div className="static-field">
              {
                user.mobile != null && user.mobile !== "" &&
                (<a href={`tel:${user.mobile}`}>{user.mobile}</a>)
              }
              {
                (user.mobile == null || user.mobile === "") &&
                (<span>---</span>)
              }
            </div>
          </div>
        </div>

        <div className="row mgt">
          <div className="col-xs-12">
            <div className="data-label">Street Address</div>
            <div className="static-field">{user.streetAddress === "" ? "---" : user.streetAddress}</div>
          </div>
        </div>

        <div className="row mgt mgb">
          <div className="col-xs-6">
            <div className="data-label">Suburb</div>
            <div className="static-field">{Helpers.isNull(user.suburb, "---")}</div>
          </div>
          <div className="col-xs-3">
            <div className="data-label">State</div>
            <div className="static-field">{Helpers.isNull(user.state, "---")}</div>
          </div>
          <div className="col-xs-3">
            <div className="data-label">Postcode</div>
            <div className="static-field">{Helpers.isNull(user.postcode, "---")}</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UserPanel;