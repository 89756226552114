import React from "react";
import { FormControl, FormHelperText, TextField, Select, InputLabel, MenuItem } from "@material-ui/core";

class CreateStep2 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="pdb-lg">
          <FormControl fullWidth>
            <InputLabel
              error={this.props.inputStates.locationType === "error"}
              id="area-type-label">
                Area*
            </InputLabel>
            <Select
              labelId="area-type-label"
              error={this.props.inputStates.locationType === "error"}
              value={this.props.locationType ?? ""}
              onChange={(e) => this.props.handleChange("locationType", e.target.value, true)}
            >
              <MenuItem key="-1" value="">
                <em>...</em>
              </MenuItem>
              {
                this.props.locationTypeOptions.map((item, index) => 
                  <MenuItem key={index} value={item.value2}>{item.value2}</MenuItem>)
              }
            </Select>
          </FormControl>
          <FormHelperText error>{this.props.inputErrors.locationType}</FormHelperText>
          
          <div className={`${(this.props.locationType ?? "").toLowerCase().startsWith("other") ? "" : "hidden"}`}>
            <TextField
              fullWidth
              error={this.props.inputStates.subLocationType === "error"}
              label="Please Specify*"
              id="sub-location-type"
              onChange={(e) => this.props.handleChange("subLocationType", e.target.value, true)}
              value={this.props.subLocationType}
              autoComplete= "off"
              helperText={this.props.inputErrors.subLocationType}
            />
          </div>
        </div>

        <div className="pdb-lg">
          <FormControl fullWidth>
            <InputLabel
              error={this.props.inputStates.assetType === "error"}
              id="asset-type-label">
                Asset Type*
            </InputLabel>
            <Select
              labelId="asset-type-label"
              error={this.props.inputStates.assetType === "error"}
              value={this.props.assetType ?? ""}
              onChange={(e) => this.props.handleChange("assetType", e.target.value, true)} 
            >
              <MenuItem key="-1" value="">
                <em>...</em>
              </MenuItem>
              {
                this.props.assetTypeOptions.map((item, index) => 
                  <MenuItem key={index} value={item.value2}>{item.value2}</MenuItem>)
              }
            </Select>
          </FormControl>
          <FormHelperText error>{this.props.inputErrors.assetType}</FormHelperText>
          
          <div className={`${(this.props.assetType ?? "").toLowerCase().startsWith("other") ? "" : "hidden"}`}>
            <TextField
              fullWidth
              error={this.props.inputStates.subAssetType === "error"}
              label="Please Specify"
              id="sub-asset-type"
              onChange={(e) => this.props.handleChange("subAssetType", e.target.value, true)}
              value={this.props.subAssetType}
              autoComplete= "off"
              helperText={this.props.inputErrors.subAssetType}
            />
          </div>
        </div>

        <div className="pdb-lg">
          <TextField
            error={this.props.inputStates.assetName === "error"}
            label="Asset Name*"
            id="asset-name"
            fullWidth
            onChange= {(e) => this.props.handleChange("assetName", e.target.value, true)}
            value= {this.props.assetName}
            autoComplete= "off"
            helperText={this.props.inputErrors.assetName}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default CreateStep2;