import React from "react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import * as Enums from "libs/Enums";
import * as Helpers from "libs/Helpers";
import * as Utils from "libs/Utils";
import strataApiConfig from "config-api-STRATA";
import { FormControlLabel, Checkbox, withStyles } from "@material-ui/core";
import CheckBox from '@material-ui/icons/CheckBox';
import MUIDataTable from "mui-datatables";

class IssueSuppliers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorized: true,
      type: this.props.type,
      suppliers: null,
      downloadFileName: ""
    };
  }

  async componentDidMount() {
    await this.loadSuppliers();
  }

  async loadSuppliers() {
    //this.props.setLoading(true);
    const suppliers = await this.getSuppliers();
    this.setState({ suppliers });
    //this.props.setLoading(false);
  }

  async getSuppliers() {
    Amplify.configure(strataApiConfig);

    let users = [];
    
    try {
      const params = {};

      const cmd = `
        query {
          users: listUsers(
            limit: 10000
          ){
            items {
              id
              contact_mobile
              contact_email
              person_firstName
              person_middleName
              person_lastName
              personas {
                items {
                  roleType
                  roleSubType
                  actors {
                    items {
                      foreignId
                      foreignType
                      raci_isResponsible
                      plan {
                        id
                        parentId
                        planName
                        parentPlan {
                          id
                          planName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`;

      const result = await API.graphql(graphqlOperation(cmd, params));

      console.log(result);

      if (result.data != null && result.data.users.items.length > 0) { 
        users = result.data.users.items; 
      }
    } catch (e) {
      console.log(e);
      //alert(JSON.stringify(e));
      if (e.hasOwnProperty("data") && e.data != null && e.data.users != null) {
        users = e.data.users.items;
      }
    }

    users = users.filter(x => x.personas.items.find(y => y.roleType === "supplier" && y.actors.items.find(z => z.foreignType === "strata")));
    users = users.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);

    return users.map((data) => {
      return {
        id: data.id,
        name: data.person_firstName + " " + Helpers.isNull(data.person_middleName, "") + " " + data.person_lastName,
        mobile: data.contact_mobile,
        email: data.contact_email,
        roleType: data.personas.items.find(x => x.roleType === "supplier").roleType,
        roleSubType: data.personas.items.find(x => x.roleType === "supplier").roleSubType
      };
    });
  }

  handleAssignSupplier = async (userId) => {
    const selectedActor = this.props.selectedSuppliers.find(x => x.userId === userId);

    if(selectedActor?.raci_isResponsible){
      window.alert("This supplier has already been assigned.");
    }
    else{
      if(!window.confirm('Are you sure you want to assign this tradesman?')){
        return;
      }

      this.props.setLoading(true);
      try {
        let resp = null;

        if(selectedActor == null){
          resp = Utils.AssignTicketSupplier(this.props.issueId, userId);
        } else {
          resp = Utils.notifyActor(selectedActor.id);
        }
        
        // console.log(resp);
      } catch (e) {
        console.log(e);
      }

      await this.props.loadTxn();
      this.props.setLoading(false);
    }
  }
  
  render() {
    const GreenCheckbox = withStyles({
      root: {
        color: "#9e9e9e",
        '&$checked': {
          color: "#5fbb47",
        },
      },
      checked: {},
    })(props => <Checkbox icon={<CheckBox />} color="default" {...props} />);

    const columns = [
      {
       name: "id",
       label: "---",
       options: {
        display: "excluded",
        filter: false,
        sort: false
       }
      },
      {
       name: "name",
       label: "Name",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Name: ${v}` },
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <a href={`/users/detail/${tableMeta.rowData[0]}`} target="_blank">{value}</a>
            );
          }
        }
      },
      {
       name: "email",
       label: "Email",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Email: ${v}` },
        filterType: "textField"
       }
      },
      {
       name: "mobile",
       label: "Mobile",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Mobile: ${v}` },
        filterType: "textField"
       }
      },
      {
       name: "roleSubType",
       label: "Category",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Category: ${v}` }
       }
      },
      {
        name: "id",
        label: "Assign",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const isChecked = this.props.selectedSuppliers.find(x => x.userId === value && x.raci_isResponsible) != null;
            return (
              <FormControlLabel
                className="select-supplier-check"
                control={
                  <GreenCheckbox
                    checked={isChecked}
                    onChange={async e => {e.preventDefault(); await this.handleAssignSupplier(value);}}
                    value={value}
                  />
                }
              />
            );
          }
        }
      },
     ];

    const options = {
      filterType: "dropdown",
      selectableRows: false,
      responsive: "stacked",
      elevation: 0,
      rowHover: false,
      rowsPerPage: 5,
      rowsPerPageOptions: [5,10,50],
      searchOpen: false,
      print: false,
      download: false,
      viewColumns: false,
      hint: ""
    };

    return (
      <div className="pdb-lg">
        {
          this.state.suppliers == null 
          ? <div className="pdt-lg pdb-sm text-center"><img src="/images/loading.svg" /></div>
          : <MUIDataTable
              data={this.state.suppliers}
              columns={columns}
              options={options}
            />
        }
      </div>     
    );
  }
}

export default IssueSuppliers;
