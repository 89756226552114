import React, { useState } from 'react';
import { Cache } from "aws-amplify";

export default (props) => {
  const [openItem1, setOpenItem1]= useState(false);

  const name = Cache.getItem("name");
  const url = window.location.pathname.toLowerCase();

  return(
    <ul className="menu">
      <li>
        <a href="/profile">
          <span className="icon"><img src="/images/icons/person-24px.svg" alt="" /></span>
          <span className="desc">{name}</span>
        </a>
      </li>

      <li>
        <a href="/dashboard" className={url === "/dashboard" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/dashboard-24px.svg" alt="" /></span>
          <span className="desc">Dashboard</span>
        </a>
      </li>

      <li>
        <a href="/quotes/workorders" className={url === "/quotes/workorders" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/playlist_add_check-24px.svg" alt="" /></span>
          <span className="desc">My Jobs</span>
        </a>
      </li>

      <li>
        <a href="/quotes/requests" className={url === "/quotes/requests" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/attach_money-24px.svg" alt="" /></span>
          <span className="desc">Quotation Requests</span>
        </a>
      </li>

      <li>
        <a href="/ratings" className={url === "/ratings" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/grade-24px.svg" alt="" /></span>
          <span className="desc">My Ratings</span>
        </a>
      </li>

      <li>
        <a href="/reports" className={url === "/reports" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/insert_chart-24px.svg" alt="" /></span>
          <span className="desc">Reports</span>
        </a>
      </li>

      <li>
        <a href="/settings" className={url === "/settings" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/settings_applications-24px.svg" alt="" /></span>
          <span className="desc">Settings</span>
        </a>
      </li>

      <li>
        <a href="/help" className={url === "/help" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/help_outline-24px.svg" alt="" /></span>
          <span className="desc">Help Centre</span>
        </a>
      </li>

      <li>
        <a href="/logout">
          <span className="icon"><img src="/images/icons/lock-24px.svg" alt="" /></span>
          <span className="desc">Logout</span>
        </a>
      </li>
    </ul>
  );
};