import React from "react";
import { Cache } from "aws-amplify";
import DateFnsUtils from '@date-io/date-fns';
import * as Helpers from "libs/Helpers";
import { FormControl, FormHelperText, TextField, Select, InputLabel, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

class CreateStep1 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        {
          !Helpers.isClientRole(Cache.getItem("roleType")) &&
          this.props.subPlanOptions.length > 0 &&
          <div className="pdb-lg">
            <FormControl fullWidth>
              <InputLabel id="role-label">Lot</InputLabel>
              <Select
                labelId="role-label"
                value={this.props.subPlanId ?? ""}
                onChange={(e) => this.props.handleSubPlanChange(e.target.value)} 
              >
                <MenuItem key="-1" value="">
                  <em>...</em>
                </MenuItem>
                {
                  this.props.subPlanOptions.map((plan, index) => 
                    <MenuItem key={index} value={plan.id}>{plan.planName}</MenuItem>)
                }
              </Select>
            </FormControl>
            <span className="text-danger">{this.props.inputErrors["subPlanId"]}</span>
          </div>
        }

        <div className="pdb-lg">
          <FormControl fullWidth>
            <InputLabel
              error={this.props.inputStates.categoryType === "error"}
              id="issue-type-label">
                Category*
            </InputLabel>
            <Select
              labelId="issue-type-label"
              error={this.props.inputStates.categoryType === "error"}
              value={this.props.categoryType ?? ""}
              onChange={(e) => this.props.handleChange("categoryType", e.target.value, true)}
            >
              <MenuItem key="-1" value="">
                <em>...</em>
              </MenuItem>
              {
                this.props.categoryTypeOptions.map((item, index) => 
                  <MenuItem key={index} value={item.value1}>{item.value1}</MenuItem>)
              }
            </Select>
          </FormControl>
          <FormHelperText error>{this.props.inputErrors.categoryType}</FormHelperText>
          
          <div className={`${(this.props.categoryType ?? "").toLowerCase().startsWith("other") ? "" : "hidden"}`}>
            <TextField
              fullWidth
              error={this.props.inputStates.subCategoryType === "error"}
              label="Please Specify"
              id="sub-category-type"
              onChange={(e) => this.props.handleChange("subCategoryType", e.target.value, true)}
              value={this.props.subCategoryType}
              autoComplete= "off"
              helperText={this.props.inputErrors.subCategoryType}
            />
          </div>
        </div>

        <div className="">
          <div className="row">
            <div className="col-sm-6 col-xs-12 pdb-lg">
              <FormControl fullWidth>
                <InputLabel
                  error={this.props.inputStates.priority === "error"}
                  id="priority-label">
                    Priority*
                </InputLabel>
                <Select
                  labelId="priority-label"
                  error={this.props.inputStates.priority === "error"}
                  value={this.props.priority ?? ""}
                  onChange={(e) => this.props.handleChange("priority", e.target.value, true)} 
                >
                  <MenuItem key="-1" value="">
                    <em>...</em>
                  </MenuItem>
                  {
                    this.props.priorityOptions.map((item, index) => 
                      <MenuItem key={index} value={item.value}>{item.text}</MenuItem>)
                  }
                </Select>
              </FormControl>
              <FormHelperText error>{this.props.inputErrors.priority}</FormHelperText>
            </div>
            <div className="col-sm-6 col-xs-12 pdb-lg">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  fullWidth
                  error={this.props.inputStates.addedAt === "error"}
                  label="Issue Date*"
                  id="date-added"
                  variant="inline"
                  format="dd/MM/yyyy"
                  onChange={(e) => this.props.handleChange("addedAt", e, true)}
                  value={this.props.addedAt}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  autoComplete="off"
                  helperText={this.props.inputErrors.addedAt}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateStep1;