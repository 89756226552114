import React from "react";
import moment from 'moment';
import * as Helpers from "libs/Helpers";
import * as Enums from "libs/Enums";

class TimelineItem extends React.Component {
  render() {
    const data = this.props.data;
    const previousStateItem = this.props.previousStateItem;
    let intervalTime = "";
    if(previousStateItem != null) {
      const mins = Helpers.calculateDateDiffInMin(new Date(previousStateItem.updatedAt), new Date(data.updatedAt));
      intervalTime = "Time Interval " +  Helpers.formatDateDiffMinutesToText(mins);
    }

    return (
      <React.Fragment>
        <div className="timeline-item clearfix">
          <img src="/images/green-dot.png" className="issue-timeline-dot" alt=""/>
          <div className="status">  
            {
              intervalTime != "" &&
              <React.Fragment>
                <div className="issue-status-interval text-light small color-grey text-bold"> 
                { intervalTime } 
                </div> 
              </React.Fragment> 
            }   
            <div className="color-grey text-bold">
              <div className="text-light issue-status-date small">{moment(new Date(data.updatedAt)).format("ddd, DD MMM YYYY hh:mm A")}</div>
            </div>
            <img src="/images/icons/label-24px.svg" className="pull-left issue-status-img mgt-sm"  alt=""/>
            <div className="text-bold text-uppercase issue-status-label lgap mgt-sm">{Enums.getIssueStateStatusLabel(data.stateType)}</div>
            <div className="text-light issue-status-text lgap">{data.stateType === "stop" ? "" : data.description}</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default TimelineItem;