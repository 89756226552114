import React from "react";
import Amplify, { API, Cache, graphqlOperation } from "aws-amplify";
import strataApiConfig from "config-api-STRATA";
import { green, lightGreen } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import HoldingPanel from "views/shared/HoldingPanel";
import { TextField, FormControlLabel, Switch } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as Helpers from "libs/Helpers";

const DefaultSwitch = withStyles({
  switchBase: {
    color: "#FAFAFA",
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: lightGreen[400],
    },
  },
  checked: {},
  track: {},
})(Switch);

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: Cache.getItem("email"),
      mobile: "",
      email: "",
      firstName: "",
      middleName: "",
      lastName: "",
      persona: "",
      address: "",
      postcode: "",
      suburb: "",
      dateOfBirth: "",
      allowEmail: false,
      allowSub: false,
      allowSMS: false,
      allowPush: false,
      inputStates: {
        mobile: "",
        email: "",
        firstName: "",
        middleName: "",
        dateOfBirth:"",
        lastName: "",
        persona: "",
        address: "",
        postcode: "",
        suburb: "",
        allowEmail: "",
        allowSub: "",
        allowSMS: "",
        allowPush: ""
      },
      inputErrors: {
        mobile: "",
        email: "",
        firstName: "",
        middleName: "",
        dateOfBirth:"",
        lastName: "",
        persona: "",
        address: "",
        postcode: "",
        suburb: "",
        allowEmail: "",
        allowSub: "",
        allowSMS: "",
        allowPush: ""
      },
      handleChange: this.handleChange.bind(this),
      handleSubmit: this.handleSubmit.bind(this)
    };
  }

  async componentDidMount() {
    const response = await this.getUser();

    var profile = response.data.getUser;

    // console.log('profile', profile);

    await this.bindFormState(profile);
  }

  bindFormState = async profile => {
    
    this.setState({
      mobile: Helpers.isNull(profile.contact_mobile, ""),
      email: Helpers.isNull(profile.contact_email, ""),
      firstName: Helpers.isNull(profile.person_firstName, ""),
      middleName: Helpers.isNull(profile.person_middleName, ""),
      lastName: Helpers.isNull(profile.person_lastName, ""),
      address: Helpers.isNull(profile.address_name, ""),
      postcode: Helpers.isNull(profile.address_postcode, ""),
      suburb: Helpers.isNull(profile.address_suburb, ""),
      allowEmail: profile.optin_allowEmail,
      allowSub: profile.optin_allowSub,
      allowSMS: profile.optin_allowSMS,
      allowPush: profile.optin_allowPush
    });
  };

  handleChange = (name, value, required) => {
    this.setState({ [name]: value }, () => {
      if (required) {
        this.validateRequired(name);
      }
      if (name == 'email') {
        this.validateEmail(value);
      }
    });
  };

  validateRequired = name => {
    let valid = false;
    const value = this.state[name];
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (value == null || value === "") {
      inputStates[name] = "error";
      inputErrors[name] = "This field is required";
    } else {
      valid = true;
      inputStates[name] = "success";
      inputErrors[name] = "";
    }

    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    return valid;
  };

  validateEmail = value => {
    let valid = false;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (Helpers.verifyEmail(value)) {
      valid = true;
      inputStates["email"] = "success";
      inputErrors["email"] = "";
    } else {
      valid = false;
      inputStates["email"] = "error";
      inputErrors["email"] = "Invalid email address";
    }

    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    return valid;
  }

  handleReset = async event => {
    const data = await this.getUser();
    var profile = data.data.getUser;
    await this.bindFormState(profile);
  };

  handleSubmit = async event => {
    event.preventDefault();
    let valid = true;
    
    valid = this.validateRequired("firstName") && valid;
    valid = this.validateRequired("lastName") && valid;
    valid = this.validateRequired("email") && valid;
    // valid = this.validateRequired("address") && valid;
    // valid = this.validateRequired("postcode") && valid;
    // valid = this.validateRequired("suburb") && valid;
    valid = this.validateEmail(this.state.email) && valid;

    if(!valid)
      return false;

    this.props.setLoading(true);

    Amplify.configure(strataApiConfig);

    const params = { 
      id: this.state.id,
      contact_mobile: Helpers.isBlank(this.state.mobile, null),
      contact_email: Helpers.isBlank(this.state.email, null),
      person_firstName: Helpers.isBlank(this.state.firstName, null),
      person_middleName: Helpers.isBlank(this.state.middleName, null),
      person_lastName: Helpers.isBlank(this.state.lastName, null),
      address_name: Helpers.isBlank(this.state.address, null),
      address_postcode: Helpers.isBlank(this.state.postcode, null),
      address_suburb: Helpers.isBlank(this.state.suburb, null),
      optin_allowEmail: this.state.allowEmail,
      optin_allowSub: this.state.allowSub,
      optin_allowSMS: this.state.allowSMS,
      optin_allowPush: this.state.allowPush
    };

    const cmd = `
      mutation(
        $id: ID!
        $contact_mobile: String
        $contact_email: String
        $person_firstName: String
        $person_middleName: String
        $person_lastName: String
        $address_name: String
        $address_postcode: String
        $address_suburb: String
        $optin_allowEmail: Boolean
        $optin_allowSub: Boolean
        $optin_allowSMS: Boolean
        $optin_allowPush: Boolean
      ){
        user: updateUser(input: {
          id: $id
          contact_mobile: $contact_mobile
          contact_email: $contact_email
          person_firstName: $person_firstName
          person_middleName: $person_middleName
          person_lastName: $person_lastName
          address_name: $address_name
          address_postcode: $address_postcode
          address_suburb: $address_suburb
          optin_allowEmail: $optin_allowEmail
          optin_allowSub: $optin_allowSub
          optin_allowSMS: $optin_allowSMS
          optin_allowPush: $optin_allowPush
        }){ 
          id
          contact_mobile
          contact_email
          person_firstName
          person_middleName
          person_lastName
          address_name
          address_postcode
          address_suburb
          optin_allowEmail
          optin_allowSub
          optin_allowSMS
          optin_allowPush
        }
      }`;

      const result = await API.graphql(graphqlOperation(cmd, params));

      // console.log('result.data.user', result.data.user);

      this.bindFormState(result.data.user);

      this.props.setLoading(false);
  };

  getUser = async () => {
    Amplify.configure(strataApiConfig);
    const params = {
      id: this.state.id
    };
    // console.log("params.id", params.id);

    const cmd = `
      query(
        $id: ID!
      ){
        getUser(
          id: $id
        )
        {
          id
          contact_mobile
          contact_email
          person_firstName
          person_middleName
          person_lastName
          address_name
          address_postcode
          address_suburb
          optin_allowEmail
          optin_allowSub
          optin_allowSMS
          optin_allowPush
        }
      }
    `;
    return API.graphql(graphqlOperation(cmd, params));
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-title">
          <h1>Profile</h1>
        </div>
        <div className="profile-container">
          <div className="card wic tpd">
            <div className="icon">
              <img src="/images/icons/assignment-24px.svg" alt="" />
            </div>
            <div className="card-title">Edit Profile</div>
            <div className="card-body">
              <form>
                <div className="">
                  <div className="row">
                    <div className="col-sm-4 col-xs-12 pdb-lg">
                      <TextField
                        fullWidth
                        error={this.state.inputStates.firstName === "error"}
                        label="First Name*"
                        id="first-name"
                        autoComplete="off"
                        onChange={e =>
                          this.handleChange("firstName", e.target.value, true)
                        }
                        value={this.state.firstName}
                        helperText={this.state.inputErrors.firstName}
                      />
                    </div>
                    <div className="col-sm-4 col-xs-12 pdb-lg">
                      <TextField
                        fullWidth
                        error={this.state.inputStates.middleName === "error"}
                        label="Middle Name"
                        id="middle-name"
                        autoComplete="off"
                        onChange={e =>
                          this.handleChange("middleName", e.target.value, false)
                        }
                        value={this.state.middleName}
                        helperText={this.state.inputErrors.middleName}
                      />
                    </div>
                    <div className="col-sm-4 col-xs-12 pdb-lg">
                      <TextField
                        fullWidth
                        error={this.state.inputStates.lastName === "error"}
                        label="Last Name*"
                        id="last-name"
                        autoComplete="off"
                        onChange={e =>
                          this.handleChange("lastName", e.target.value, true)
                        }
                        value={this.state.lastName}
                        helperText={this.state.inputErrors.lastName}
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-sm-6 col-xs-12 pdb-lg">
                      <TextField
                        fullWidth
                        error={this.state.inputStates.email === "error"}
                        label="Email*"
                        id="email"
                        autoComplete="off"
                        onChange={e =>
                          this.handleChange("email", e.target.value, true)
                        }
                        value={this.state.email}
                        helperText={this.state.inputErrors.email}
                      />
                    </div>
                    <div className="col-sm-6 col-xs-12 pdb-lg">
                      <TextField
                        fullWidth
                        error={this.state.inputStates.mobile === "error"}
                        label="Mobile*"
                        id="mobile"
                        autoComplete="off"
                        onChange={e =>
                          this.handleChange("mobile", e.target.value, true)
                        }
                        value={this.state.mobile}
                        helperText={this.state.inputErrors.mobile}
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-sm-12 col-xs-12 pdb-lg">
                      <TextField
                        fullWidth
                        error={this.state.inputStates.address === "error"}
                        label="Address"
                        id="address"
                        autoComplete="off"
                        onChange={e =>
                          this.handleChange("address", e.target.value, false)
                        }
                        value={this.state.address}
                        helperText={this.state.inputErrors.address}
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-sm-6 col-xs-6 pdb-lg">
                      <TextField
                        fullWidth
                        error={this.state.inputStates.postcode === "error"}
                        label="Post Code"
                        id="post-code"
                        autoComplete="off"
                        onChange={e =>
                          this.handleChange("postcode", e.target.value, false)
                        }
                        value={this.state.postcode}
                        helperText={this.state.inputErrors.postcode}
                      />
                    </div>
                    <div className="col-sm-6 col-xs-6 pdb-lg">
                      <TextField
                        fullWidth
                        error={this.state.inputStates.suburb === "error"}
                        label="Suburb"
                        id="suburb"
                        autoComplete="off"
                        onChange={e =>
                          this.handleChange("suburb", e.target.value, false)
                        }
                        value={this.state.suburb}
                        helperText={this.state.inputErrors.suburb}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="">
                  <div className="row">
                    <div className="col-sm-6 col-xs-12 pdb-lg">
                      <TextField
                        fullWidth
                        label="Password"
                        id="password"
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-sm-6 col-xs-12 pdb-lg">
                      <TextField
                        fullWidth
                        label="Confirm Password"
                        id="confirm-password"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div> */}

                <h2 className="mgb-sm">Notification</h2>
                <div className="">
                  <div className="row">
                    <div className="col-sm-6 col-xs-12 pdb-sm">
                      <FormControlLabel
                        label="Allow Email"
                        control={
                          <DefaultSwitch
                            color="primary"
                            checked={this.state.allowEmail}
                            onChange={e =>
                              this.handleChange("allowEmail", !this.state.allowEmail, false)
                            }
                          />
                        }
                        labelPlacement="end"
                      />
                    </div>
                    <div className="col-sm-6 col-xs-12 pdb-sm">
                      <FormControlLabel
                        value={this.state.allowPush}
                        label="Allow Push"
                        control={
                          <DefaultSwitch
                            color="primary"
                            checked={this.state.allowPush}
                            onChange={e =>
                              this.handleChange("allowPush", !this.state.allowPush, false)
                            }
                          />
                        }
                        labelPlacement="end"
                      />
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="row">
                    <div className="col-sm-6 col-xs-12 pdb-sm">
                      <FormControlLabel
                        value={this.state.allowSMS}
                        label="Allow SMS"
                        control={
                          <DefaultSwitch
                            color="primary"
                            checked={this.state.allowSMS}
                            onChange={e =>
                              this.handleChange("allowSMS", !this.state.allowSMS, false)
                            }
                          />
                        }
                        labelPlacement="end"
                      />
                    </div>
                    <div className="col-sm-6 col-xs-12 pdb-sm">
                      <FormControlLabel
                        value={this.state.allowSub}
                        label="Allow Sub"
                        control={
                          <DefaultSwitch
                            color="primary"
                            checked={this.state.allowSub}
                            onChange={e =>
                              this.handleChange("allowSub", !this.state.allowSub, false)
                             }
                          />
                        }
                        labelPlacement="end"
                      />
                    </div>
                  </div>
                </div>
                <div className="pdt-lg clearfix">
                  <button
                    className="btn btn-secondary pull-left"
                    type="button"
                    onClick={this.handleReset}
                    >
                    Reset
                  </button>
                  <button
                    className="btn btn-success pull-right"
                    type="button"
                    onClick={this.handleSubmit}
                  >
                    Update profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Profile;
