import React from "react";
import Amplify, { API, Cache, graphqlOperation } from "aws-amplify";
import * as Enums from "libs/Enums";
import strataApiConfig from "config-api-STRATA";
import utilsApiConfig from "config-api-UTILS";
import { TextField, InputLabel, MenuItem, Select, InputAdornment, FormControl, FormHelperText } from "@material-ui/core";


Amplify.configure(utilsApiConfig);

class IssueEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      priority: null,
      estimatedCost: null,
      subject: "",
      description: "",
      priorityOptions: [],
      inputStates: {
        priority: "",
        estimatedCost: "",
        subject: "",
        description: ""
      },
      inputErrors: {
        priority: "",
        estimatedCost: "",
        subject: "",
        description: ""
      }
    };
  }

  async componentDidMount() {
    this.props.setLoading(true);
    var priorityOptions = Enums.getPriorities();
    this.setState({ priorityOptions });
    await this.loadTxn();
    this.props.setLoading(false);
  }

  loadTxn = async () => {
    Amplify.configure(strataApiConfig);

    const cmd = `
      query(
        $id: ID!
      ) {
        txn: getTxn(
          id: $id
        ){
          id
          ticket_subject 
          ticket_description 
          ticket_estimatedCost
          ticket_priorityRank 
        }
      }`;

    const result = await API.graphql(graphqlOperation(cmd, { id: this.state.id }));

    console.log('result', result);

    const txn = result.data.txn;
    
    this.setState({
      priority: txn.ticket_priorityRank,
      estimatedCost: txn.ticket_estimatedCost,
      subject: txn.ticket_subject,
      description: txn.ticket_description
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    let valid = true;
    
    valid = this.validateRequired("priority") && valid;
    valid = this.validateRequired("estimatedCost") && valid;
    valid = this.validateRequired("subject") && valid;
    valid = this.validateRequired("description") && valid;

    if(!valid)
      return false;

    this.props.setLoading(true);

    Amplify.configure(strataApiConfig);

    const params = { 
      id: this.state.id,
      ticket_priorityRank: this.state.priority,
      ticket_amount: this.state.estimatedCost,
      ticket_estimatedCost: this.state.estimatedCost,
      ticket_subject: this.state.subject,
      ticket_description: this.state.description
    };

    const cmd = `
      mutation(
        $id: String!
        $ticket_priorityRank: Int
        $ticket_amount: Float
        $ticket_estimatedCost: Float
        $ticket_subject: String
        $ticket_description: String
      ){
        updateTxn(input: {
          id: $id
          ticket_priorityRank: $ticket_priorityRank
          ticket_amount: $ticket_amount
          ticket_estimatedCost: $ticket_estimatedCost
          ticket_subject: $ticket_subject
          ticket_description: $ticket_description
        }){ 
          id
        }
      }`;

    const result = await API.graphql(graphqlOperation(cmd, params));
    
    // await this.loadTxn();

    await this.props.handleEditSuccess();

    this.props.setLoading(false);
  };

  handleChange = async (name, value, required) => {
    this.setState({ [name]: value }, () => {
      if (required) {
        this.validateRequired(name);
      }
    });
  }

  validateRequired = (name) => {
    let valid = false;
    const value = this.state[name];
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (value == null || value === "") {
      inputStates[name] = "error";
      inputErrors[name] = "This field is required";
    } else {
      valid = true;
      inputStates[name] = "success";
      inputErrors[name] = "";
    }
    
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    return valid;
  }

  render() {
    return (
      <React.Fragment>
        <form>
          <div className="pdb-lg">
            <div className="row">
              <div className="col-xs-6">
                <FormControl fullWidth>
                  <InputLabel
                    error={this.state.inputStates.priority === "error"}
                    id="priority-label">
                      Priority*
                  </InputLabel>
                  <Select
                    labelId="priority-label"
                    error={this.state.inputStates.priority === "error"}
                    value={this.state.priority ?? ""}
                    onChange={(e) => this.handleChange("priority", e.target.value, true)} 
                  >
                    <MenuItem key="-1" value="">
                      <em>...</em>
                    </MenuItem>
                    {
                      this.state.priorityOptions.map((item, index) => 
                        <MenuItem key={index} value={item.value}>{item.text}</MenuItem>)
                    }
                  </Select>
                </FormControl>
                <FormHelperText error>{this.state.inputErrors.priority}</FormHelperText>
              </div>
              <div className="col-xs-6">
                <TextField
                  fullWidth
                  className="no-spinner"
                  error={this.state.inputStates.estimatedCost === "error"}
                  label="Estimated Cost*"
                  id="estimated-cost"
                  autoComplete="off"
                  onChange={e => this.handleChange("estimatedCost", e.target.value, true)}
                  value={this.state.estimatedCost}
                  helperText={this.state.inputErrors.estimatedCost}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    type: "number"
                  }}
                />
              </div>
            </div>
          </div>

          <div className="pdb-lg">
            <TextField
              fullWidth
              error={this.state.inputStates.subject === "error"}
              label="Subject*"
              id="subject"
              autoComplete="off"
              onChange={e => this.handleChange("subject", e.target.value, true) }
              value={this.state.subject}
              helperText={this.state.inputErrors.subject}
            />
          </div>

          <div className="pdb-lg">
            <TextField
              fullWidth
              error={this.state.inputStates.description === "error"}
              label="Issue description*"
              id="description"
              placeholder="Please enter the description of your issue"
              multiline={true}
              rows={4}
              rowsMax={4}
              value={this.state.description}
              onChange={(e) => this.handleChange("description", e.target.value, true)}
              helperText={this.state.inputErrors.description}
            />
          </div>

          <div className="pdt-lg clearfix">
            <button
              className="btn btn-secondary pull-left"
              onClick={(e) => this.props.handleClose("editIssueOpen")}>
              Cancel
            </button>
            <button
              className="btn btn-success pull-right"
              type="button"
              onClick={(e) => this.handleSubmit(e)}>
              Edit Issue
            </button>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default IssueEdit;
