import React from "react";

class DashboardStrataManager extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-title">
          <h1>Dashboard</h1>
        </div>

        <div className="row mgt">
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/issues">
              <div className="icon">
                <img src="/images/icons/assignment-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Issues</div>
              </div>
            </a>
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/plans">
              <div className="icon">
                <img src="/images/icons/person_outline-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Strata Plans</div>
              </div>
            </a>
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/plans">
              <div className="icon">
                <img src="/images/icons/business-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Properties</div>
              </div>
            </a>
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/clients">
              <div className="icon">
                <img src="/images/icons/person-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Clients</div>
              </div>
            </a>
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/suppliers">
              <div className="icon">
                <img src="/images/icons/tradesman-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Tradesmen</div>
              </div>
            </a>
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/reports">
              <div className="icon">
                <img src="/images/icons/insert_chart-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Reports</div>
              </div>
            </a>
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/concierge">
              <div className="icon">
                <img src="/images/icons/concierge-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Concierge</div>
              </div>
            </a>
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/settings">
              <div className="icon">
                <img src="/images/icons/settings_applications-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Settings</div>
              </div>
            </a>
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/help">
              <div className="icon">
                <img src="/images/icons/help_outline-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Help Centre</div>
              </div>
            </a>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default DashboardStrataManager;