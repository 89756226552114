import React from "react";
import moment from 'moment';
import Amplify, { API, Cache, Storage, graphqlOperation } from "aws-amplify";
import * as Helpers from "libs/Helpers";
import * as Enums from "libs/Enums";
import * as Utils from "libs/Utils";
import * as FileS3 from "libs/FileS3";
import config from "config";
import strataApiConfig from "config-api-STRATA";
import utilsApiConfig from "config-api-UTILS";
import { OnUpdateMachine, OnDeleteMachine, OnCreateMachine } from "graphql/Subscriptions";
import { TextField, Dialog, DialogTitle, DialogContent, InputAdornment, FormControlLabel, Checkbox, withStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import CheckBox from '@material-ui/icons/CheckBox';
import DateFnsUtils from '@date-io/date-fns';
import TimelineItem from './parts/TimelineItem';
import NoteItem from './parts/NoteItem';
import AttachmentItem from './parts/AttachmentItem';
import QuoteListItem from './parts/QuoteListItem';
import IssueEdit from "./Edit";
import Suppliers from "./Suppliers";

Amplify.configure(utilsApiConfig);

class IssueDetail extends React.Component {
  constructor(props) {
    super(props);
    const rolesCanViewTransitionPanel = ["admin", "stratamanager", "propertymanager"];
    const rolesHaveEditAccess = ["admin", "stratamanager", "propertymanager"];
    this.interval = null;
    this.state = {
      id: this.props.match.params.id,
      userId: Cache.getItem("email"),
      userRoleType: "",
      hasEditAccess: rolesHaveEditAccess.includes(Cache.getItem("roleType")),
      trackingMachineIds: [],
      txn: null,
      machine: null,
      approvedQuote: null,
      transitions: [],
      nextState: null,
      transitionPanelVisible: rolesCanViewTransitionPanel.includes(Cache.getItem("roleType")),
      allowTransition: false,
      noteComment: "",
      activeTab: 1,
      menuOpen: false,
      modalOpen: false,
      quoteOpen: false,
      updateEstimatedCostOpen: false,
      attachInvoiceOpen: false,
      editIssueOpen: false,
      selectSuppliersOpen: false,
      albumOpened: false,
      displayMedia: null,
      selectedFile: null,
      fileCaption: "",
      viewingQuoteId: "",
      isViewingWorkOrder: false,
      companyName: "",
      quoteNumber: "",
      quoteAmount: "",
      quoteDescription: "",
      proposedStartAt: null,
      invoiceFile: null,
      invoiceFileCaption: "",
      estimatedCost: "",
      inputStates: {
        noteComment: "",
        selectedFile: "",
        nextState:"",
        fileCaption: "",
        quoteAmount: "",
        quoteDescription: "",
        proposedStartAt: "",
        invoiceFile: "",
        invoiceFileCaption: "",
        estimatedCost: "",
      },
      inputErrors: {
        noteComment: "",
        selectedFile: "",
        nextState:"",
        fileCaption: "",
        quoteAmount: "",
        quoteDescription: "",
        proposedStartAt: "",
        invoiceFile: "",
        invoiceFileCaption: "",
        estimatedCost: "",
      }
    };
  }

  async componentDidMount() {
    this.props.setLoading(true);


    try {
      await this.loadTxn();
    } catch(e) {
      alert(JSON.stringify(e));
    }

    await this.loadTxnQuote();
    await this.loadTransitions();
    //console.log(this.state);
    this.props.setLoading(false);

    this.createMachineSubscription = API.graphql(
        graphqlOperation(OnCreateMachine)
      ).subscribe({
        next: async (state) => await this.doRefresh(state.value.data.onCreateMachine, "create")
    });
  
    this.updateMachineSubscription = API.graphql(
        graphqlOperation(OnUpdateMachine)
      ).subscribe({
        next: async (state) => await this.doRefresh(state.value.data.onUpdateMachine, "update")
    });
  
    this.deleteMachineSubscription = API.graphql(
        graphqlOperation(OnDeleteMachine)
      ).subscribe({
        next: async (state) => await this.doRefresh(state.value.data.onDeleteMachine, "delete")
    });
  }

  componentWillUnmount() {
    this.createMachineSubscription.unsubscribe();
    this.updateMachineSubscription.unsubscribe();
    this.deleteMachineSubscription.unsubscribe();
  }

  async doRefresh(machine, trigger) {
    // console.log(machine);

    if(trigger === "create" && machine.parentForeignId === this.state.id){
      let trackingMachineIds = this.state.trackingMachineIds;
      trackingMachineIds.push(machine.id);
      this.setState({ trackingMachineIds: trackingMachineIds});
    }

    if(!this.state.trackingMachineIds.includes(machine.id)) 
      return;

    //this.props.setLoading(true);
    await this.loadTxn();
    await this.loadTxnQuote();
    await this.loadTransitions();
    //this.props.setLoading(false);
  }

  openQuoteCreation = () => {
    console.log('this.state.txn', this.state.txn);
    this.setState({ quoteOpen: !this.state.txn.quotes.find(x => x.createdBy === this.state.userId) && this.state.userRoleType === "supplier"});
  }

  openQuoteForm = (quote=null) => {
    if(quote == null){
      this.openQuoteCreation();
      return;
    }
    console.log('quote', quote);
    this.setState({ quoteOpen: this.state.txn.quotes.find(x => x.id === quote.id) != null});
    this.setQuoteDetails(quote);
    console.log('quote.id', quote.refId);
  }

  setQuoteDetails = (quote) => {
    this.setState({
      viewingQuoteId: quote.id,
      isViewingWorkOrder: quote.approved,
      companyName: quote.supplierName ?? "",
      quoteNumber: quote.refId ?? "",
      quoteAmount: quote.amount ?? "",
      quoteDescription: quote.description ?? "",
      proposedStartAt: quote.proposedStartAt
    });
  }

  closeQuote = () => {
    this.setState({ quoteOpen: false });
  }

  updateQuoteClose = () => {
    this.setState({ updateEstimatedCostOpen: false });
  }

  openDialog = () => {
    this.setState({ modalOpen: true });
  }

  closeDialog = () => {
    this.setState({ modalOpen: false, selectedFile: null, fileCaption: "" });
  }

  closeGenericDialog = (stateValue) => {
    this.setState({[stateValue] : false});
  }

  openAlbum = (mediaId) => {
    const media = this.state.txn.assets.find(x => x.id === mediaId);
    this.setState({ albumOpened: true, displayMedia: media });
  }

  closeAlbum = () => {
    this.setState({ albumOpened: false, displayMedia: null });
  }

  handleAlbumPrev = () => {
    const assets = this.state.txn.assets;
    const current = this.state.displayMedia;
    const currentIndex = assets.findIndex(x => x.id === current.id);
    const prevIndex = currentIndex === 0 ? assets.length - 1 : currentIndex - 1;
    this.setState({ displayMedia: assets[prevIndex] });
    if(current.category == "video" || current.category == "audio") {
      const video = document.getElementById("video_" + current.id);
      if(video != null)
        video.pause();
    }
  }

  handleAlbumNext = () => {
    const assets = this.state.txn.assets;
    const current = this.state.displayMedia;
    const currentIndex = assets.findIndex(x => x.id === current.id);
    const nextIndex = currentIndex === assets.length - 1 ? 0 : currentIndex + 1;
    this.setState({ displayMedia: assets[nextIndex] });
    if(current.category == "video" || current.category == "audio") {
      const video = document.getElementById("video_" + current.id);
      if(video != null)
        video.pause();
    }
  }
  
  menuClickOpen = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  }
  
  activateTab = (event, index) => {
    event.preventDefault();
    this.setState({ activeTab: index });
  }

  validateRequired = (name) => {
    let valid = false;
    const value = this.state[name];
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (value == null || value === "") {
      inputStates[name] = "error";
      inputErrors[name] = "This field is required";
    } else if(name === "quoteAmount" && parseFloat(value) <= 0.0){
      inputStates[name] = "error";
      inputErrors[name] = "Amount should not be 0";
    } else {
      valid = true;
      inputStates[name] = "success";
      inputErrors[name] = "";
    }
    
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    return valid;
  }

  handleChange = async (name, value, required) => {
    this.setState({ [name]: value }, () => {
      if (required) {
        this.validateRequired(name);
      }
    });
  }

  handleMediaChange = (event) => {
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (event.target.files.length === 0) {
      inputStates["selectedFile"] = "";
      inputErrors["selectedFile"] = "";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      this.setState({selectedFile: null});
      return;
    } 

    if (event.target.files.length > 1) {
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = "Multiple files were slected. Please select one file";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    } 

    var file = event.target.files[0];
    
    if (!Helpers.checkLegitImage(file.name)
      && !Helpers.checkLegitAudio(file.name)
      && !Helpers.checkLegitVideo(file.name)) {
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = "This file format is not supported";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    const category = Helpers.getFileCategory(file.name);

    const maxFileSize = category === "video" 
      ? config.MAX_ATTACHMENT_SIZE_VIDEO
      : config.MAX_ATTACHMENT_SIZE_FILE;

    if (file.size > maxFileSize) {
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = `Please select a file smaller than ${maxFileSize / 1000000} MB.`;
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    } 

    inputStates["selectedFile"] = "success";
    inputErrors["selectedFile"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });
    this.setState({selectedFile: file});
  }
  
  handleAddMedia = async () => {
    let valid = true;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (this.state.selectedFile == null) {
      valid = false;
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = "Media file is required";
    } 

    if (this.state.fileCaption == null || this.state.fileCaption === "") {
      valid = false;
      inputStates["fileCaption"] = "error";
      inputErrors["fileCaption"] = "This field is required";
    } 

    if(!valid) {
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    inputStates["selectedFile"] = "success";
    inputErrors["selectedFile"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    this.props.setLoading(true);

    Amplify.configure(strataApiConfig);
    
    try {
      const txnId = this.state.id;
      const fileCaption = this.state.fileCaption;
      const selectedFile = this.state.selectedFile;
      const formattedName = selectedFile.name
        .replace("-", " ")
        .replace("_", " ")
        .replace(/[^a-zA-Z0-9\. ]/g, "")
        .replace(/[ ]+/g, "-");
      const fileName = (Helpers.generateGuid() + "_" + formattedName).toLowerCase(); // must not have spaces in file names

      const prefix = "plan/" + this.state.txn.planId;
      var result = await FileS3.upload(selectedFile, prefix, fileName);     
      
      const annotation = fileCaption;
      const attachmentSubType = "";

      const respAttachment = await Utils.createAttachmentMedia(
        txnId, 
        "strata",
        result.privateFileKey, 
        result.privateFileURL,
        fileCaption,
        selectedFile.type.toLowerCase(),
        selectedFile.size / (1024 * 1024),
        attachmentSubType,
        annotation); 

      const attachmentId = respAttachment.data.createAttachment.id;

      await Utils.createMachine(
        attachmentId, 
        "attach", 
        txnId,
        "strata",
        "thinking", 
        "ticketed",
        config.ADMIN_USER_ID,
        "admin");

      console.log(Helpers.getFileCategory(fileName));
      // Set primary image
      if(this.state.txn.mediumId == null
        && Helpers.getFileCategory(fileName) === "image"){
        await Utils.updateTxnMedium(txnId, attachmentId);
      }
    } catch (e) {
      console.log(e);
      alert(JSON.stringify(e));
    }

    await this.loadTxn();
    this.props.setLoading(false);
    this.closeDialog();
  }
  
  handleDeleteMedia = async (event, id) => {
    event.preventDefault();
    event.stopPropagation();

    if(!window.confirm('Are you sure you want to delete?')){
      return;
    }

    try {
      await Utils.deleteAttachment(id);
      // Set primary image
      if(this.state.txn.mediumId == id){
        await Utils.updateTxnMedium(this.state.id, null);
      }
      await this.loadTxn();
    }
    catch (e) {
      console.log(e);
    }
  }
  
  handleSetMedium = async (event, id) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      await Utils.updateTxnMedium(this.state.id, id);
      await this.loadTxn();
    }
    catch (e) {
      console.log(e);
    }
  }
  
  handleAddNote = async () => {
    let valid = true;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (this.state.noteComment == null || this.state.noteComment === "") {
      valid = false;
      inputStates["noteComment"] = "error";
      inputErrors["noteComment"] = "This field is required";
    } 

    if(!valid) {
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    inputStates["noteComment"] = "success";
    inputErrors["noteComment"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });
    
    this.props.setLoading(true);

    const txnId = this.state.id;

    try {
      const respAttachment = await Utils.createAttachmentNote(txnId, "strata", this.state.noteComment);

      const attachmentId = respAttachment.data.createAttachment.id;

      await Utils.createMachine(
        attachmentId, 
        "attach", 
        txnId,
        "strata",
        "thinking", 
        "ticketed",
        config.ADMIN_USER_ID,
        "admin");

      await this.loadTxn();
      await this.loadTxnQuote();
      await this.loadTransitions(); 
      this.setState({ noteComment: "" });
    }
    catch (e) {
      //
    }

    this.props.setLoading(false);
  }

  handleTransitState = async (stateType) => {
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    //console.log(stateType);

    if ((stateType ?? "") === "") {
      inputStates["nextState"] = "error";
      inputErrors["nextState"] = "Please select an option";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    inputStates["nextState"] = "success";
    inputErrors["nextState"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    if(stateType === "cancelled"
      && !window.confirm("Are you sure you want to cancel?")) {
        return;
    }

    if(stateType === "declined"
      && !window.confirm("Are you sure you want to decline?")) {
        return;
    }

    if(stateType === "stop"
      && !window.confirm("Are you sure you want to stop?")) {
        return;
    }

    this.props.setLoading(true);

    try {
      console.log(this.state.machine.id);

      const machine = this.state.machine;

      await Utils.updateMachine(
        machine.id,
        (machine.machineState.machineType ?? "fixxting"),
        stateType,
        Cache.getItem("email"),
        Cache.getItem("roleType")
      );
    }
    catch (e) {
      console.error(e);
    }

    await this.loadTxn();
    await this.loadTxnQuote();
    await this.loadTransitions(); 

    this.props.setLoading(false);
  }

  handleUpdateEstimatedCost = async() => 
  {
    this.setState({ updateEstimatedCostOpen: true});
  }

  handleUpdateEstimatedCostSubmit = async(event) => 
  {
    event.preventDefault();
    let valid = true;
    valid = this.validateRequired("estimatedCost") && valid;

    if(!valid)
      return false;
    
    this.props.setLoading(true);

    try {
      await Utils.updateTxnEstimatedCost(
         this.state.id,
         this.state.estimatedCost
      );

      this.setState({ updateEstimatedCostOpen: false});
      this.setState({ transitions: [] })
      this.handleTransitState("approved");
    } catch (e) {
      console.error(e);
    }

    this.props.setLoading(false);

  }

  handleQuoteSubmit = async (event) => {
    event.preventDefault();
    let valid = true;
    
    valid = this.validateRequired("companyName") && valid;
    valid = this.validateRequired("quoteAmount") && valid;
    valid = this.validateRequired("quoteDescription") && valid;
    valid = this.validateRequired("proposedStartAt") && valid;

    if(!valid)
      return false;

    this.props.setLoading(true);

    Amplify.configure(strataApiConfig);

    let quoteId = null;
    
    try {
      const respTxn = await this.createQuote();
      console.log('respTxn', respTxn);

      quoteId = respTxn.data.createTxn.id;

      var respMachine = await Utils.updateMachine(
        this.state.id,
        this.state.txn.machines[0].machineState.machineType,
        "quoted",
        this.state.userId,
        this.state.userRoleType);
      console.log('respMachine', respMachine);

      // await Utils.createMachine(
      //   quoteId, 
      //   "strata", 
      //   quoteId, 
      //   "strata", 
      //   "fixxting", 
      //   "started",
      //   userId,
      //   roleType);

    } catch (e) {
      console.log(e);
      alert(JSON.stringify(e));
    }

    if(quoteId == null)
      return false;

    this.closeQuote();
    this.props.setLoading(false);
  }

  handleQuoteSelection = async (e) => {
    e.preventDefault();
    this.props.setLoading(true);
    const quote = await Utils.approveQuote(this.state.viewingQuoteId);
    const machine = this.state.machine;

    const updatedMachine = await Utils.updateMachine(
      machine.id,
      (machine.machineState.machineType ?? "fixxting"),
      "quoteselected",
      Cache.getItem("email"),
      Cache.getItem("roleType")
    );
    
    this.closeQuote();
    this.props.setLoading(false);
  }

  handleWorkAction = async (toMachineState) => {
    // update quote
    // await Utils.completeWork(quoteId);

    this.props.setLoading(true);

    // update issue machine
    if(Helpers.isNullOrBlank(toMachineState) !== ""){
      try {  
        const machine = this.state.machine;
  
        await Utils.updateMachine(
          machine.id,
          (machine.machineState.machineType ?? "fixxting"),
          toMachineState,
          this.state.userId,
          this.state.userRoleType
        );

        this.closeGenericDialog("quoteOpen");
      }
      catch (e) {
        console.error(e);
      }
    }

    this.props.setLoading(true);
  }

  handleQuoteForm = async (e) =>{    
    console.log('this.state.transitions', this.state.transitions);
    console.log('this.state.txn.quotes.find(x => x.createdBy === this.state.userId)', this.state.txn.quotes.find(x => x.createdBy === this.state.userId));
    const allowedRoleTypes = ["stratamanager","admin"];
    if(allowedRoleTypes.includes(this.state.userRoleType)){
      await this.handleQuoteSelection(e);
    }
    if(this.state.userRoleType === "contractor"){
      await this.handleWorkAction(this.state.transitions.map(t => t.value).includes("worked") ? "worked" : "scheduled" );
    }
    if(this.state.userRoleType === "supplier"
        && this.state.transitions.map(t => t.value).includes("quoted")
        && this.state.txn.quotes.find(x => x.createdBy === this.state.userId) == null){
      await this.handleQuoteSubmit(e);
    }
    // if(this.state.userRoleType === "supplier"){
    //   const selectedQuote = this.state.txn.quotes.find(x => x.approved === true);
    //   if( selectedQuote != null){
    //     await this.handleWorkComplete(selectedQuote.id);
    //   }else{
    //     await this.handleQuoteSubmit(e);
    //   }
    // }else{
    //   await this.handleQuoteSelection(e);
    // }
  }

  handleInvoiceAddition = async () => {
    const respHandleAttachInvoiceFile = await this.handleAttachInvoiceFile(this.state.approvedQuote.id);
    if(respHandleAttachInvoiceFile == null){
      return;
    }

    this.props.setLoading(true);
    const resp = await Utils.addInvoice(this.state.approvedQuote.id);

    try {
      console.log(this.state.machine.id);

      const machine = this.state.machine;

      await Utils.updateMachine(
        machine.id,
        (machine.machineState.machineType ?? "fixxting"),
        "invoiced",
        this.state.userId,
        this.state.userRoleType
      );
    }
    catch (e) {
      console.error(e);
    }

    console.log('resp', resp);
    this.closeGenericDialog("attachInvoiceOpen");

    this.props.setLoading(false);
  }

  handleInvoiceFileChange = (event) => {
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (event.target.files.length === 0) {
      inputStates["invoiceFile"] = "";
      inputErrors["invoiceFile"] = "";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      this.setState({invoiceFile: null});
      return;
    } 

    if (event.target.files.length > 1) {
      inputStates["invoiceFile"] = "error";
      inputErrors["invoiceFile"] = "Multiple files were slected. Please select one file";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    } 

    var file = event.target.files[0];
    
    if (!Helpers.checkLegitImage(file.name)
        && !Helpers.checkLegitDocument(file.name)) {
      inputStates["invoiceFile"] = "error";
      inputErrors["invoiceFile"] = "This file format is not supported";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    const maxFileSize = config.MAX_ATTACHMENT_SIZE_FILE;

    if (file.size > maxFileSize) {
      inputStates["invoiceFile"] = "error";
      inputErrors["invoiceFile"] = `Please select a file smaller than ${maxFileSize / 1000000} MB.`;
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    } 

    inputStates["invoiceFile"] = "success";
    inputErrors["invoiceFile"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });
    this.setState({invoiceFile: file});
  }

  handleAttachInvoiceFile = async (workOrderId) => {
    let valid = true;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (this.state.invoiceFile == null) {
      valid = false;
      inputStates["invoiceFile"] = "error";
      inputErrors["invoiceFile"] = "Invoice file is required";
    } 

    if (this.state.invoiceFileCaption == null || this.state.invoiceFileCaption === "") {
      valid = false;
      inputStates["invoiceFileCaption"] = "error";
      inputErrors["invoiceFileCaption"] = "This field is required";
    } 
    // console.log('valid', valid);
    // console.log('inputStates', inputStates);
    if(!valid) {
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    inputStates["invoiceFile"] = "success";
    inputErrors["invoiceFile"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    this.props.setLoading(true);

    Amplify.configure(strataApiConfig);
    let respCreateAttachmentMedia = null;
    let respCreateMachine = null;
    
    try {
      const invoiceFileCaption = this.state.invoiceFileCaption;
      const invoiceFile = this.state.invoiceFile;
      const formattedName = invoiceFile.name
        .replace("-", " ")
        .replace("_", " ")
        .replace(/[^a-zA-Z0-9\. ]/g, "")
        .replace(/[ ]+/g, "-");
      const fileName = (Helpers.generateGuid() + "_" + formattedName).toLowerCase(); // must not have spaces in file names

      const prefix = "quote/" + workOrderId;
      var result = await FileS3.upload(invoiceFile, prefix, fileName);     
      
      const annotation = "Invoice - " + invoiceFileCaption;
      const attachmentSubType = "invoice";

      respCreateAttachmentMedia = await Utils.createAttachmentMedia(
        workOrderId, 
        "strata",
        result.privateFileKey, 
        result.privateFileURL,
        invoiceFileCaption,
        invoiceFile.type.toLowerCase(),
        invoiceFile.size / (1024 * 1024),
        attachmentSubType,
        annotation);
      

      const attachmentId = respCreateAttachmentMedia.data.createAttachment.id;

      console.log("respAttachment", respCreateAttachmentMedia);

      respCreateMachine = await Utils.createMachine(
        attachmentId, 
        "attach", 
        workOrderId,
        "strata",
        "thinking", 
        "ticketed",
        this.state.userId,
        this.state.userRoleType);
    } catch (e) {
      console.log("Upload Log",e);
      alert(JSON.stringify(e));
    }
      
    this.props.setLoading(false);

    if(respCreateMachine == null || respCreateAttachmentMedia == null){
      return;
    }

    return respCreateMachine;
  }

  handleEditSuccess = async () => {
    this.menuClickOpen();
    this.closeGenericDialog("editIssueOpen");
    await this.loadTxn();
  }

  // handleEditSuccess = async () => {
  //   this.menuClickOpen();
  //   this.closeGenericDialog("editIssueOpen");
  //   await this.loadTxn();
  // }

  showAttachInvoice = async () => {
    this.setState({attachInvoiceOpen: true});
  }

  handleQuoteActionButton = async (e) => {
    e.preventDefault();

    if(this.state.transitions.map(x => x.value).includes("invoiced")){
      this.showAttachInvoice();
    }else{
      this.openQuoteForm(this.state.txn.quotes.find(x => x.createdBy === this.state.userId));
    }
  }

  notifyActor = async (actor) => {
    if(actor.raci_isResponsible){
      window.alert("The tradesman has already been assigned!");
      return;
    }

    if(!window.confirm(`Are you sure you want to assign ${actor.persona.supplier_companyName ?? (actor.persona.user.person_firstName + " " + actor.persona.user.person_lastName)}?`)){
      return;
    }

    this.props.setLoading(true);
    await Utils.notifyActor(actor.id);
    await this.loadTxn();
    this.props.setLoading(false);

    window.alert("The tradesman has been assigned successfully.");
  }
  
  loadTxn = async () => {
    const txn = await this.getTxn();

    console.log('loadTxn: resp:', txn);

    const id = Cache.getItem("email");
    const roleType = Cache.getItem("roleType");
    const persona = await this.getPersona( id, roleType);

    let trackingMachineIds = [];
    trackingMachineIds.push(...txn.machines.map(x => x.id));
    trackingMachineIds.push(...txn.noteItems.map(x => x.id));
    trackingMachineIds.push(...txn.mediaItems.map(x => x.id));

    this.setState({
      txn: txn,
      machine: txn.machines[0],
      trackingMachineIds: trackingMachineIds,
      companyName: persona.supplier_companyName,
      estimatedCost: txn.estimatedCost
    });

    if(!txn.completed)
    {
      if(this.interval != null)
      {
        clearInterval(this.interval)
      }

      this.interval = setInterval( () => {
        const txn = this.state.txn;
        txn.timeInMinutes = txn.timeInMinutes + 1;
        this.setState({ txn });
      }, 60000)
    }
  }

  loadTxnQuote = async () => {
    const approvedQuote = this.state.txn.quotes.find(x => x.approved === true);
    const roleType = approvedQuote?.createdBy === this.state.userId && Cache.getItem("roleType") === "supplier"
                                                ? "contractor"
                                                : Cache.getItem("roleType");

    this.setState({userRoleType: roleType, approvedQuote: approvedQuote});
  }

  loadTransitions = async () => { 
    // Build valid transitions list for this role given current state
    let transitions = [];
    let facts = [];

    try {
        console.log(this.state.machine);
        const response = await this.getTransitions(this.state.machine);
        facts = response == null ? [] : response.data.applyRule;
        console.log("respTrans", response);
    } catch (e) {
        console.error(e);
        alert("getTransitions: " + JSON.stringify(e));
    }

    for (var i = 0; i < facts.length; i++) {
      let fact = JSON.parse(facts[i].fact);
      if(fact.stateType === "noop")
        continue;
      // if(fact.stateType === "quoteselected")
      //   continue;
      transitions.push({ idx: fact.idx, value: fact.stateType, text: Helpers.capitalize(fact.stateType) });
    }

    transitions.sort((a,b) => (a.idx > b.idx) ? 1 : -1); 

    let fact = JSON.parse(facts[0].fact);

    if (facts.length === 1 
      && (fact.stateType === "end" || fact.stateType === "finish")) { 
      transitions = []; 
    }

    const allowTransition = this.state.txn.pending && transitions.length > 0;

    this.setState({ transitions, allowTransition });

    console.log('transitions', transitions);
  }

  getTxn = async () => {
    Amplify.configure(strataApiConfig);

    const cmd = `
      query(
        $id: ID!
      ) {
        txn: getTxn(
          id: $id
        ){
          id
          refId 
          txnType
          planId
          createdAt
          createdBy
          updatedAt
          updatedBy
          ticket_subject 
          ticket_abnSearch
          ticket_amount
          ticket_description 
          ticket_statusType 
          ticket_categoryType 
          ticket_subCategoryType
          ticket_locationType 
          ticket_priorityRank 
          ticket_impactType
          ticket_estimatedCost
          mediumId
          createdUser {
            person_firstName
            person_lastName
          }
          actors{
            id
            userId
            roleType
            foreignType
            foreignId
            raci_isResponsible
            raci_isAccountable
            raci_isConsulted
            raci_isInformed
            raci_isMutator
            persona{
              userId
              roleType
              supplier_companyName
              supplier_availabilityHour
              user{
                person_firstName
                person_lastName
              }
            }
          }
          medium {
            id
            media_fileKey
            media_title
            media_mimeType
          }
          machines {
            id
            machineState {
                machineType
                stateType
                ignoreStream
                updatedBy
                updatedRoleType
                updatedAt
                transitionActionType
                description
                behaviorType
            }
            machineStates {
                machineType
                stateType
                ignoreStream
                updatedBy
                updatedRoleType
                updatedAt
                transitionActionType
                description
                behaviorType
            }
          }
          notes(
            sortDirection: DESC
            limit: 1000
          ) {
              items {
                  id
                  createdAt
                  note_comment
                  attachmentType
                  text_type
              }
          }
          media(
            sortDirection: ASC
            limit: 1000
          ) {
              items {
                id
                media_fileKey
                media_title
                media_mimeType
              }
          }
          plan{
            planName
            attachments{
              id
              media_fileKey
              media_title
              media_mimeType
              media_annotation
            }
          }
          childTxns{
            id
            refId
            createdBy
            txnType
          }
        }
      }`;

      //quote_subject
      //quote_amount
      //quote_description
      //quote_isSelected
      //quote_isInvoiceAttached
      // work_proposedStartAt

    const result = await API.graphql(graphqlOperation(cmd, { id: this.state.id }));

    console.log('getTxn', result);

    const txn = result.data.txn;
    const machine = txn.machines[0];
    const machineState = machine == null ? null : txn.machines[0].machineState;
    const completed = machineState != null && (machineState.stateType === "end" || machineState.stateType === "finish");

    let data = {
      id: txn.id.trim().split("\n")[0],
      refId: txn.refId,
      txnType: txn.txnType,
      statusType: txn.ticket_statusType,
      planId: txn.planId,
      pending: machineState != null && machineState.stateType !== "end" && machineState.stateType !== "finish",
      subject: txn.ticket_subject,
      description: txn.ticket_description,
      area: txn.ticket_locationType,
      category: txn.ticket_categoryType,
      priority: Enums.getPriorityLabel(txn.ticket_priorityRank),
      estimatedCost: txn.ticket_estimatedCost,
      issueDate: txn.createdAt,
      issuedBy: txn.createdUser == null ? "---" : (txn.createdUser.person_firstName ?? "") + " " + (txn.createdUser.person_lastName ?? ""),
      mediumId: txn.mediumId,
      medium: txn.medium,
      primaryImage: null,
      companyName: "",
      statusCode: machineState == null ? "---" : machineState.stateType,
      statusLabel: machineState == null ? "---" : Enums.getIssueStateStatusLabel(machineState.stateType),
      statusDesc: machineState == null ? "---" : machineState.stateType === "stop" ? "" : machineState.description,
      statusUpdatedAt: machineState == null ? null : machineState.updatedAt,
      timelineItems: machineState == null ? [] : machine.machineStates,
      completed: completed,
      timeInMinutes : 0,
      actors: txn.actors,
      machines: txn.machines,
      noteItems: txn.notes.items,
      mediaItems: txn.media.items,
      quotes: [],
      documentAssets: [],
      assets: [],
      planAssets: []
    };

    if(data.medium != null && data.medium.media_fileKey != null) {
      const thumbKey = data.medium.media_fileKey.replace("public/", "") + "-thumbnail." + Helpers.getFileExtension(data.medium.media_fileKey);
      data.primaryImage = await Storage.get(thumbKey);
    }
     
    txn.childTxns.filter(x=> x.txnType === "quote")
        .forEach(async item => {
          var supplier = await this.getPersona(item.createdBy, 'supplier');
          // data.actors.find(x => x.userId === item.createdBy)["quoteAmount"] = item.quote_amount;
          // data.actors.find(x => x.userId === item.createdBy)["quoteSubmitted"] = true;
          data.quotes.push({
            id: item.id,
            refId: item.refId ?? "",
            createdBy: item.createdBy ?? "",
            supplierName: supplier?.supplier_companyName ?? "",
            availabilityHours: supplier?.supplier_availabilityHour ?? 0,
            amount: item.quote_amount,
            description: item.quote_description ?? "",
            proposedStartAt: item.work_proposedStartAt,
            isInvoiceAttached: item.quote_isInvoiceAttached,
            approved: item.quote_isSelected === null ? false : item.quote_isSelected
          });
    }); 
    
    const id = Cache.getItem("email");

    const hasSupplierQuote = txn.childTxns.filter(x=> x.txnType === "quote" && x.createdBy === id).length > 0
    const supplierQuoteAmount = txn.childTxns.find(x=> x.txnType === "quote" && x.createdBy === id)?.quote_amount ?? 0;

    data.estimate = this.state.userRoleType !== "supplier" 
                        ? (txn.ticket_estimatedCost??0) === 0 
                              ? "--" 
                              : Helpers.formatCurrencyNoFractions(txn.ticket_estimatedCost)
                        :  hasSupplierQuote
                                ? Helpers.formatCurrencyNoFractions(supplierQuoteAmount)
                                : "--"

    var planAssets = await Helpers.formatMediaToAssets(txn.plan.attachments);
    data.assets = await Helpers.formatMediaToAssets(data.mediaItems);
    data.planAssets = planAssets.filter(x => x.category === "document");
    data.timelineItems.sort((a,b) => (a.updatedAt > b.updatedAt) ? -1 : 1); 
    
    const dataEnd = data.completed 
            ? new Date(data.timelineItems[0].updatedAt)
            : null;

    data.timeInMinutes = Helpers.calculateDateDiffInMin(
      new Date(data.timelineItems[data.timelineItems.length-1].updatedAt), 
      dataEnd); 
      
    return data;
  }

  getTransitions = machine => {
    Amplify.configure(utilsApiConfig);
    
    if(machine == null) 
      return null;

    const fact = {
      roleType: this.state.userRoleType,
      machineType: (machine.machineState.machineType ?? "fixxting"),
      stateType: machine.machineState.stateType ?? ""
    };

    let params = {
      facts: [JSON.stringify(fact)],
      ruleName: "getTransitions"
    }

    console.log('getTransitions params:', params);

    const cmd = `
      query(
        $facts: [String]
        $ruleName: String
      ){
        applyRule(
          facts: $facts
          ruleName: $ruleName
        ){
          fact
        }
      }`;

    return API.graphql(graphqlOperation(cmd, params));
  }

  createQuote = () => {
    Amplify.configure(strataApiConfig);

    const params = {
      txnType: "quote",
      parentId: this.state.id,
      addedAt: new Date(this.state.addedAt).getTime(),
      createdBy: this.state.userId,
      quote_subject: this.state.companyName,
      quote_amount: this.state.quoteAmount,
      quote_description: this.state.quoteDescription,
      quote_isSelected: false,
      work_proposedStartAt: new Date(this.state.proposedStartAt).getTime(),
    };

    console.log(params);

    const cmd = `
      mutation(
        $txnType: String!
        $parentId: String
        $addedAt: Float
        $createdBy: String
        $quote_subject: String
        $quote_amount: Float
        $quote_description: String
        $quote_isSelected: Boolean
        $work_proposedStartAt: Float
      ){
        createTxn(input: {
          txnType: $txnType
          parentId: $parentId
          addedAt: $addedAt
          createdBy: $createdBy
          quote_subject: $quote_subject
          quote_amount: $quote_amount
          quote_description: $quote_description
          quote_isSelected: $quote_isSelected
          work_proposedStartAt: $work_proposedStartAt
        }){ 
            id
            refId
            addedAt
        }
      }`; 

    // console.log(cmd);
    
    return API.graphql(graphqlOperation(cmd, params));
  }

  showPlanAssets = (assets) => {
    return assets.length !== 0;
  }

  findWorkOrder = (quotes) => {
    const rolesHaveAccess = ["admin", "stratamanager", "propertymanager"];

    if(!rolesHaveAccess.includes(this.state.userRoleType))
      return null;

    return quotes.find(x=> x.approved === true) === undefined 
                  ? null 
                  : quotes.find(x=> x.approved === true);
  }

  closeIssue = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.menuClickOpen();

    if(!window.confirm('Are you sure you want to close this issue?')){
      return;
    }

    this.props.setLoading(true);
    
    try {
      const issue = await Utils.closeIssue(this.state.id);
      // console.log('issue', issue);
      await this.loadTxn();

      window.alert("This issue has been successfully closed.");
    }
    catch (e) {
      console.log(e);
    }

    this.props.setLoading(false);
  }
  
  renderTimeline = items => {
    return items.map((item, index) => 
        <TimelineItem 
          key={index} 
          data={item}
          previousStateItem={items.length > (index + 1) ? items[index +1] : null } />);
  }

  renderNotes = items => {
    return items.map((item, index) => 
        <NoteItem 
          key={index} 
          data={item} />);
  }
  
  renderAttachments = (items, canManage) => {
    return items.map((item, index) => 
        <AttachmentItem 
          key={index} 
          data={item}
          canManage={canManage}
          handleDeleteMedia={(e) => {this.handleDeleteMedia(item.id);} } />);
  }

  renderSupplierQuotes = (issueSuppliers) => {
    const GreenCheckbox = withStyles({
      root: {
        color: "#9e9e9e",
        '&$checked': {
          color: "#5fbb47",
        },
      },
      checked: {},
    })(props => <Checkbox icon={<CheckBox />} color="default" {...props} />);

    // console.log('issueSuppliers', issueSuppliers);

    return issueSuppliers.map((item, index) =>{
      const quote = this.state.txn.quotes.find(x => x.createdBy === item.userId);
      return (
        <div key={item.id} className="supplier-quotes-container">
          <a href="#" onClick={e => {e.preventDefault(); if(quote != null) this.openQuoteForm(quote);}}>
            <QuoteListItem key={index} data={item}/>
          </a>
          {
            this.state.userRoleType !== "tenant" &&
            <FormControlLabel
              className="actor-notify-check"
              control={
                <GreenCheckbox
                  checked={item.raci_isResponsible}
                  onChange={async e => { e.preventDefault(); this.notifyActor(item)}}
                  value={item.raci_isResponsible}
                />
              }
            />
          }
        </div>
        );
    });
  }

  renderQuoteSupplierNames = (issueSuppliers) => {
    console.log('issueSuppliers', issueSuppliers);
    return issueSuppliers.map((supplier, index) =>{
      const persona = supplier.persona;
      return (
        <div className="prop-doc-item clearfix" key={index}>
          <a href={`/users/detail/${supplier.userId}`} target="_blank" className="doc-name">
            {persona.user.person_firstName + " " + persona.user.person_lastName + (persona.supplier_companyName == null ? "" : (" - " + persona.supplier_companyName))}
          </a>
        </div>
        );
    });
  }
  
  renderQuoteFormDialog = () => {
    let isViewOnly = true;
    let dialogTitle = "View Quote";
    let successBtnText = "";
    let successBtnShow = false;
    let successBtnDisabled = false;
    const transitionStates = this.state.transitions.map(x => x.value);

    if(["stratamanager","propertymanager","admin"].includes(this.state.userRoleType) && transitionStates.includes("quoteselected")){
      successBtnText = "Accept";
      successBtnShow = true;
    }

    if(this.state.userRoleType === "contractor" ){
      dialogTitle = "Work Order Details";
      successBtnText = transitionStates.includes("worked")
                              ? "Complete Work"
                              : "Schedule Work";
      successBtnShow = transitionStates.includes("worked") || transitionStates.includes("scheduled") ;
      const dateProposed = this.state.approvedQuote.proposedStartAt;
      const dateNow = new Date();
      successBtnDisabled = transitionStates.includes("worked") && (dateProposed >= dateNow.getTime()) ;
    }

    if(this.state.userRoleType === "supplier"
      && (this.state.txn.quotes?.find(x => x.createdBy === this.state.userId) == null)){
      isViewOnly = false;
      dialogTitle = "Send Quote";
      successBtnText = "Send";
      successBtnShow = true;
    }

    // if(this.state.userRoleType === "supplier" && this.state.approvedQuote != null){
    //   dialogTitle = "Work Order Details";
    //   successBtnText = "Complete Work";
    //   successBtnShow = true;
    //   const dateProposed = this.state.approvedQuote.proposedStartAt;
    //   const stateType = this.state.machine == null ? "" : this.state.machine.machineState.stateType
    //   const dateNow = new Date();
    //   successBtnDisabled = (dateProposed >= dateNow.getTime()) && stateType !== "worked";
    // }

    return (
      <Dialog
        open={this.state.quoteOpen}
        onClose={() => this.closeQuote()}
        disableBackdropClick={false}
        aria-labelledby="form-dialog-title">
          <div className={`modal-popup sm ${isViewOnly ? "disabled-view-only-black" : ""}`}>
            <DialogTitle
              id="form-dialog-title"
              className="text-bold">
                {dialogTitle}
            </DialogTitle>
            <DialogContent className="pdb-lg">
              <div className="row">
                <div className="col-sm-12 pdb-lg">
                  <TextField
                    fullWidth
                    error={this.state.inputStates.companyName === "error"}
                    disabled={true}
                    label="Company Name"
                    value={this.state.companyName}
                  />
                </div>
                <div className={isViewOnly ? "col-sm-6 pdb-lg" : "col-sm-12 pdb-lg"}>
                  <TextField
                    fullWidth
                    disabled={isViewOnly}
                    className="no-spinner"
                    error={this.state.inputStates.quoteAmount === "error"}
                    label="Amount (GST inc.)"
                    value={this.state.quoteAmount}
                    onChange={(e) => this.handleChange("quoteAmount", e.target.value, true)}
                    autoComplete= "off"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      type: "number"
                    }}
                    helperText={this.state.inputErrors.quoteAmount}
                  />
                </div>
                {
                  isViewOnly &&
                  <div className="col-sm-6 pdb-lg">
                    <TextField
                      fullWidth
                      error={this.state.inputStates.quoteNumber === "error"}
                      disabled={true}
                      label={`${this.state.isViewingWorkOrder ? "Work Order Number" : "Quote Number"}`}
                      value={this.state.quoteNumber}
                      onChange={(e) => this.handleChange("quoteNumber", e.target.value, true)}
                      autoComplete= "off"
                      helperText={this.state.inputErrors.quoteNumber}
                    />
                  </div>
                }
                
                <div className="col-sm-12 pdb-lg">
                  <TextField
                    fullWidth
                    error={this.state.inputStates.quoteDescription === "error"}
                    multiline={true}
                    rows={3}
                    rowsMax={3}
                    disabled={isViewOnly}
                    label="Description"
                    value={this.state.quoteDescription}
                    onChange={(e) => this.handleChange("quoteDescription", e.target.value, true)}
                    autoComplete= "off"
                    helperText={this.state.inputErrors.quoteDescription}
                  />
                </div>
                <div className="col-sm-6 pdb-lg">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      disableToolbar
                      error={this.state.inputStates.proposedStartAt === "error"}
                      disabled={isViewOnly}
                      disablePast={true}
                      label="Proposed Start Date"
                      format="dd/MM/yyyy"
                      id="date-added"
                      variant="inline"
                      onChange={(e) => this.handleChange("proposedStartAt", e, true)}
                      value={this.state.proposedStartAt}
                      autoComplete="off"
                      helperText={this.state.inputErrors.proposedStartAt}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="col-sm-6 pdb-lg">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      autoOk
                      fullWidth
                      error={this.state.inputStates.proposedStartAt === "error"}
                      disabled={isViewOnly}
                      label="Start Time"
                      id="time-added"
                      variant="inline"
                      onChange={(e) => this.handleChange("proposedStartAt", e, true)}
                      value={this.state.proposedStartAt}
                      keyboardIcon={<img className="schedule-icon" src="/images/icons/schedule-grey-24px.svg" alt=""/>}
                      helperText={this.state.inputErrors.proposedStartAt}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              
              <div className="clearfix">
                <button
                  className="btn btn-secondary mgt pull-left"
                  onClick={() => this.closeQuote()}
                >Cancel</button>
                {
                  successBtnShow &&
                  <button
                    disabled={successBtnDisabled}
                    className="btn btn-success solid mgt pull-right"
                    onClick={e => this.handleQuoteForm(e)}
                  >{successBtnText}</button>
                }
              </div>
            </DialogContent>
          </div>
      </Dialog>
    );
  }

  renderQuoteActionButton = () => {
    const txn = this.state.txn;
    const transitionStates = this.state.transitions.map(t => t.value);
    const machineState = this.state.machine?.machineState.stateType;
    let newBtnText = "";

    console.log('this.state.transitions', this.state.transitions);
    console.log('machineState', this.state.machine);
    const isContractor = this.state.userRoleType === "contractor";
    const isSupplier = this.state.userRoleType === "supplier";
    const isIssueEnded = ["end", "finish"].includes(machineState);

    if(isContractor){
      newBtnText = isIssueEnded
                          ? "View Invoice"
                          : transitionStates.includes("invoiced")
                                    ? "Attach Invoice"
                                    : transitionStates.includes("worked")
                                              ? "Complete Work"
                                              : transitionStates.includes("scheduled")
                                                      ? "Quote Approved for $" + this.state.approvedQuote.amount
                                                      : "";
    } else
    if(isSupplier && this.state.approvedQuote == null){
     
      // let btnText = txn.quotes[0] != null
      //                                 ? this.state.approvedQuote != null
      //                                                   ? issueStateType === "worked"
      //                                                                   ? "Attach Invoice"
      //                                                                   : "Quote Approved for $" + this.state.approvedQuote.amount
      //                                                   : "Quote Sent: " + txn.quotes[0]?.refId
      //                                 : "Send Quote";

      // newBtnText = txn.quotes?.find(x => x.createdBy === this.state.userId) != null
      //                     ? "Quote Sent: " + txn.quotes?.find(x => x.createdBy === this.state.userId).refId
      //                     : this.state.approvedQuote == null  && machineState === "selectquote"
      //                             ? "Send Quote"
      //                             : "";

      newBtnText = txn.quotes?.find(x => x.createdBy === this.state.userId) != null && (machineState === "selectquote" || machineState === "quote")
                            ? "Quote Sent: " + txn.quotes?.find(x => x.createdBy === this.state.userId).refId
                            : this.state.approvedQuote == null  && machineState === "quote"
                                        ? "Send Quote"
                                        : "";
    }
    
    // if(["supplier", "contractor"].includes(this.state.userRoleType) && showQuoteBtn){
    if(((isSupplier && this.state.approvedQuote == null) || isContractor) && newBtnText !== ""){
      return(
        <a href={`${isIssueEnded ? `/quotes/detail/${this.state.approvedQuote.id}` : "#" }`} className="btn btn-success solid pull-right"
          onClick={(e) => { if(!isIssueEnded){this.handleQuoteActionButton(e)}}}>
        {newBtnText}
      </a>
      );
    }
  }
  
  getPersona = async (id, roleType) => {
    Amplify.configure(strataApiConfig);

    const cmd = `
    query(
      $id: ID!
    ){ 
      persona:getPersona(
          id: $id
      )
      {
        id
        supplier_companyName
        supplier_availabilityHour
      } 
    }`;

    const result = await API.graphql(graphqlOperation(cmd, { id: id+"|"+roleType }));

    console.log('result', result);

    return result.data.persona;
  }

  render() {
    if(this.state.txn == null && this.props.isLoading){
      return null;
    }

    if(this.state.txn == null && !this.props.isLoading){
      return (<div className="pdt-lg">Invalid issue</div>);
    }

    const txn = this.state.txn;
    const roleType = this.state.userRoleType;
    
    return (
      <React.Fragment>
        <div className="page-title clearfix">
          <h1 className="pull-left">Issue</h1>
          {this.renderQuoteActionButton()}
        </div>
        <div className="row">
          <div className="col-lg-6 issue-list col-issue-left">
            <div className="issue-item active clearfix">
                <a className="menu-icon" href="#" 
                  onClick={(e) => { e.preventDefault(); this.menuClickOpen(); }}>
                    <img src="/images/icons/more_vert-24px.svg" alt=""/>
                </a>
                <div className={`context-menu ${this.state.menuOpen ? "open" : ""}`}>
                    <ul>
                        {
                          roleType === "supplier" 
                          ? (
                              <React.Fragment>
                                {this.state.txn.quotes.length === 0 
                                  ? (
                                      <li>
                                        <a href="#" onClick={(e) => {e.preventDefault(); this.openQuoteCreation(); this.menuClickOpen();}}>
                                          Send Quote
                                        </a>
                                      </li>
                                    ) 
                                  : (
                                      <li><a href="#">Cancel Quote</a></li>
                                    )
                                }
                              </React.Fragment>
                            )
                          : (
                              <React.Fragment>
                                <li><a href="#" onClick={(e) => { e.preventDefault(); this.openDialog(); this.menuClickOpen(); }}>Add Media</a></li>
                                {
                                  this.state.hasEditAccess &&
                                  <React.Fragment>
                                    <li><a href="#" onClick={(e) => { e.preventDefault(); this.setState({editIssueOpen: true});}}>Edit Issue</a></li>
                                    <li><a href={`/issues/cancel/${txn.id}`}>Cancel Issue</a></li>
                                    {
                                      this.state.approvedQuote?.isInvoiceAttached && txn.statusType !== "closed" &&
                                      <li><a href="#" onClick={e => {e.preventDefault(); this.closeIssue(e);}} >Close Issue</a></li>
                                    }
                                  </React.Fragment>
                                }
                              </React.Fragment>
                            )
                        }
                    </ul>
                </div>

                <div className="clearfix">
                  <div className="ref">
                      <div className="code color-grey text-bold">{ Helpers.isNull(txn.refId, "---") }</div>
                      
                      {
                        txn.primaryImage != null &&
                        <a href="#" onClick={(e) => { e.preventDefault(); this.openAlbum(txn.mediumId); }}>
                            <img className="issue-img" src={txn.primaryImage} alt=""/>
                        </a>
                      }
                      
                      {
                        txn.primaryImage == null && 
                        txn.mediumId != null &&
                        <a href="#" onClick={(e) => e.preventDefault()}>
                            <img className="issue-img proc" src="/images/processing.svg" alt=""/>
                        </a>
                      }
                      
                      {
                        txn.primaryImage == null &&
                        txn.mediumId == null &&
                        <a href="#" onClick={(e) => e.preventDefault()}>
                            <img className="issue-img" src="/images/noimage.png" alt=""/>
                        </a>
                      }
                  </div>
                  <div className="info">
                        <a href={`/issues/detail/${txn.id}`} className="issue-title">
                            { Helpers.isNull(txn.subject, "---") }
                        </a>
                        <div className="color-grey issue-maker small">by {txn.issuedBy}</div>
                        <div className="row mgt">
                            <div className="col-md-5 fields">
                                <div className="field issued">
                                    <div className="data-label">Issue Date</div>
                                    <div className="text-light text-uppercase">{moment(new Date(txn.issueDate)).format("ddd, DD MMM YYYY h:mm A")}</div>
                                </div>
                                <div className="row mgt">
                                    <div className="col-xs-6 field area">
                                        <div className="data-label">Area</div>
                                        <div className="text-light">{ Helpers.isNull(txn.area, "---") }</div>
                                    </div>
                                    <div className="col-xs-6 field category">
                                        <div className="data-label">Category</div>
                                        <div className="text-light">{ Helpers.isNull(txn.category, "---") }</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 status">
                                <div>
                                    <div className="data-label">Issue Status</div>
                                    <div className="text-light text-uppercase color-grey">
                                        {txn.statusUpdatedAt == null ? "---" : moment(new Date(txn.statusUpdatedAt)).format("DD MMM YYYY h:mm A")}
                                    </div>
                                </div>
                                <div>
                                    <img src="/images/icons/label-24px.svg" className="pull-left issue-status-img" alt=""/>
                                    <div className="text-bold text-uppercase issue-status-label lgap mgt">{txn.statusLabel}</div>
                                    <div className="text-light issue-status-text lgap mgt-sm">{txn.statusDesc}</div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="field priority">
                                    <div className="data-label">Priority</div>
                                    <div className="text-uppercase">{ Helpers.isNull(txn.priority, "---") }</div>
                                    <div className={`priority-color ${Helpers.isNull(txn.priority, "").toLowerCase()}`}></div>
                                </div>
                                <div className="field cost mgt">
                                    <div className="data-label">Quote</div>
                                    <div className="text-bold">{txn.estimate}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="desc">
                  <div className="data-label">Issue Description</div>
                  <div className="text-light">{Helpers.isNull(txn.description, "---")}</div>                  
                </div>
                
                {
                  txn.mediaItems.length > 0 &&
                  <div className="issue-section-wrap">
                    <div className="data-label">Media Files</div>
                    <div className="row media-files">
                        {
                          txn.assets.map((item, index) => 
                            item.category === "image" 
                            ? (<div className="col-sm-2 col-xs-4 preview-img" key={index} data-id={item.id}>
                                  <a href="#" className="delete" 
                                    onClick={(e) => { this.handleDeleteMedia(e, item.id);} } >
                                    <img src="/images/icons/delete-24px.svg" alt=""/>
                                  </a>
                                  <a href="#" className={`medium ${txn.mediumId == item.id ? "active" : ""}`} 
                                    onClick={(e) => { this.handleSetMedium(e, item.id);} } >
                                    <img src="/images/icons/done-24px.svg" alt=""/>
                                  </a>
                                  <a href="#" className={`photo ${item.thumbnail == null ? "proc" : ""}`} 
                                    onClick={(e) => { e.preventDefault(); this.openAlbum(item.id); }}>
                                    <img src={item.thumbnail == null ? "/images/processing.svg" : item.thumbnail} alt=""/>
                                  </a>
                                </div>)
                            : (<div className="col-sm-2 col-xs-4 preview-img" key={index} data-id={item.id}>
                                <a href="#" className="delete" 
                                  onClick={(e) => { this.handleDeleteMedia(e, item.id);} } >
                                  <img src="/images/icons/delete-24px.svg" alt=""/>
                                </a>
                                <a href="#" className="photo video" 
                                  onClick={(e) => { e.preventDefault(); this.openAlbum(item.id); }}>
                                  <img src="/images/icons/movie-24px.svg" alt=""/>
                                </a>
                              </div>)
                          )
                        }  
                    </div>                  
                  </div>
                }
                
                {
                  this.findWorkOrder(txn.quotes) !== null &&
                  <div className='issue-section-wrap'>
                    <a className="btn btn-success mgb-10 mgt-sm solid" href={`/quotes/detail/${this.findWorkOrder(txn.quotes).id}`}>
                      Work Order {this.findWorkOrder(txn.quotes).refId}
                    </a>
                  </div>
                }      

                {
                  (this.state.hasEditAccess || this.state.userRoleType === "tenant") &&
                  // txn.actors.filter(x => x.roleType === "supplier").length > 0 &&
                  this.state.machine?.machineStates.map(x => x.stateType).includes("rfq") &&
                  <div className='issue-section-wrap'>
                      <div className="data-label text-white">Quotes | Requested: {txn.actors.filter(x => x.roleType === "supplier" && x.raci_isResponsible).length} | Received: {txn.quotes.length}</div>
                      <div className="prop-docs">
                        {this.renderSupplierQuotes(txn.actors.filter(x => x.roleType === "supplier"))}
                      </div>
                  </div>
                }

                {
                  this.state.hasEditAccess &&
                  this.state.machine?.machineStates.map(x => x.stateType).includes("rfq") &&
                  <div className='issue-section-wrap'>
                     <a href="#" className="text-bold green" onClick={(e) => { e.preventDefault(); this.setState({selectSuppliersOpen: true});}}>Assign Manual Tradesmen</a>
                  </div>
                }

                {
                  this.showPlanAssets(txn.planAssets) &&
                  <React.Fragment>
                    <div className='issue-section-wrap issue-plan-attachment' >
                        <div className="data-label">Property Documents&nbsp;&nbsp;<img src="/images/icons/featured_play_list-24px.svg" alt="" className="icon" /></div>
                        <div className="prop-docs">
                          {this.renderAttachments(txn.planAssets, false)}  
                        </div>
                    </div>
                  </React.Fragment>
                }
            </div>
          </div>

          <div className="col-lg-6 col-issue-right">
            <div className="tabs-wrap">
              <ul className="tabs clearfix c3">
                <li className={this.state.activeTab == 1 ? "active" : ""}>
                  <a href="#" onClick={(e) => { this.activateTab(e, 1); }}>
                    <img src="/images/icons/schedule-24px.svg" alt="" className="icon" />
                    Timelines
                  </a>
                </li>
                <li className={this.state.activeTab == 2 ? "active" : ""}>
                  <a href="#" onClick={(e) => { this.activateTab(e, 2); }}>
                    <img src="/images/icons/notifications-24px.svg" alt="" className="icon" />
                    Issue Notes
                  </a>
                </li>
                <li className={this.state.activeTab == 3 ? "active" : ""}>
                  <a href="#" onClick={(e) => { this.activateTab(e, 3); }}>
                    <img src="/images/icons/tradesman-24px.svg" alt="" className="icon" />
                    Tradesmen
                  </a>
                </li>
              </ul>
            </div>

            <div className={`tab-view ${this.state.activeTab == 1 ? "active" : ""}`}> 
              {
                this.state.transitionPanelVisible && 
                this.state.allowTransition &&       
                <div className="clearfix transition-actions">
                  {
                    this.state.transitions.map((item, index) => {
                      const actionLabel = Enums.getIssueStateTransitionLabel(item.value);
                      return(
                        actionLabel != "" &&
                        <React.Fragment key={index}>
                          {
                            item.value !== "quoteselected" &&
                            <div className="action-wrap mgb-20" key={item.idx} >
                              <a href="#" className={`btn btn-info solid ${Enums.getIssueStateCssClass(item.value)}`}
                                onClick={(e) => { 
                                  e.preventDefault(); 
                                  if(item.value === "approved" && txn.estimatedCost == null) {
                                    this.handleUpdateEstimatedCost();
                                  } else {
                                    this.handleTransitState(item.value);   
                                  }
                                }}>
                                {actionLabel}
                              </a>
                            </div>
                          }
                        </React.Fragment>
                      );
                    })
                  }
                </div>
              }                
              
              {
                this.state.transitionPanelVisible && 
                !this.state.allowTransition && 
                txn.pending &&
                <div className="pdb-lg">
                  <div className="issue-feedback-wrap">
                  {Helpers.isNullOrBlank(txn.statusDesc, "Waiting")}
                  </div>
                </div>
              }        
              
              {
                !txn.pending &&
                <div className="pdb-lg">
                  <div className="issue-feedback-wrap">
                  The issue is RESOLVED.
                  </div>
                </div>
              }   

              {
                txn.completed &&
                <div className="text-light issue-status-date small color-grey text-bold mgb-lg">Total time taken : {Helpers.formatDateDiffMinutesToText(txn.timeInMinutes)}</div> 
              }

              {
                !txn.completed &&
                <div className="text-light issue-status-date small color-grey text-bold mgb-lg">Pending for : {Helpers.formatDateDiffMinutesToText(txn.timeInMinutes)}</div> 
              }

              <div className="pdb">
                { this.renderTimeline(txn.timelineItems) }
              </div>
            </div>

            <div className={`tab-view ${this.state.activeTab == 2 ? "active" : ""}`}>
              <div className="issue-control-wrap">
                  <textarea 
                    rows="3" 
                    placeholder="note..."
                    value={this.state.noteComment}
                    onChange={(e) => this.handleChange("noteComment", e.target.value) } />
              </div>
              
              <span className="text-danger small">{this.state.inputErrors["noteComment"]}</span>
              
              <div className="pdt pdb-lg clearfix">
                <a href="#" className="action-btn pull-right" onClick={(e) => { e.preventDefault(); this.handleAddNote(); }}>
                  <img src="/images/icons/send-24px.svg" alt="" className="icon" />
                  Add a note
                </a>
              </div>
                    
              { this.renderNotes(txn.noteItems) }
            </div>

            <div className={`tab-view ${this.state.activeTab == 3 ? "active" : ""}`}> 
              <div className="prop-docs">
                {this.renderQuoteSupplierNames(txn.actors.filter(x => x.roleType === "supplier"))}  
              </div>
            </div>

          </div>
        </div>

        <Dialog
          open={this.state.modalOpen}
          onClose={() => this.closeDialog()}
          disableBackdropClick={false}
          aria-labelledby="form-dialog-title">
            <div className="modal-popup sm">
              <DialogTitle
                id="form-dialog-title"
                className="text-bold">
                  Upload File
              </DialogTitle>
              <DialogContent className="pdb-lg">
                <div className="pdb-lg">
                  <label>File</label>
                  <div className="clearfix">
                    <label htmlFor="media_input" className="fu-wrap">
                      <span>{this.state.selectedFile == null ? "Browse..." : this.state.selectedFile.name}</span>
                      <input 
                        type="file" 
                        id="media_input" 
                        onChange={(e) => this.handleMediaChange(e)} />
                    </label>
                  </div>
                  <span className="text-danger small">{this.state.inputErrors.selectedFile}</span>
                </div>
                
                <div className="pdb-lg">
                  <TextField
                    fullWidth
                    error={this.state.inputStates.fileCaption === "error"}
                    label="Caption"
                    value={this.state.fileCaption}
                    onChange={(e) => this.handleChange("fileCaption", e.target.value, true)}
                    autoComplete= "off"
                    helperText={this.state.inputErrors.fileCaption} />
                </div>
                
                <div className="clearfix">
                  <button
                    className="btn btn-secondary mgt pull-left"
                    onClick={() => this.closeDialog()}>
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary mgt pull-right"
                    onClick={() => this.handleAddMedia()}>
                    Upload
                  </button>
                </div>
              </DialogContent>
            </div>
        </Dialog>

        <Dialog
          open={this.state.albumOpened}
          onClose={() => this.closeAlbum()}
          disableBackdropClick={false}
          aria-labelledby="form-dialog-title">
            <div className="modal-popup album">
              <DialogContent>
                  {
                    txn.assets.length > 1 &&
                    <React.Fragment>
                      <a href="#" className={`prev ${this.state.displayMedia?.category}`} onClick={(e) => { e.preventDefault(); this.handleAlbumPrev(); }}>
                        <span>
                          <img src="/images/icons/chevron_left-24px.svg" />
                        </span>
                      </a>
                      <a href="#" className={`next ${this.state.displayMedia?.category}`} onClick={(e) => { e.preventDefault(); this.handleAlbumNext(); }}>
                        <span>
                          <img src="/images/icons/chevron_right-24px.svg" />
                        </span>
                      </a>
                    </React.Fragment>
                  }
                  <div className="album-photos">
                      {
                        txn.assets.map((item, index) => {
                          const active = (this.state.displayMedia == null && index == 0) 
                            || this.state.displayMedia?.id == item.id 
                              ? "active" 
                              : "";

                          return item.file == null
                            ? (<img src="/images/noimage.png" key={index} alt="" className={active} />)
                            : item.category === "image" 
                              ? (<img src={item.file} key={index} alt="" className={active} />)
                              : (
                                  <video key={index} className={active} id={`video_${item.id}`} preload="auto" controls>
                                    <source src={item.file} type={item.mimeType} />
                                  </video>
                                )
                        }
                        )
                      }
                  </div>
              </DialogContent>
            </div>
        </Dialog>

        {this.renderQuoteFormDialog()}

        <Dialog
          open={this.state.updateEstimatedCostOpen}
          onClose={() => this.updateQuoteClose()}
          disableBackdropClick={false}
          aria-labelledby="form-dialog-title">
            <div className="modal-popup sm">
              <DialogTitle
                id="form-dialog-title"
                className="text-bold">
                  Approve Issue
              </DialogTitle>
              <DialogContent className="pdb-lg">
                <div className="row">
                  <div className="col-sm-12 pdb-lg">
                    <TextField
                      fullWidth
                      className="no-spinner"
                      error={this.state.inputStates.estimatedCost === "error"}
                      label="What is estimate cost of this issue"
                      value={this.state.estimatedCost}
                      onChange={(e) => this.handleChange("estimatedCost", e.target.value, true)}
                      helperText={this.state.inputErrors.estimatedCost}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        type: "number"
                      }}
                    />
                  </div>
                </div>
                <div className="clearfix">
                  <button
                    className="btn btn-secondary mgt pull-left"
                    onClick={() => this.updateQuoteClose()}>
                    Cancel
                  </button>
                  <button
                    className="btn btn-success mgt solid pull-right"
                    onClick={this.handleUpdateEstimatedCostSubmit}
                  >
                    Approve
                  </button>
                </div>
              </DialogContent>
            </div>
        </Dialog>

        <Dialog
          open={this.state.attachInvoiceOpen}
          onClose={e => this.closeGenericDialog("attachInvoiceOpen")}
          disableBackdropClick={false}
          aria-labelledby="form-dialog-title">
            <div className="modal-popup sm">
              <DialogTitle
                id="form-dialog-title"
                className="text-bold">
                  Attach Invoice
              </DialogTitle>
              <DialogContent className="pdb-lg">
                <div className="row">
                  <div className="col-sm-12 pdb-lg">
                    <label>File*</label>
                    <div className="clearfix">
                      <label htmlFor="media_input" className="fu-wrap">
                        <span>{this.state.invoiceFile == null ? "Browse..." : this.state.invoiceFile.name}</span>
                        <input 
                          type="file" 
                          id="media_input" 
                          onChange={(e) => this.handleInvoiceFileChange(e)} />
                      </label>
                    </div>
                    <span className="text-danger small">{this.state.inputErrors.invoiceFile}</span>
                  </div>
                  <div className="col-sm-12 pdb-lg">
                    <TextField
                      fullWidth
                      error={this.state.inputStates.invoiceFileCaption === "error"}
                      label="Caption"
                      value={this.state.invoiceFileCaption}
                      onChange={(e) => this.handleChange("invoiceFileCaption", e.target.value, true)}
                      helperText={this.state.inputErrors.invoiceFileCaption}
                    />
                  </div>
                </div>
                <div className="clearfix">
                  <button
                    className="btn btn-secondary mgt pull-left"
                    onClick={e => this.closeGenericDialog("attachInvoiceOpen")}>
                    Cancel
                  </button>
                  <button
                    className="btn btn-success mgt solid pull-right"
                    onClick={e => this.handleInvoiceAddition()}
                  >
                    Invoiced
                  </button>
                </div>
              </DialogContent>
            </div>
        </Dialog>

        <Dialog
          open={this.state.editIssueOpen}
          onClose={e => this.closeGenericDialog("editIssueOpen")}
          disableBackdropClick={false}
          aria-labelledby="form-dialog-title">
            <div className="modal-popup sm">
              <DialogTitle
                id="form-dialog-title"
                className="text-bold">
                  Edit Issue Details
              </DialogTitle>
              <DialogContent className="pdb-lg">
                <IssueEdit {...this.props} 
                  id={this.state.id}
                  handleEditSuccess={this.handleEditSuccess} 
                  handleClose={this.closeGenericDialog}>
                </IssueEdit>
              </DialogContent>
            </div>
        </Dialog>

        <Dialog
          open={this.state.selectSuppliersOpen}
          onClose={e => this.closeGenericDialog("selectSuppliersOpen")}
          disableBackdropClick={false}
          aria-labelledby="form-dialog-title">
            <div className="modal-popup xl">
              <DialogTitle
                id="form-dialog-title"
                className="text-bold">
                  Suppliers
              </DialogTitle>
              <DialogContent className="pdb-lg">
                <Suppliers
                  {...this.props}
                  issueId={this.state.id}
                  selectedSuppliers={txn.actors.filter(x => x.roleType === "supplier")}
                  loadTxn={this.loadTxn}
                  closeDialog={this.closeGenericDialog}
                >
                </Suppliers>
                <div className="clearfix">
                  <button
                    className="btn btn-success mgb pull-right"
                    onClick={e => this.closeGenericDialog("selectSuppliersOpen")}
                  >
                    Close
                  </button>
                </div>
              </DialogContent>
            </div>
        </Dialog>

      </React.Fragment>
    );
  }
}

export default IssueDetail;
