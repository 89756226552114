import React from "react";
import { Redirect } from "react-router-dom";
import Amplify, { API, Cache, Storage, graphqlOperation } from "aws-amplify";
import * as Helpers from "libs/Helpers";
import * as Enums from "libs/Enums";
import * as Utils from "libs/Utils";
import config from "config";
import IssueItem from './parts/IssueItem';

import strataApiConfig from "config-api-STRATA";

class IssueIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      planId: this.props.match.params.id ?? Cache.getItem("planId"),
      openIndex: -1,
      txns: []
    };
  }
  
  menuClickOpen = rank => {
    this.setState({
      openIndex: (rank === this.state.openIndex ? -1 : rank)
    });
  }
  
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  async componentDidMount() {
    if(Helpers.isClientRole(Cache.getItem("roleType"))) {
      const planId = this.state.planId;

      if(planId != null){
        const plan = await Utils.getPlan(planId);
        this.setState({ planId: (plan.planType === "lot" ? plan.parentId : plan.id)  });
      }
    }
    else {
      const planId = this.state.planId;
      const plan = await Utils.getPlan(planId);

      if(plan.id !== Cache.getItem("planId") && plan.planType !== "lot"){
        const now = new Date();
        const expiry = now.setMinutes(now.getMinutes() + config.AUTH_SESSION_DURATION_MINUTES);

        await Cache.setItem("planId", plan.id, { expires: expiry });
        await Cache.setItem("planName", plan.planName, { expires: expiry });
      }
    }

    console.log(this.state);

    if(this.state.planId == null)
      return;

    await this.loadTxns();
  }

  async getTxns() {
    Amplify.configure(strataApiConfig);

    let txns = [];
    
    try {
      const params = {
        txnType: "ticket", 
        planId: this.state.planId
      };

      const cmd = `
        query(
          $txnType: String!
          $planId: String!
        ) {
          txns: listTxns(
            filter: {
              txnType: { eq: $txnType },
              planId: { eq: $planId }
            },
            limit: 10000
          ){
            items {
              id
              refId 
              txnType
              planId
              createdAt
              updatedAt
              ticket_subject 
              ticket_abnSearch
              ticket_estimatedCost
              ticket_description 
              ticket_statusType 
              ticket_categoryType 
              ticket_subCategoryType 
              ticket_locationType 
              ticket_priorityRank 
              ticket_impactType 
              createdUser {
                person_firstName
                person_lastName
              }
              medium {
                id
                media_fileKey
                media_title
                media_mimeType
              }
              plan {
                  id
                  planType
                  planName
              }
              machines {
                machineState {
                    machineType
                    stateType
                    ignoreStream
                    updatedBy
                    updatedRoleType
                    updatedAt
                    transitionActionType
                    description
                    behaviorType
                }
                machineStates {
                    machineType
                    stateType
                    ignoreStream
                    updatedBy
                    updatedRoleType
                    updatedAt
                    transitionActionType
                    description
                    behaviorType
                }
              }
              actors {
                userId
                roleType
                foreignType
              }
            }
          }
        }`;

      const result = await API.graphql(graphqlOperation(cmd, params));

      console.log(result);

      if (result.data.txns.items.length > 0) { 
        txns = result.data.txns.items; 
      }
    } catch (e) {
      console.log(e);
      alert(JSON.stringify(e));
    }

    const userId = Cache.getItem("email");
    const roleType = Cache.getItem("roleType");

    if (roleType !== "admin") {
      txns = txns.filter(x => 
        x.actors.find(y => y.userId === userId && y.roleType === roleType && y.foreignType === "ticket")
      );
    }

    const completeStates = ["end", "finish"];

    if (this.props.status === "PENDING") {
      txns = txns.filter(x =>
        x.machines[0] != null 
        && x.machines[0].machineState != null
        && !completeStates.includes(x.machines[0].machineState.stateType)
      );
    }
    else if (this.props.status === "RESOLVED") {
      txns = txns.filter(x =>
        x.machines[0] != null 
        && x.machines[0].machineState != null
        && completeStates.includes(x.machines[0].machineState.stateType) 
      );
    }
    else if (this.props.status === "DRAFT") {
      txns = txns.filter(x => 
        x.machines[0] == null 
        || x.machines[0].machineState == null
      );
    }

    txns = txns.sort((a,b) => (a.createdAt > b.createdAt) ? -1 : 1); 

    txns = txns.map((x) => {
      const machine = x.machines[0];
      const machineState = machine == null ? null : machine.machineState;

      return {
        id: x.id.trim().split("\n")[0],
        refId: x.refId,
        subject: x.ticket_subject,
        description: x.ticket_description,
        area: x.ticket_locationType,
        category: x.ticket_categoryType,
        priority: Enums.getPriorityLabel(x.ticket_priorityRank),
        estimate: x.ticket_estimatedCost,
        issueDate: x.addedAt ?? x.createdAt,
        issuedBy: x.createdUser == null ? "---" : (x.createdUser.person_firstName ?? "") + " " + (x.createdUser.person_lastName ?? ""),
        medium: x.medium,
        thumbnail: null,
        statusLabel: machineState == null
            ? "DRAFT"
            : x.ticket_statusType === "closed"
                  ? "RESOLVED"
                  : (Enums.getIssueStateStatusLabel(machineState.stateType) ?? "").toUpperCase(),
        statusCode: machineState == null ? "" : machineState.stateType,
        statusDesc: machineState == null ? "---" : machineState.description,
        statusUpdatedAt: machineState == null ? null : machineState.updatedAt,
        pending: machineState != null && !completeStates.includes(machineState.stateType) && x.ticket_statusType !== "closed",
        resolved: x.ticket_statusType === "closed",
        draft: machine == null
      };
    });

    for(var i = 0; i < txns.length; i++) {
      if(txns[i].medium != null && txns[i].medium.media_fileKey != null) {
        const thumbKey = txns[i].medium.media_fileKey.replace("public/", "") + "-thumbnail." + Helpers.getFileExtension(txns[i].medium.media_fileKey);
        txns[i].thumbnail = await Storage.get(thumbKey);
      }
    }

    console.log(txns);

    return txns;
  }

  async loadTxns() {
    this.props.setLoading(true);
    const txns = await this.getTxns();
    this.setState({ txns: txns });
    this.props.setLoading(false);
  }

  render() {
    //console.log(this.state.planId == null);
    if (this.state.planId == null) {
      return (<Redirect to="/account/plans" />);
    }

    return (
      <React.Fragment>
        <div className="page-title">
          <a href={`/issues/create/${this.state.planId}`} className="btn btn-success solid pull-right hidden-xs">Report an Issue</a>
          <a href={`/issues/create/${this.state.planId}`} className="btn btn-success solid pull-right visible-xs btn-issue-report">
            <img src="/images/icons/error_outline-24px.svg" alt=""/>
          </a>
          <h1>{this.props.status} Issues ({this.state.txns.length})</h1>
        </div>
        <div className="row">
          <div className="col-lg-6 issue-list">
              {
                this.state.txns.map((item, index) => 
                  <IssueItem 
                    key={index} 
                    index={index}
                    txn={item} 
                    menuOpen={this.state.openIndex === index} 
                    menuClickOpen={() => {this.menuClickOpen(index); } } />)
              }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default IssueIndex;
