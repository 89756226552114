import Amplify, { API, Cache, graphqlOperation } from "aws-amplify";
import * as Helpers from "libs/Helpers";
import strataApiConfig from "config-api-STRATA";
import utilsApiConfig from "config-api-UTILS";

export const getActors = (userId, roleType, foreignType) => {
  Amplify.configure(strataApiConfig);

  const params = { 
    userId: userId,
    roleType: roleType,
    foreignType: foreignType
  };

  const cmd = `
    query(
      $userId: String!
      $roleType: String!
      $foreignType: String!
    ){
      actors: listActors(
          filter: {
              userId: { eq: $userId }
              roleType: { eq: $roleType }
              foreignType: { eq: $foreignType }
          }
          limit: 10000
      ){
          items {
              foreignId
              foreignType
          }
      }
    }`;

  return API.graphql(graphqlOperation(cmd, params));
}

export const findActor = async (userId, roleType, foreignType, foreignId) => {
  Amplify.configure(strataApiConfig);

  const params = { 
    userId: userId,
    roleType: roleType,
    foreignType: foreignType,
    foreignId: foreignId
  };

  const cmd = `
    query(
      $userId: String
      $roleType: String
      $foreignType: String
      $foreignId: String
    ){
      actors: listActors(
          filter: {
              userId: { eq: $userId }
              roleType: { eq: $roleType }
              foreignType: { eq: $foreignType }
              foreignId: { eq: $foreignId }
          }
          limit: 1000
      ){
          items {
              id
              userId
              roleType
              foreignType
              foreignId
          }
      }
    }`;

  const respActors = await API.graphql(graphqlOperation(cmd, params));
  
  //console.log(respActors);

  return respActors.data.actors.items.length > 0 ? respActors.data.actors.items[0] : null;
}

export const createActor = (userId, roleType, foreignType, foreignId) => {
  Amplify.configure(strataApiConfig);

  const params = { 
    userId: userId,
    roleType: roleType,
    foreignType: foreignType,
    foreignId: foreignId
  };

  const cmd = `
    mutation(
      $userId: String!
      $roleType: String!
      $foreignType: String!
      $foreignId: String!
    ){
      createActor(input: {
        userId: $userId
        roleType: $roleType
        foreignType: $foreignType
        foreignId: $foreignId
      }){
        id
      }
    }`;

  return API.graphql(graphqlOperation(cmd, params));
}

export const deleteActor = (id) => {
  Amplify.configure(strataApiConfig);

  const params = { 
    id: id
  };

  const cmd = `
    mutation(
      $id: ID!
    ){
      deleteActor(input: {
        id: $id
      }){
        id
      }
    }`;

  return API.graphql(graphqlOperation(cmd, params));
}

export const notifyActor = (actorId) => {
  Amplify.configure(strataApiConfig);

  const params = {
    id: actorId,
    raci_isResponsible: true
  };

  console.log('params', params);

  const cmd = `
    mutation(
      $id: ID!
      $raci_isResponsible: Boolean
    ){
      actor: updateActor(input: {
        id: $id 
        raci_isResponsible: $raci_isResponsible
      }){ 
          id
      }
    }`; 
  
  const response = API.graphql(graphqlOperation(cmd, params));

  // console.log('response', response);
  
  return response;
}

export const getAccessPlanIds = async (userId, roleType) => {
  const clientRoleTypes = ["owner", "owneroccupier", "tenant"];
  const respActors = await getActors(userId, roleType, (clientRoleTypes.includes(roleType) ? "lot" : "strata"));
  return respActors.data.actors.items.map(x => x.foreignId);
}

export const getAccessIssueIds = async (userId, roleType) => {
  const respActors = await getActors(userId, roleType, "ticket");
  return respActors.data.actors.items.map(x => x.foreignId);
}

export const createMachine = (
  foreignId, 
  foreignType, 
  parentForeignId, 
  parentForeignType, 
  machineType, 
  stateType,
  updatedBy,
  roleType) => {
  Amplify.configure(utilsApiConfig);

  const params = {
    id: foreignId,
    foreignId: foreignId,
    foreignType: foreignType,
    machineType: machineType,
    stateType: stateType,
    updatedBy: updatedBy,
    roleType: roleType
  };

  parentForeignId = parentForeignId ?? "";
  parentForeignType = parentForeignType ?? "";

  if(parentForeignId !== ""){
    params.parentForeignId = parentForeignId;
  }

  if(parentForeignType !== ""){
    params.parentForeignType = parentForeignType;
  }

  const cmd = `
    mutation(
        $id: String!
        $foreignId: String!
        $foreignType: String!
        $machineType: String!
        $stateType: String!
        $updatedBy: String!
        $roleType: String!
        ${parentForeignId !== "" ? "$parentForeignId: String" : ""}
        ${parentForeignType !== "" ? "$parentForeignType: String" : ""}
    ){
        createMachine(input: {
            id: $id
            foreignId: $foreignId
            foreignType: $foreignType
            machineType: $machineType
            stateType: $stateType
            updatedBy: $updatedBy
            roleType: $roleType
            ${parentForeignId !== "" ? "parentForeignId: $parentForeignId" : ""}
            ${parentForeignType !== "" ? "parentForeignType: $parentForeignType" : ""}
        }){ 
            id
            foreignType
            parentForeignId
            parentForeignType
        }
    }`;
  
  return API.graphql(graphqlOperation(cmd, params));
}

export const updateMachine = (
  id, 
  machineType, 
  stateType, 
  updatedBy, 
  roleType) => {

  Amplify.configure(utilsApiConfig);

  let params = {
    id: id,
    machineType: machineType,
    stateType: stateType,
    updatedBy: updatedBy,
    roleType: roleType
  };

  console.log('params', params);
  
  const cmd = `
    mutation(
          $id: String!
          $machineType: String!
          $stateType: String!
          $updatedBy: String!
          $roleType: String!
    ){
      updateMachine( input: {
          id : $id
          machineType: $machineType
          stateType: $stateType
          updatedBy: $updatedBy
          roleType: $roleType
      }){ 
          id 
          foreignType
          parentForeignId
          parentForeignType
          stateType
      }
    }`;

  return API.graphql(graphqlOperation(cmd, params));
}

export const createAttachmentMedia = (
  foreignId, 
  foreignType,
  privateFileKey, 
  privateFileURL, 
  title, 
  mimeType, 
  sizeMB, 
  attachmentSubType, 
  annotation) => {

  Amplify.configure(strataApiConfig);

  const params = {
    attachmentType: "media",
    foreignId: foreignId,
    foreignType: foreignType,
    media_privateFileKey: privateFileKey,
    media_privateFileURL: privateFileURL,
    media_title: title,
    media_mimeType: mimeType,
    media_sizeMB: sizeMB,
    createdBy: Cache.getItem("email")
  };

  attachmentSubType = attachmentSubType ?? "";
  annotation = annotation ?? "";

  if(attachmentSubType !== ""){
    params.attachmentSubType = attachmentSubType;
  }

  if(annotation !== ""){
    params.media_annotation = annotation;
  }

  const cmd = `
    mutation(
        $attachmentType: String!
        $foreignId: String!
        $foreignType: String!
        $media_privateFileKey: String
        $media_privateFileURL: String
        $media_title: String
        $media_mimeType: String
        $media_sizeMB: Float
        $createdBy: String
        ${attachmentSubType !== "" ? "$attachmentSubType: String" : ""}
        ${annotation !== "" ? "$media_annotation: String" : ""}
    ){
        createAttachment(input: {
            attachmentType: $attachmentType
            foreignId: $foreignId
            foreignType: $foreignType
            media_privateFileKey: $media_privateFileKey
            media_privateFileURL: $media_privateFileURL
            media_title: $media_title
            media_mimeType: $media_mimeType,
            media_sizeMB: $media_sizeMB
            createdBy: $createdBy
            ${attachmentSubType !== "" ? "attachmentSubType: $attachmentSubType" : ""}
            ${annotation !== "" ? "media_annotation: $media_annotation" : ""}
        }){ 
            id
        }
    }`; 
  
  return API.graphql(graphqlOperation(cmd, params));
}

export const createAttachmentNote = (
  foreignId, 
  foreignType,
  comment) => {

  Amplify.configure(strataApiConfig);

  const params = {
    attachmentType: "note",
    foreignId: foreignId,
    foreignType: foreignType,
    note_comment: comment,
    createdBy: Cache.getItem("email")
  };

  const cmd = `
    mutation(
        $attachmentType: String!
        $foreignId: String!
        $foreignType: String!
        $note_comment: String
        $createdBy: String
    ){
        createAttachment(input: {
            attachmentType: $attachmentType
            foreignId: $foreignId
            foreignType: $foreignType
            note_comment: $note_comment
            createdBy: $createdBy
        }){ 
            id
        }
    }`; 
  
  return API.graphql(graphqlOperation(cmd, params));
}

export const deleteAttachment = (id) => {
  Amplify.configure(strataApiConfig);

  let params = {
    id: id
  };

  const cmd = `
    mutation(
        $id: ID!
    ){
        deleteAttachment(input: {
            id: $id
        }){ 
            id
        }
    }`;
  
  return API.graphql(graphqlOperation(cmd, params));
}

export const updatePlanMedium = (planId, mediumId) => {
  Amplify.configure(strataApiConfig);

  const params = {
    id: planId,
    mediumId: mediumId
  };

  const cmd = `
    mutation(
      $id: ID!
      $mediumId: String
    ){
      updatePlan(input: {
        id: $id 
        mediumId: $mediumId
      }){ 
          id
          mediumId
      }
    }`; 
  
  return API.graphql(graphqlOperation(cmd, params));
}

export const updateTxnMedium = (txnId, mediumId) => {
  Amplify.configure(strataApiConfig);

  const params = {
    id: txnId,
    mediumId: mediumId
  };

  const cmd = `
    mutation(
      $id: String!
      $mediumId: String
    ){
      updateTxn(input: {
        id: $id 
        mediumId: $mediumId
      }){ 
          id
          mediumId
      }
    }`; 
  
  return API.graphql(graphqlOperation(cmd, params));
}

export const updateTxnEstimatedCost = (txnId, estimatedCost) => {
  Amplify.configure(strataApiConfig);

  const params = {
    id: txnId,
    ticket_amount: estimatedCost,
    ticket_estimatedCost: estimatedCost
  };

  const cmd = `
    mutation(
      $id: String!
      $ticket_amount: Float
      $ticket_estimatedCost: Float
    ){
      updateTxn(input: {
        id: $id 
        ticket_amount: $ticket_amount
        ticket_estimatedCost: $ticket_estimatedCost
      }){ 
          id
          ticket_amount
          ticket_estimatedCost
      }
    }`; 
  
  return API.graphql(graphqlOperation(cmd, params));
}

export const closeIssue = (issueId) => {
  Amplify.configure(strataApiConfig);

  const params = {
    id: issueId,
    ticket_statusType: "closed"
  };

  console.log('params', params);

  const cmd = `
    mutation(
      $id: String!
      $ticket_statusType: String
    ){
      issue: updateTxn(input: {
        id: $id 
        ticket_statusType: $ticket_statusType
      }){ 
          id
          ticket_statusType
      }
    }`; 
  
  const response = API.graphql(graphqlOperation(cmd, params));

  // console.log('response', response);
  
  return response;
}

export const approveQuote = (quoteId) => {
  Amplify.configure(strataApiConfig);

  const params = {
    id: quoteId,
    quote_isSelected: true
  };

  console.log('params', params);

  const cmd = `
    mutation(
      $id: String!
      $quote_isSelected: Boolean
    ){
      quote: updateTxn(input: {
        id: $id 
        quote_isSelected: $quote_isSelected
      }){ 
          id
          quote_isSelected
      }
    }`; 
  
  const response = API.graphql(graphqlOperation(cmd, params));

  // console.log('response', response);
  
  return response;
}

export const AssignTicketSupplier = (issueId, userId) => {
  Amplify.configure(strataApiConfig);

  const params = {
    userId: userId,
    roleType: "supplier",
    foreignType: "ticket",
    foreignId: issueId,
    raci_isResponsible: true
  };

  console.log('params', params);

  const cmd = `
    mutation(
      $userId: String!
      $roleType: String!
      $foreignType: String!
      $foreignId: String!
      $raci_isResponsible: Boolean
    ){
      issue: createActor(
        input: {
          userId: $userId
          roleType: $roleType
          foreignType: $foreignType
          foreignId: $foreignId
          raci_isResponsible: $raci_isResponsible
        }
      ){
          id
      }
    }`; 
  
  const response = API.graphql(graphqlOperation(cmd, params));

  console.log('response', response);
  
  return response;
}

export const addInvoice = (quoteId) => {
  Amplify.configure(strataApiConfig);

  const params = {
    id: quoteId,
    quote_isInvoiceAttached: true
  };

  console.log('params', params);

  const cmd = `
    mutation(
      $id: String!
      $quote_isInvoiceAttached: Boolean
    ){
      issue: updateTxn(input: {
        id: $id 
        quote_isInvoiceAttached: $quote_isInvoiceAttached
      }){ 
          id
          quote_isInvoiceAttached
      }
    }`; 
  
  const response = API.graphql(graphqlOperation(cmd, params));

  console.log('response', response);
  
  return response;
}

// export const completeWork = (issueId) => {
//   Amplify.configure(strataApiConfig);

//   const params = {
//     id: issueId,
//     ticket_statusType: "worked"
//   };

//   console.log('params', params);

//   const cmd = `
//     mutation(
//       $id: String!
//       $ticket_statusType: String
//     ){
//       issue: updateTxn(input: {
//         id: $id 
//         ticket_statusType: $ticket_statusType
//       }){ 
//           id
//           ticket_statusType
//       }
//     }`; 
  
//   const response = API.graphql(graphqlOperation(cmd, params));

//   // console.log('response', response);
  
//   return response.issue;
// }

export const getPlan = async (id) => {
  Amplify.configure(strataApiConfig);
  
  const params = { id: id };

  const cmd = `
    query(
      $id: ID!
    ) {
      plan: getPlan(
        id: $id
      ){
        id
        parentId
        planType
        planName
      }
    }`;

  const response = await API.graphql(graphqlOperation(cmd, params))

  return response.data.plan;
}

export const getPlanIncParent = async (id) => {
  Amplify.configure(strataApiConfig);
  
  const params = { id: id };

  const cmd = `
    query(
      $id: ID!
    ) {
      plan: getPlan(
        id: $id
      ){
        id
        parentId
        planType
        planName
        parentPlan {
          id
          parentId
          planName
        }
      }
    }`;

  const response = await API.graphql(graphqlOperation(cmd, params))

  return response.data.plan;
}

export const getPlans = async (userId, roleType) => {  
  Amplify.configure(strataApiConfig);

  let params = null;
  let cmd = null;

  if(roleType === "admin") {
    params = { planType: "strata" };

    cmd = `
      query(
        $planType: String!
      ) {
        plans: listPlans(
          filter: {
            planType: { eq: $planType }
          },
          limit: 10000
        ){
          items {
            id
            planName
          }
        }
      }`;

    const respPlans = await API.graphql(graphqlOperation(cmd, params));

    let plans = respPlans.data.plans.items;
    plans = plans.sort((a,b) => (a.planName > b.planName) ? 1 : -1);
  
    return plans;
  }

  params = { 
    userId: userId,
    roleType: roleType,
    foreignType: (Helpers.isClientRole(roleType) ? "lot" : "strata")
  };

  cmd = `
    query(
      $userId: String
      $roleType: String
      $foreignType: String
    ){
      actors: listActors(
          filter: {
              userId: { eq: $userId }
              roleType: { eq: $roleType }
              foreignType: { eq: $foreignType }
          }
          limit: 1000
      ){
          items {
              plan {
                id
                planName
              }
          }
      }
    }`;

  const respActors = await API.graphql(graphqlOperation(cmd, params));

  let plans = respActors.data.actors.items.map((x) => {
    return {
      id: x.plan.id,
      planName: x.plan.planName,
    }
  });

  plans = plans.sort((a,b) => (a.planName > b.planName) ? 1 : -1);

  return plans;
}

export const getSubPlans = async (id) => {
  Amplify.configure(strataApiConfig);
  
  const params = { parentId: id };

  const cmd = `
    query(
      $parentId: String
    ) {
      plans: listPlans(
        filter: {
          parentId: { eq: $parentId }
        },
        limit: 10000
      ){
        items {
          id
          planName
        }
      }
    }`;

  const respPlans = await API.graphql(graphqlOperation(cmd, params));

  let plans = respPlans.data.plans.items;
  plans = plans.sort((a,b) => (a.planName > b.planName) ? 1 : -1);

  return plans;
}