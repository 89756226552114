import React from "react";
import moment from 'moment';
import * as Helpers from "libs/Helpers";

class IssueItem extends React.Component {
    render() {
        const { index, txn, menuOpen, menuClickOpen } = this.props;

        return (
            <React.Fragment>
                <div className="issue-item clearfix">
                    <a className="menu-icon" href="#" 
                        onClick={(e) => { e.preventDefault(); menuClickOpen(e); }}>
                        <img src="/images/icons/more_vert-24px.svg" alt=""/>
                    </a>
                    <div className={`context-menu ${menuOpen ? "open" : ""}`}>
                        <ul>
                            <li><a href={`/issues/detail/${txn.id}`}>View Issue</a></li>
                            <li><a href={`/issues/edit/${txn.id}`}>Edit Issue</a></li>
                        </ul>
                    </div>
                    <div className="clearfix">
                        <div className="ref">
                            <div className="code color-grey text-bold">{ Helpers.isNull(txn.refId, "---") }</div>
                            <a href={`/issues/detail/${txn.id}`}>
                                <img className="issue-img" src={txn.thumbnail == null ? "/images/noimage.png" : txn.thumbnail} alt=""/>
                            </a>
                        </div>
                        <div className="info">
                            <a href={`/issues/detail/${txn.id}`} className="issue-title">
                                { Helpers.isNull(txn.subject, "---") }
                            </a>
                            <div className="color-grey issue-maker small">by {txn.issuedBy}</div>
                            <div className="row mgt">
                                <div className="col-md-5 fields">
                                    <div className="field issued">
                                        <div className="data-label">Issue Date</div>
                                        <div className="text-light text-uppercase">{moment(new Date(txn.issueDate)).format("ddd, DD MMM YYYY h:mm A")}</div>
                                    </div>
                                    <div className="row mgt">
                                        <div className="col-xs-6 field area">
                                            <div className="data-label">Area</div>
                                            <div className="text-light">{ Helpers.isNull(txn.area, "---") }</div>
                                        </div>
                                        <div className="col-xs-6 field category">
                                            <div className="data-label">Category</div>
                                            <div className="text-light">{ Helpers.isNull(txn.category, "---") }</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 status">
                                    <div>
                                        <div className="data-label">Issue Status</div>
                                        <div className="text-light text-uppercase color-grey">
                                            {txn.statusUpdatedAt == null ? "---" : moment(new Date(txn.statusUpdatedAt)).format("DD MMM YYYY h:mm A")}
                                        </div>
                                    </div>
                                    <div>
                                        <img src="/images/icons/label-24px.svg" className="pull-left issue-status-img" alt=""/>
                                        <div className="text-bold text-uppercase issue-status-label lgap mgt">
                                            {txn.statusLabel}
                                        </div>
                                        <div className="text-light issue-status-text lgap mgt-sm">{txn.statusCode === "stop" ? "" : txn.statusDesc}</div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="field priority">
                                        <div className="data-label">Priority</div>
                                        <div className="text-uppercase">{ Helpers.isNull(txn.priority, "---") }</div>
                                        <div className={`priority-color ${Helpers.isNull(txn.priority, "").toLowerCase()}`}></div>
                                    </div>
                                    <div className="field cost mgt">
                                        <div className="data-label">Estimate</div>
                                        <div className="text-bold">{Helpers.formatCurrencyNoFractions(txn.estimate)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default IssueItem;