import React from "react";
import { Redirect } from "react-router-dom";
import { Cache } from "aws-amplify";
import DashboardAdmin from './DashboardAdmin';
import DashboardStrataManager from './DashboardStrataManager';
import DashboardPropertyManager from './DashboardPropertyManager';
import DashboardOwner from './DashboardOwner';
import DashboardTenant from './DashboardTenant';
import DashboardSupplier from './DashboardSupplier';

class Index extends React.Component {
  renderDashboard = props => {
    const roleType = Cache.getItem("roleType");

    if (roleType === "admin") {
      return <DashboardAdmin {...props} />;
    }
    else if (roleType === "stratamanager") {
      return <DashboardStrataManager {...props} />;
    }
    else if (roleType === "propertymanager") {
      return <DashboardPropertyManager {...props} />;
    }
    else if (roleType === "owner" || roleType === "owneroccupier") {
      return <DashboardOwner {...props} />;
    }
    else if (roleType === "tenant") {
      return <DashboardTenant {...props} />;
    }
    else if (roleType === "supplier") {
      return <DashboardSupplier {...props} />;
    }
    
    return <Redirect to="/account/personas" />;
  }

  render() {
    return (
      this.renderDashboard(this.props)
    );
  }
}

export default Index;