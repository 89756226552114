import React from "react";

class AttachmentItem extends React.Component {
  render() {
    const data = this.props.data;
    const canManage = this.props.canManage;

    return (
      <React.Fragment>
        <div className="prop-doc-item clearfix">
          {
            canManage && 
            <a href="#" className="doc-act" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.handleDeleteMedia() }}>
              <img src="/images/icons/delete-24px.svg" alt="" />
            </a>
          }
          <a href={data.file} target="_blank" className="doc-name">
            {data.annotation ?? data.title}
          </a>
        </div>
      </React.Fragment>
    )
  }
}

export default AttachmentItem;