import React from "react";
import * as Helpers from "libs/Helpers";

class IssueItem extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <React.Fragment>
        <div className="prop-issue-item clearfix">
          <div className="info">
            <div className={`priority-color ${Helpers.isNull(data.priority, "").toLowerCase()}`}></div>
            <div className="priority">
              { Helpers.isNull(data.priority, "---") }
            </div>
            <div className="status color-grey">
              {data.status}
            </div>
          </div>
          <div className="subject">
            {data.subject}
          </div>
          <div className="actions">
            <a href={`/issues/detail/${data.id}`}>
              <img src="/images/icons/open_in_new-24px.svg" alt=""/>
            </a>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default IssueItem;