import React from "react";
import { withRouter } from "react-router-dom";
import { Auth, Cache } from "aws-amplify";
import { StylesProvider } from "@material-ui/styles";
import Routes from "Routes";

require('dotenv').config();

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: (Cache.getItem("email") != null && Cache.getItem("email") !== ""),
      isLoading: false,
      sidebarCollapsed: false,
      sidebarFloat: false
    };
  }

  async componentDidMount() {
    let auth = this.state.isAuthenticated;

    try {
      await Auth.currentSession();
      auth = Cache.getItem("email") != null && Cache.getItem("email") !== "";
    }
    catch(e) {
      auth = false;
      if (e !== 'No current user') {
        //alert(e);
      }
    }

    if(this.state.isAuthenticated && !auth) {
      await Auth.signOut();
      await Cache.clear();
    }
    
    this.setAuthenticated(auth);
  }

  setAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  setLoading = loading => {
    this.setState({ isLoading: loading });
  }

  logout = async () => {
    await Auth.signOut();
    await Cache.clear();
    this.setAuthenticated(false);
    this.redirect("/login");
  }

  redirect = url => {
    this.props.history.push(url);
  }

  toggleSidebar = event => {
    event.preventDefault();
    //alert(!this.state.sidebarCollapsed);
    this.setState({ sidebarCollapsed: !this.state.sidebarCollapsed });
  }

  floatSidebar = float => {
    this.setState({ sidebarFloat: float });
  }

  render() {
    const props = {
      isAuthenticated: this.state.isAuthenticated,
      isLoading: this.state.isLoading,
      sidebarCollapsed: this.state.sidebarCollapsed,
      sidebarFloat: this.state.sidebarFloat,
      setAuthenticated: this.setAuthenticated,
      setLoading: this.setLoading,
      toggleSidebar: this.toggleSidebar,
      floatSidebar: this.floatSidebar,
      logout: this.logout,
      redirect: this.redirect
    };

    //console.log(this.state.isAuthenticated);

    return (
      <StylesProvider injectFirst>
        <React.Fragment>
          <Routes props={props} />
        </React.Fragment>
      </StylesProvider>
    );
  }
}

export default withRouter(App);
