import React from "react";
import { Redirect } from "react-router-dom";
import Amplify, { API, Cache, graphqlOperation } from "aws-amplify";
import * as Enums from "libs/Enums";
import * as Helpers from "libs/Helpers";
import * as Utils from "libs/Utils";
import * as FileS3 from "libs/FileS3";
import config from "config";
import strataApiConfig from "config-api-STRATA";
import CreateStep1 from './CreateStep1';
import CreateStep2 from './CreateStep2';
import CreateStep3 from './CreateStep3';
import CreateStepDone from './CreateDone';
import Steps from './parts/Steps';

class Create extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      planId: this.props.match.params.id ?? Cache.getItem("planId"),
      planName: "",
      txnRefId: "",
      subPlanId: null,
      subPlanName: "",
      categoryType: "",
      subCategoryType: "",
      priority: null,
      addedAt: null,
      locationType: "",
      subLocationType: "",
      assetType: "",
      subAssetType: "",
      assetName: "",
      subject: "",
      description: "",
      modalOpen: false,
      media: [],
      selectedFile: null,
      fileCaption: "",
      categoryTypeOptions: [],
      locationTypeOptions: [],
      priorityOptions: [],
      assetTypeOptions: [],
      subPlanOptions: [],
      inputStates: {
        categoryType: "",
        subCategoryType: "",
        priority: "",
        addedAt: "",
        locationType: "",
        subLocationType: "",
        assetType: "",
        subAssetType: "",
        assetName: "",
        subject: "",
        description: "",
        selectedFile: "",
        fileCaption: ""
      },
      inputErrors: {
        categoryType: "",
        subCategoryType: "",
        priority: "",
        addedAt: "",
        locationType: "",
        subLocationType: "",
        assetType: "",
        subAssetType: "",
        assetName: "",
        subject: "",
        description: "",
        selectedFile: "",
        fileCaption: ""
      },
      handleChange: this.handleChange.bind(this),
      handleSubPlanChange: this.handleSubPlanChange.bind(this),
      handleMediaChange: this.handleMediaChange.bind(this),
      handleSubmit: this.handleSubmit.bind(this),
      setStep: this.setStep.bind(this),
      nextStep: this.nextStep.bind(this),
      prevStep: this.prevStep.bind(this),
      prevButton: this.prevButton.bind(this),
      nextButton: this.nextButton.bind(this),
      openDialog: this.openDialog.bind(this),
      closeDialog: this.closeDialog.bind(this),
      attachMedia: this.attachMedia.bind(this),
      removeMedia: this.removeMedia.bind(this)
    };
  }

  async componentDidMount() {
    let planId = this.state.planId;
    
    if(planId == null) {
      this.setState({ planId: 0 });
      return;
    }

    const plan = await Utils.getPlanIncParent(planId);

    if(plan.planType === "lot") {
      this.setState({ planId: plan.parentId, planName: plan.parentPlan.planName, subPlanId: plan.id, subPlanName: plan.planName });
    }
    else {
      this.setState({ planId: plan.id, planName: plan.planName });
    }

    var categoryTypeOptions = await Enums.getIssueCategories();
    var locationTypeOptions = await Enums.getLocationTypes();
    var assetTypeOptions = await Enums.getAssetTypes();
    var priorityOptions = Enums.getPriorities();

    //console.log(assetTypeOptions);

    this.setState({ categoryTypeOptions, locationTypeOptions, assetTypeOptions, priorityOptions });
    
    if(!Helpers.isClientRole(Cache.getItem("roleType"))){
      var subPlanOptions = await Utils.getSubPlans(plan.id);
      this.setState({ subPlanOptions });
    }
  }

  openDialog  = () => {
    this.setState({ modalOpen: true });
  };

  closeDialog = () => {
    this.setState({ modalOpen: false, selectedFile: null, fileCaption: "" });
  };

  handleChange = async (name, value, required) => {
    this.setState({ [name]: value }, () => {
      if (required) {
        this.validateRequired(name);
      }
    });
  }

  handleSubPlanChange = (value) => {
    if(value === ""){
      this.setState({ subPlanId: null, subPlanName: "" });
    }
    else{
      const subPlanName = this.state.subPlanOptions.find(x => x.id === value).planName;
      this.setState({ subPlanId: value, subPlanName: subPlanName });
    }
  }

  handleMediaChange = (event) => {
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (event.target.files.length === 0) {
      inputStates["selectedFile"] = "";
      inputErrors["selectedFile"] = "";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      this.setState({selectedFile: null});
      return;
    } 

    if (event.target.files.length > 1) {
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = "Multiple files were slected. Please select one file";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    } 

    var file = event.target.files[0];
    
    if (!Helpers.checkLegitImage(file.name)
      && !Helpers.checkLegitAudio(file.name)
      && !Helpers.checkLegitVideo(file.name)) {
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = "This file format is not supported";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    const category = Helpers.getFileCategory(file.name);

    const maxFileSize = category === "video" 
      ? config.MAX_ATTACHMENT_SIZE_VIDEO
      : config.MAX_ATTACHMENT_SIZE_FILE;

    if (file.size > maxFileSize) {
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = `Please select a file smaller than ${maxFileSize / 1000000} MB.`;
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    } 

    inputStates["selectedFile"] = "success";
    inputErrors["selectedFile"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });
    this.setState({selectedFile: file});
  }
  
  attachMedia = () => {
    let valid = true;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (this.state.selectedFile == null) {
      valid = false;
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = "Media file is required";
    } 

    if (this.state.fileCaption == null || this.state.fileCaption === "") {
      valid = false;
      inputStates["fileCaption"] = "error";
      inputErrors["fileCaption"] = "This field is required";
    } 

    if(!valid) {
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    inputStates["selectedFile"] = "success";
    inputErrors["selectedFile"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    const category = Helpers.getFileCategory(this.state.selectedFile.name);

    if(category != "image") {
      var files = this.state.media;
      
      this.props.setLoading(true);

      files.push({ 
        file: this.state.selectedFile, 
        caption: this.state.fileCaption, 
        previewURL: null,
        category: category
      });

      this.props.setLoading(false);

      this.closeDialog();

      return;
    }

    let reader = new FileReader();
     
    reader.onloadend = () => {
      var files = this.state.media;

      this.props.setLoading(true);

      files.push({ 
        file: this.state.selectedFile, 
        caption: this.state.fileCaption, 
        previewURL: reader.result,
        category: category
      });

      this.setState({ media: files });

      this.props.setLoading(false);

      this.closeDialog();
    }
 
    reader.readAsDataURL(this.state.selectedFile);
  }

  removeMedia = (event, index) => {
    event.preventDefault();
    const media = this.state.media;
    media.splice(index, 1)
    this.setState({ media: media });
  }

  validateRequired = (name) => {
    let valid = false;
    const value = this.state[name];
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (value == null || value === "") {
      inputStates[name] = "error";
      inputErrors[name] = "This field is required";
    } else {
      valid = true;
      inputStates[name] = "success";
      inputErrors[name] = "";
    }
    
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    return valid;
  }
  
  handleSubmit = async (event) => {
    event.preventDefault();
    let valid = true;
    
    valid = this.validateRequired("categoryType") && valid;
    valid = this.validateRequired("priority") && valid;
    valid = this.validateRequired("addedAt") && valid;
    valid = this.validateRequired("locationType") && valid;
    valid = this.validateRequired("assetType") && valid;
    valid = this.validateRequired("assetName") && valid;
    valid = this.validateRequired("subject") && valid;
    valid = this.validateRequired("description") && valid;

    if(!valid)
      return false;

    this.props.setLoading(true);

    Amplify.configure(strataApiConfig);

    const userId = Cache.getItem("email");
    const roleType = Cache.getItem("roleType");

    let txnId = null;
    
    try {
      const respTxn = await this.createTxn();

      txnId = respTxn.data.createTxn.id;      

      await Utils.createActor(userId, roleType, "ticket", txnId);

      await Utils.createMachine(
        txnId, 
        "strata", 
        txnId, 
        "strata", 
        "fixxting", 
        "started",
        userId,
        roleType);

      this.setState({ step: 0, txnRefId: respTxn.data.createTxn.refId });
    } catch (e) {
      console.log(e);
      alert(JSON.stringify(e));
    }

    if(txnId == null)
      return false;

    let primaryImageId = null;
      
    // Save attachments
    try {
      for(var i = 0; i < this.state.media.length; i++) {
        const mediaItem = this.state.media[i];
        const formattedName = mediaItem.file.name
          .replace("-", " ")
          .replace("_", " ")
          .replace(/[^a-zA-Z0-9\. ]/g, "")
          .replace(/[ ]+/g, "-");
        const fileName = (Helpers.generateGuid() + "_" + formattedName).toLowerCase(); // must not have spaces in file names 

        const prefix = "plan/" + this.state.planId;
        var result = await FileS3.upload(mediaItem.file, prefix, fileName); 

        const annotation = mediaItem.caption;
        const attachmentSubType = "";

        const respAttachment = await Utils.createAttachmentMedia(
          txnId, 
          "strata",
          result.privateFileKey, 
          result.privateFileURL,
          mediaItem.caption,
          mediaItem.file.type.toLowerCase(),
          mediaItem.file.size / (1024 * 1024),
          attachmentSubType,
          annotation);  

        const attachmentId = respAttachment.data.createAttachment.id;

        await Utils.createMachine(
          attachmentId, 
          "attach", 
          txnId, 
          "ticket"  , 
          "thinking", 
          "ticketed",
          config.ADMIN_USER_ID,
          "admin");

        // Set primary image
        if(Helpers.getFileCategory(fileName) === "image" 
          && primaryImageId == null){
            primaryImageId = attachmentId;
        }
      }
    } catch (e) {
      console.log(e);
      alert(JSON.stringify(e));
    }

    // Set primary image
    if(primaryImageId != null){
      await Utils.updateTxnMedium(txnId, primaryImageId);
    }

    this.props.setLoading(false);
  }

  createTxn = () => {
    Amplify.configure(strataApiConfig);

    const params = {
      txnType: "ticket",
      planId: this.state.planId,
      addedAt: new Date(this.state.addedAt).getTime(),
      ticket_priorityRank: this.state.priority,
      ticket_categoryType: this.state.categoryType,
      ticket_locationType: this.state.locationType,
      ticket_assetType: this.state.assetType,
      ticket_assetName: this.state.assetName,
      ticket_subject: this.state.subject,
      ticket_description: this.state.description,
      createdBy: Cache.getItem("email")
    };

    const subCategoryType = this.state.subCategoryType ?? "";
    const subLocationType = this.state.subLocationType ?? "";
    const subAssetType = this.state.subAssetType ?? "";
    const supplierType = this.state.assetTypeOptions.find(x => x.value2 === this.state.assetType)?.value3 ?? "";

    const privateLocations = ["multiple lots", "single lots"];
    const subplanIds = this.state.subPlanId != null && privateLocations.includes(this.state.locationType.toLowerCase())
      ? [this.state.subPlanId] 
      : [];

    if(subCategoryType !== ""){
      params.ticket_subCategoryType = subCategoryType;
    }

    if(subLocationType !== ""){
      params.ticket_subLocationType = subLocationType;
    }

    if(subAssetType !== ""){
      params.ticket_subAssetType = subAssetType;
    }

    if(subplanIds.length > 0){
      params.subplanIds = subplanIds;
    }

    if(supplierType !== ""){
      params.ticket_supplierType = supplierType;
    }

    console.log(params);

    const cmd = `
      mutation(
        $txnType: String!
        $planId: String!
        $addedAt: Float
        $ticket_priorityRank: Int
        $ticket_categoryType: String
        $ticket_locationType: String
        $ticket_assetType: String
        $ticket_assetName: String
        $ticket_subject: String
        $ticket_description: String
        $createdBy: String
        ${subplanIds.length > 0 ? "$subplanIds: [String]" : ""}
        ${subCategoryType !== "" ? "$ticket_subCategoryType: String" : ""}
        ${subLocationType !== "" ? "$ticket_subLocationType: String" : ""}
        ${subAssetType !== "" ? "$ticket_subAssetType: String" : ""}
        ${supplierType !== "" ? "$ticket_supplierType: String" : ""}
      ){
        createTxn(input: {
          txnType: $txnType 
          planId: $planId
          addedAt: $addedAt
          ticket_priorityRank: $ticket_priorityRank
          ticket_categoryType: $ticket_categoryType
          ticket_locationType: $ticket_locationType
          ticket_assetType: $ticket_assetType
          ticket_assetName: $ticket_assetName
          ticket_subject: $ticket_subject
          ticket_description: $ticket_description
          createdBy: $createdBy
          ${subplanIds.length > 0 ? "subplanIds: $subplanIds" : ""}
          ${subCategoryType !== "" ? "ticket_subCategoryType: $ticket_subCategoryType" : ""}
          ${subLocationType !== "" ? "ticket_subLocationType: $ticket_subLocationType" : ""}
          ${subAssetType !== "" ? "ticket_subAssetType: $ticket_subAssetType" : ""}
          ${supplierType !== "" ? "ticket_supplierType: $ticket_supplierType" : ""}
        }){ 
            id
            refId
        }
      }`; 

    //console.log(cmd);
    
    return API.graphql(graphqlOperation(cmd, params));
  }
    
  setStep = step => {
    this.setState({ step: step });
  }
    
  prevStep = () => {
    let step = this.state.step
    step = step <= 1 ? 1: step - 1;
    this.setState({ step: step });
  }

  nextStep = () => {
    let step = this.state.step;
    let valid = true;

    if(step === 1){
      valid = this.validateRequired("categoryType") && valid;
      valid = this.validateRequired("priority") && valid;
      valid = this.validateRequired("addedAt") && valid;
    }
    else if(step === 2){
      valid = this.validateRequired("locationType") && valid;
      valid = this.validateRequired("assetType") && valid;
      valid = this.validateRequired("assetName") && valid;
    }

    if(!valid)
      return false;

    step = step >= 2 ? 3: step + 1;
    this.setState({ step: step });
  }

  prevButton = () => {
    let step = this.state.step;
    if(step > 1){
      return (
        <button 
          className="btn btn-secondary pull-left" 
          type="button" 
          onClick={this.prevStep}>
        Previous
        </button>
      );
    }
    return null;
  }
  
  nextButton = () => {
    let step = this.state.step;
    if(step < 3){
      return (
        <button 
          className="btn btn-success pull-right" 
          type="button" 
          onClick={this.nextStep}>
        Next
        </button>        
      );
    }
    return (
      <button 
        className="btn btn-success pull-right" 
        type="button"
        onClick={this.handleSubmit}>
      Report Issue
      </button>        
    );
  }

  renderStep = () => {
    const step = this.state.step;

    if (step === 1) {
      return <CreateStep1 
        {...this.state}
        {...this.props} />;
    }
    else if (step === 2) {
      return <CreateStep2 
        {...this.state}
        {...this.props} />;
    }
    else if (step === 3) {
      return <CreateStep3 
        {...this.state}
        {...this.props} />;
    }
    
    return null;
  }

  render() {
    if (this.state.planId == null) {
      return null;
    }

    if (this.state.planId === 0) {
      return (
        <div className="pdb">Invalid plan</div>
      );
    }

    if (this.state.txnRefId !== "") {
      return (
        <React.Fragment>
          <div className="create-issue-container">
            <div className="card wic tpd">
              <div className="icon">
                <img src="/images/icons/assignment-24px.svg" alt="" />
              </div>
              <div className="card-title">
                Report an Issue
              </div>
              <div className="card-body">
                <div className="pdb-lg">
                  <label>Property</label>
                  <div className="pdt text-bold">
                    {this.state.planName}
                    {
                      (this.state.subPlanName ?? "") !== "" &&
                      <React.Fragment>
                        <span className="mgr mgl">&raquo;</span>
                        {this.state.subPlanName}
                      </React.Fragment>
                    }
                  </div>
                </div>

                <CreateStepDone {...this.state} {...this.props} />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    const step = this.state.step;

    if(step == null)
      return (<Redirect to="/issues/create" />);

    return (
      <React.Fragment>
        <div className="create-issue-container">
          <div className="card wic tpd">
            <div className="icon">
              <img src="/images/icons/assignment-24px.svg" alt="" />
            </div>
            <div className="card-title">
              Report an Issue
            </div>
            <div className="card-body">
              <form onSubmit={this.handleSubmit}>
                
                <div className="pdb-20 clearfix">
                  <Steps activeStep={step} setStep={this.state.setStep} />
                </div>

                <div className="text-right pdb-lg">* Denotes mandatory field</div>

                <div className="pdb-lg">
                  <label>Property</label>
                  <div className="pdt text-bold">
                    {this.state.planName}
                    {
                      (this.state.subPlanName ?? "") !== "" &&
                      <React.Fragment>
                        <span className="mgr mgl">&raquo;</span>
                        {this.state.subPlanName}
                      </React.Fragment>
                    }
                  </div>
                </div>

                { this.renderStep() }

                <div className="pdt-lg clearfix">
                  {this.prevButton()}
                  {this.nextButton()}
                </div>

              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Create;