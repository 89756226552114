import React from "react";

export default (props) => {
    document.body.classList.add("skyline", "green");

    return (
        <React.Fragment>
            {props.children}
            <div id="loading" className={props.isLoading ? "active" : ""}></div>
        </React.Fragment>
    );
}