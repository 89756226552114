import React from "react";

class IssueCreateSteps extends React.Component {

  renderStepNum = (enable, step, setStep) => {
    return enable
      ? <a href="#" className="num visited" onClick={(e) => { e.preventDefault(); setStep(step); }}>{step}</a>
      : <a href="#" className="num" onClick={(e) => { e.preventDefault(); }}>{step}</a>
  }

  render() {
    const { activeStep, setStep } = this.props;

    return(
      <ul className="steps">
        <li className={`step ${activeStep == 1 ? "active" : ""}`}>
          { this.renderStepNum(true, 1, setStep) }
        </li>
        <li className="step">
          <span className="line"></span>
        </li>
        <li className={`step ${activeStep == 2 ? "active" : ""}`}>
          { this.renderStepNum(activeStep > 1, 2, setStep) }
        </li>
        <li className="step">
          <span className="line"></span>
        </li>
        <li className={`step ${activeStep == 3 ? "active" : ""}`}>
          { this.renderStepNum(activeStep > 2, 3, setStep) }
        </li>
      </ul>
    );
  }
}

export default IssueCreateSteps;