import React, { useState } from 'react';
import { Cache } from "aws-amplify";

export default (props) => {
  const name = Cache.getItem("name");
  const url = window.location.pathname.toLowerCase();

  const [openItem1, setOpenItem1]= useState(url.startsWith("/issues"));
  const [openItem2, setOpenItem2]= useState(url.startsWith("/plans"));

  return(
    <ul className="menu">
      <li>
        <a href="/profile">
          <span className="icon"><img src="/images/icons/person-24px.svg" alt="" /></span>
          <span className="desc">{name}</span>
        </a>
      </li>

      <li>
        <a href="/dashboard" className={url === "/dashboard" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/dashboard-24px.svg" alt="" /></span>
          <span className="desc">Dashboard</span>
        </a>
      </li>

      <li>
        <a href="#" className="arrow" onClick={(e) => {
          e.preventDefault(); 
          setOpenItem1(!openItem1);
        }}>
          <i className={`fa ${openItem1 ? "fa-caret-up" : "fa-caret-down"}`} />
        </a>
        <a href="/issues"
          className={url.startsWith("/issues") ? "active" : ""}>
          <span className="icon"><img src="/images/icons/assignment-24px.svg" alt="" /></span>
          <span className="desc">Issues</span>
        </a>
        <ul className={openItem1 ? "open" : ""}>
          <li>
            <a href="/issues/create"
              className={url.startsWith("/issues/create") ? "active" : ""}>
              <span className="short">CI</span>
              <span className="desc">Report an Issue</span>
            </a>
          </li>
          <li>
            <a href="/issues/pending"
              className={url.startsWith("/issues/pending") ? "active" : ""}>
              <span className="short">PI</span>
              <span className="desc">Pending Issues</span>
            </a>
          </li>
          <li>
            <a href="/issues/resolved"
              className={url.startsWith("/issues/resolved") ? "active" : ""}>
              <span className="short">RI</span>
              <span className="desc">Resolved Issues</span>
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a href="#" className="arrow" onClick={(e) => {
          e.preventDefault(); 
          setOpenItem2(!openItem2);
        }}>
          <i className={`fa ${openItem2 ? "fa-caret-up" : "fa-caret-down"}`} />
        </a>
        <a href="/plans"
          className={url.startsWith("/plans") ? "active" : ""}>
          <span className="icon"><img src="/images/icons/business-24px.svg" alt="" /></span>
          <span className="desc">Properties</span>
        </a>
        <ul className={openItem2 ? "open" : ""}>
          <li>
            <a href="/plans/import"
              className={url.startsWith("/plans/import") ? "active" : ""}>
              <span className="short">IP</span>
              <span className="desc">Import Properties</span>
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a href="/clients" className={url === "/clients" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/person-24px.svg" alt="" /></span>
          <span className="desc">Clients</span>
        </a>
      </li>

      <li>
        <a href="/suppliers" className={url === "/suppliers" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/tradesman-24px.svg" alt="" /></span>
          <span className="desc">Tradesmen</span>
        </a>
      </li>

      <li>
        <a href="/reports" className={url === "/reports" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/insert_chart-24px.svg" alt="" /></span>
          <span className="desc">Reports</span>
        </a>
      </li>

      <li>
        <a href="/concierge" className={url === "/concierge" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/concierge-24px.svg" alt="" /></span>
          <span className="desc">Concierge</span>
        </a>
      </li>

      <li>
        <a href="/settings" className={url === "/settings" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/settings_applications-24px.svg" alt="" /></span>
          <span className="desc">Settings</span>
        </a>
      </li>

      <li>
        <a href="/help" className={url === "/help" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/help_outline-24px.svg" alt="" /></span>
          <span className="desc">Help Centre</span>
        </a>
      </li>

      <li>
        <a href="/logout">
          <span className="icon"><img src="/images/icons/lock-24px.svg" alt="" /></span>
          <span className="desc">Logout</span>
        </a>
      </li>
    </ul>
  );
};