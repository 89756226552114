import React from "react";
import { Cache } from "aws-amplify";

class DashboardTenant extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const planId = Cache.getItem("planId");

    return (
      <React.Fragment>
        <div className="page-title">
          <h1>Dashboard</h1>
        </div>

        <div className="row mgt">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href={planId == null ? "/account/plans" : "/plans/detail/" + planId}>
              <div className="icon">
                <img src="/images/icons/business-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">My Place</div>
              </div>
            </a>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/issues/list/pending">
              <div className="icon">
                <img src="/images/icons/assignment-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Issues</div>
              </div>
            </a>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/announcements">
              <div className="icon">
                <img src="/images/icons/stars-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Announcements</div>
              </div>
            </a>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/documents">
              <div className="icon">
                <img src="/images/icons/featured_play_list-24px.svg" alt="" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Documents</div>
              </div>
            </a>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/concierge">
              <div className="icon">
                <img src="/images/icons/concierge-24px.svg" alt="" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Concierge</div>
              </div>
            </a>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/facilities">
              <div className="icon">
                <img src="/images/icons/grade-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Facilities</div>
              </div>
            </a>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/settings">
              <div className="icon">
                <img src="/images/icons/settings_applications-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Settings</div>
              </div>
            </a>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="help">
              <div className="icon">
                <img src="/images/icons/help_outline-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Help Centre</div>
              </div>
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DashboardTenant;
