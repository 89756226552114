import React from "react";
import { Cache } from "aws-amplify";
import * as Enums from "libs/Enums";

export default ({ props }) => {
  return (
    <div className="status-panel">
      <div className="container">
        { renderSwitchPersona() }
        { renderSwitchPlan() }
      </div>
    </div>
  );
};

const renderSwitchPersona = () => {
  if(parseInt(Cache.getItem("personas")) <= 1) {
    return (
      <span>
        <img src="/images/icons/person-24px.svg" className="mico mgr-sm" alt="" />
        {Enums.getRoleLabel(Cache.getItem("roleType"))}
      </span>
    );
  }
    
  return (
    <span>
      <a href="/account/personas">
        <img src="/images/icons/compare_arrows-24px.svg" className="mico mgr-sm" alt="" />
        {Enums.getRoleLabel(Cache.getItem("roleType"))}
      </a>
    </span>
  );
}

const renderSwitchPlan = () => {
  var planName = Cache.getItem("planName");

  if(parseInt(Cache.getItem("plans")) <= 1){
    return (
      <span>
        <img src="/images/icons/business-24px.svg" className="mico mgr-sm" alt="" />
        { planName == null ? "---" : planName }
      </span>
    );
  }
    
  return (
    <span>
      <a href="/account/plans">
        <img src="/images/icons/compare_arrows-24px.svg" className="mico mgr-sm" alt="" />
        {planName}
      </a>
    </span>
  );
}