import Amplify, { API, graphqlOperation } from "aws-amplify";
import * as Helpers from "libs/Helpers";
import utilsApiConfig from "config-api-UTILS";

export const getListData = async (listName) => {
  Amplify.configure(utilsApiConfig);

  const params = {
    listName: listName
  };

  const cmd = `
    query(
      $listName: String
    ) {  
      lists: listLists(  
          filter: {
              listName: { eq: $listName }
          },
          limit: 1000
      ) {
          items {
              id
              idx1
              value1
              idx2
              value2 
              idx3
              value3        
          }
        }
    }`;

  const response = await API.graphql(graphqlOperation(cmd, params));

  let items = response.data.lists.items;

  items.sort((a,b) => (a.idx1 > b.idx1) ? 1 : -1); 

  return items;
}

export const getConfigs = async () => {
  return await getListData("configs");
}

export const getIssueCategories = async () => {
  const data = await getListData("issueCategories");
  const keys = [];
  const categories = [];

  data.map(x => {
    if (keys.indexOf(x.idx1) === -1) {
      keys.push(x.idx1);
      categories.push({
        id: x.id, 
        idx1: x.idx1, 
        value1: x.value1, 
        idx2: "", 
        value2: "", 
        idx3: "", 
        value3: ""
      });
    }
  });

  return categories.sort((a,b) => (a.idx1 > b.idx1) ? 1 : -1);
}

export const getIssueSubCategories = async (categoryId) => {
  const categories = await getListData("issueCategories");
  return categories.filter(x => x.idx1 == categoryId);
}

export const getIssueImpacts = async () => {
  return await getListData("issueImpact");
}

export const getDataLists = async () => {
  return await getListData("lists");
}

export const getLocationTypes = async () => {
  return await getListData("locationTypes");
}

export const getRoleTypes = async () => {
  return await getListData("roleTypes");
}

export const getRoleSubTypes = async () => {
  return await getListData("roleSubTypes");
}

export const getStates = async () => {
  return await getListData("states");
}

export const getWorkflows = async () => {
  return await getListData("workflows");
}

export const getAssetTypes = async () => {
  return await getListData("assetTypes");
}

export const getAttachmentSubTypes = async () => {
  return await getListData("attachmentSubTypes");
}

export const getRoleLabel = (value) => { 
  const roleTypes = [
    {value:"admin", text:"Admin"},
    {value:"stratamanager", text:"Strata Manager"},
    {value:"propertymanager", text:"Property Manager"},
    {value:"owner", text:"Owner"},
    {value:"owneroccupier", text:"Owner Occupier"},
    {value:"tenant", text:"Tenant"},
    {value:"committee", text:"Committee"},
    {value:"supplier", text:"Supplier"},
    {value:"concierge", text:"Concierge"},
    {value:"guest", text:"Guest"}
  ];

  return getLabel(roleTypes, value);
}

export const getSubRoleLabel = (value) => { 
  const roleTypes = [
    {value:"xx", text:"xx"}
  ];

  return getLabel(roleTypes, value) ?? Helpers.capitalize(value);
}

export const getIssueStates = () => { 
  return [
    {code:"start", statusLabel:"Waiting to Start", transitionLabel:"", cssClass:"info"},
    {code:"started", statusLabel:"Issue Reported", transitionLabel:"Report Issue", cssClass:"info"},
    {code:"cancelled", statusLabel:"Cancelled", transitionLabel:"Cancel", cssClass:"grey"},
    {code:"stop", statusLabel:"Stop", transitionLabel:"Stop", cssClass:"danger"},
    {code:"end", statusLabel:"End", transitionLabel:"Complete", cssClass:"success"},
    {code:"finish", statusLabel:"Finish", transitionLabel:"Finish", cssClass:"success"},
    {code:"createactors", statusLabel:"Actors Created", transitionLabel:"", cssClass:"info"},
    {code:"approve", statusLabel:"Waiting for Approval", transitionLabel:"", cssClass:"info"},
    {code:"approved", statusLabel:"Approved", transitionLabel:"Approve", cssClass:"success"},
    {code:"declined", statusLabel:"Declined", transitionLabel:"Decline", cssClass:"danger"},
    {code:"rfq", statusLabel:"Waiting for RFQ", transitionLabel:"", cssClass:"info"},
    {code:"rfqed", statusLabel:"Quote Requested", transitionLabel:"Request Quotes", cssClass:"success"},
    {code:"quote", statusLabel:"Waiting for Quote", transitionLabel:"", cssClass:"info"},
    {code:"quoted", statusLabel:"Quote Received", transitionLabel:"Receive Quotes", cssClass:"success"},
    {code:"quotecount", statusLabel:"Count Quotes", transitionLabel:"", cssClass:"info"},
    {code:"quotescompleted", statusLabel:"Quotes Completed", transitionLabel:"", cssClass:"info"},
    {code:"selectquote", statusLabel:"Waiting for Quote Selection", transitionLabel:"", cssClass:"info"},
    {code:"quoteselected", statusLabel:"Quote Selected", transitionLabel:"Select Quote", cssClass:"success"},
    {code:"schedule", statusLabel:"Waiting for Schedule", transitionLabel:"", cssClass:"info"},
    {code:"scheduled", statusLabel:"Work Scheduled", transitionLabel:"Schedule Work", cssClass:"success"},
    {code:"work", statusLabel:"Waiting for Work", transitionLabel:"", cssClass:"info"},
    {code:"worked", statusLabel:"Work Completed", transitionLabel:"Work Complete", cssClass:"success"},
    {code:"invoice", statusLabel:"Waiting for Invoice", transitionLabel:"", cssClass:"info"},
    {code:"invoiced", statusLabel:"Invoice Sent", transitionLabel:"Send Invoice", cssClass:"success"}
  ];
}

export const getIssueStateStatusLabel = (code) => { 
  var states = getIssueStates();
  const matches = states.filter(x => x.code == code.toLowerCase());
  return matches.length > 0 ? matches[0].statusLabel : null;
}

export const getIssueStateTransitionLabel = (code) => { 
  var states = getIssueStates();
  const matches = states.filter(x => x.code == code);
  return matches.length > 0 ? matches[0].transitionLabel : null;
}

export const getIssueStateCssClass = (code) => { 
  var states = getIssueStates();
  const matches = states.filter(x => x.code == code);
  return matches.length > 0 ? matches[0].cssClass : null;
}

export const getPriorities = () => {  
  return [
    {value:"1", text:"Low"},
    {value:"2", text:"Medium"},
    {value:"3", text:"High"},
    {value:"4", text:"Urgent"}
  ];
}

export const getPriorityLabel = (value) => { 
  return getLabel(getPriorities(), value);
}

export const getLabel = (items, value) => { 
  const matches = items.filter(x => x.value == value);
  return matches.length > 0 ? matches[0].text : null;
}