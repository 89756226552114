import React from "react";

export default ({ props }) => {
  return(
    <div className="header">
      <div className="container">
        <a href="/" className="logo"><img src="/images/fixxt_logo.png" /></a>
        <a href="/logout" className="logout-btn">Logout</a>
      </div>
    </div>
  );
};