export const OnCreateMachine = `
subscription onCreateMachine {
    onCreateMachine {
        id
        foreignType
        parentForeignId
        parentForeignType
    }
}`;

export const OnUpdateMachine = `
subscription onUpdateMachine {
    onUpdateMachine {
        id
        foreignType
        parentForeignId
        parentForeignType
        stateType
    }
}`;

export const OnDeleteMachine = `
subscription onDeleteMachine {
    onDeleteMachine {
        id
        foreignType
        parentForeignId
        parentForeignType
    }
}`;

