import React from "react";
import { Route } from "react-router-dom";

export default ({ props: baseProps, component: Component, layout: Layout,  ...rest }) => {
  return (
    <Route
      {...rest}
      render={ props =>
        Layout != null
          ? <Layout {...baseProps}><Component {...baseProps} {...props} /></Layout>
          : <Component {...baseProps} {...props} />
      }
    />
  );
};
