// API
const config = {
    API: {
      graphql_endpoint: process.env.REACT_APP_UTILS_URL,
      graphql_headers: async () => ({
        'x-api-key': process.env.REACT_APP_UTILS_KEY,
        'tenantId': process.env.REACT_APP_TENANTID
      })
    },
    "aws_appsync_graphqlEndpoint": process.env.REACT_APP_UTILS_URL,
    "aws_appsync_region": process.env.REACT_APP_REGION,
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": process.env.REACT_APP_UTILS_KEY
};
export default config;
