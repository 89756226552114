import React from "react";
import Amplify, { API, Cache, graphqlOperation } from "aws-amplify";
import * as Utils from "libs/Utils";
import strataApiConfig from "config-api-STRATA";
import MUIDataTable from "mui-datatables";
import UnauthPanel from "views/shared/UnauthPanel";

class PropertyIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorized: true,
      plans: null
    };
  }

  async componentDidMount() {
    const rolesHaveAccess = ["admin", "stratamanager", "propertymanager", "supplier", "owner"];
    const roleType = Cache.getItem("roleType");

    if(!rolesHaveAccess.includes(roleType)){
      this.setState({ authorized: false });
      return;
    }

    await this.loadPlans();
  }

  async loadPlans() {
    const plans = await this.getPlans();
    this.setState({ plans });
  }

  async getPlans() {
    let plans = [];
    
    const userId = Cache.getItem("email");
    const roleType = Cache.getItem("roleType");
    const restrictions = roleType !== "admin";

    if(!restrictions) {
      plans = await this.queryPlans();
    }
    else {
      const planIds = await Utils.getAccessPlanIds(userId, roleType);

      if(planIds.length > 0)
        plans = await this.queryPlansByIds(planIds);
    }

    if(plans.length === 0)
      return plans;

    plans = plans.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : ((b.createdAt > a.createdAt) ? 1 : 0)); 

    console.log(plans);

    const accessIssueIds = restrictions
      ? await Utils.getAccessIssueIds(userId, roleType)
      : null;

    //console.log(accessIssueIds);

    const completeStates = ["end", "finish"];

    return plans.map((data) => {
      return {
        id: data.id.trim().split("\n")[0],
        name: data.planName,
        address: ((data.address_name ?? "") + " " + (data.address_number ?? "") + " " + (data.address_street ?? "") + " " + (data.address_suburb ?? "") + " " + (data.address_postcode ?? "") + " " + (data.address_state ?? "")).trim(),
        lots: data.subplans.length,
        pendingIssues: data.txns
          .filter(x => x.txnType === "ticket"
            && (!restrictions || accessIssueIds.includes(x.id))
            && x.machines[0] != null 
            && x.machines[0].machineState !== null
            && !completeStates.includes(x.machines[0].machineState.stateType)).length,
        resolvedIssues: data.txns
          .filter(x => x.txnType === "ticket" 
            && (!restrictions || accessIssueIds.includes(x.id))
            && x.machines[0] != null 
            && x.machines[0].machineState !== null
            && completeStates.includes(x.machines[0].machineState.stateType)).length
      };
    });
  }

  async queryPlans() {
    Amplify.configure(strataApiConfig);

    let plans = [];
    
    try {
      const params = { planType: "strata" };

      const cmd = `
        query(
          $planType: String!
        ) {
          plans: listPlans(
            filter: {
              planType: { eq: $planType }
            },
            limit: 10000
          ){
            items {
              id
              planType
              description
              planName
              createdAt
              address_name
              address_number
              address_street
              address_suburb
              address_postcode
              address_state
              address_country
              lot_parkingSpot
              lot_keyTag
              lot_keyTagCount
              lot_level
              lot_unit
              subplans {
                id
              }
              txns {
                id
                txnType
                machines {
                  machineState {
                    stateType
                  }
                }
              }
            }
          }
        }`;

      const result = await API.graphql(graphqlOperation(cmd, params));

      console.log(result);

      if (result.data != null && result.data.plans.items.length > 0) { 
        plans = result.data.plans.items; 
      }
    } catch (e) {
      console.log(e);
      //alert(JSON.stringify(e));
      if (e.hasOwnProperty("data") && e.data != null && e.data.plans != null) {
        plans = e.data.plans.items;
      }
    }
    
    return plans;
  }

  async queryPlansByIds(planIds) {
    Amplify.configure(strataApiConfig);

    let plans = [];
    
    try {
      const params = { ids: planIds };

      console.log(params);

      const cmd = `
        query(
          $ids: [String]
        ) {
          plans: getPlansByIds(
            ids: $ids,
            limit: 10000
          ){
            id
            planType
            description
            planName
            createdAt
            address_name
            address_number
            address_street
            address_suburb
            address_postcode
            address_state
            address_country
            lot_parkingSpot
            lot_keyTag
            lot_keyTagCount
            lot_level
            lot_unit
            subplans {
              id
            }
            txns {
              id
              txnType
              machines {
                machineState {
                  stateType
                }
              }
            }
          }
        }`;

      const result = await API.graphql(graphqlOperation(cmd, params));

      console.log(result);

      if (result.data != null && result.data.plans.length > 0) { 
        plans = result.data.plans; 
      }
    } catch (e) {
      console.log(e);
      //alert(JSON.stringify(e));
      if (e.hasOwnProperty("data") && e.data != null && e.data.plans != null) {
        plans = e.data.plans;
      }
    }

    plans = plans.filter(x => x.planType === "strata"); 
    
    return plans;
  }

  render() {
    if(this.state.authorized === false){
      return (<UnauthPanel />);
    }

    const roleType = Cache.getItem("roleType");

    const columns = [
      {
       name: "id",
       label: "---",
       options: {
        display: 'excluded',
        filter: false,
        sort: false
       }
      },
      {
       name: "name",
       label: "Property",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Property: ${v}` },
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <a href={`/plans/detail/${tableMeta.rowData[0]}`}>{value}</a>
            );
          }
        }
      },
      {
       name: "address",
       label: "Address",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Address: ${v}` },
        filterType: "textField"
       }
      },
      {
        name: "resolvedIssues",
        label: "Resolved Issues",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <a href={`/issues/list/resolved/${tableMeta.rowData[0]}`}>{value}</a>
            );
          }
        }
      },
      {
        name: "pendingIssues",
        label: "Pending Issues",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <a href={`/issues/list/pending/${tableMeta.rowData[0]}`}>{value}</a>
            );
          }
        }
      },
      {
       name: "lots",
       label: "Lots/Units",
       options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <a href={`/plans/lots/${tableMeta.rowData[0]}`}>{value}</a>
            );
          }
        }
      }
     ];

    const options = {
      filterType: "dropdown",
      selectableRows: false,
      responsive: "stacked",
      elevation: 0,
      rowHover: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10,50,100],
      searchOpen: false,
      print: false,
      download: false,
      viewColumns: false,
      hint: ""
    };

    return (
      <React.Fragment>
        <div className="page-title">
          {
            roleType === "admin" && 
            <React.Fragment>
              <a href="/plans/import" className="btn btn-success solid pull-right hidden-xs">Import Properties</a>
              <a href="/plans/import" className="btn btn-success solid pull-right visible-xs btn-issue-report">
                <img src="/images/icons/error_outline-24px.svg" alt=""/>
              </a>
            </React.Fragment>
          }
          <h1>Properties</h1>
        </div>
        <div className="card">
          <div className="card-body nopdt">
            {
              this.state.plans == null 
              ? <div className="pdt-lg pdb-sm text-center"><img src="/images/loading.svg" /></div>
              : <MUIDataTable
                  data={this.state.plans}
                  columns={columns}
                  options={options}
                />
            }
          </div>
        </div>
      </React.Fragment>      
    );
  }
}

export default PropertyIndex;
