import React from "react";
import { Cache } from "aws-amplify";
import * as Helpers from "libs/Helpers";

class CreateDone extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const isClient = Helpers.isClientRole(Cache.getItem("roleType"));

    return (
      <React.Fragment>

        <div className="pdt pdb-lg">
          Your Issue has been reported!
        </div>

        <div className="pdb-lg text-center">
          <div className="btn btn-light solid w100">
            {this.props.txnRefId}
          </div>
        </div>

        <div className="pdt-lg mgt-lg clearfix">
          {
            isClient 
            ? <a href="/issues/list/pending" className="btn btn-success pull-left">View Issues</a>
            : <a href="/issues" className="btn btn-success pull-left">View Issues</a>
          }
          <a href="/issues/create" className="btn btn-success pull-right">Report an Issue</a>
        </div>

      </React.Fragment>
    );
  }
}

export default CreateDone;