import React from "react";
import Amplify, { API, Cache, graphqlOperation } from "aws-amplify";
import config from "config";
import strataApiConfig from "config-api-STRATA";
import * as Enums from "libs/Enums";
import { InputLabel, MenuItem, FormControl, Select } from "@material-ui/core";
import LoaderButton from "views/shared/LoaderButton";
import Footer from "views/layouts/parts/Footer";


class Personas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roleType: Cache.getItem("roleType"),
      disableSubmit: false,
      personas: null,
      inputStates: {
        roleType: ""
      },
      inputErrors: {
        roleType: ""
      }
    };
  }

  async componentDidMount() { 
    try {
      // Do no use props as componentDidMount() of parent (App.js) executes after the children
      const userId = Cache.getItem("email");

      const result = await this.listPersonas(userId);
      const personas = result.data.personas.items;
  
      //console.log(personas);

      this.setState({ personas: personas });
    } catch (e) {
      alert("ERROR: " + JSON.stringify(e));
    }
  }

  validateRoleType = () => {
    const name = "roleType";
    let valid = false;
    const value = this.state[name];
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (value == null || value === "") {
      inputStates[name] = "error";
      inputErrors[name] = "This field is required";
    } else {
      valid = true;
      inputStates[name] = "success";
      inputErrors[name] = "";
    }
    
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    return valid;
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });

    if (name == 'roleType') {
      this.validateRoleType();
    }
  }

  handleSubmit = async event => {
    event.preventDefault();

    if (this.state.disableSubmit) {
      return false;
    }

    let valid = this.validateRoleType();

    //console.log(valid);

    if (!valid) {
      //alert('Please fix the errors');
      return false;
    }

    //this.props.setLoading(true);
    this.setState({ disableSubmit: true });

    try {
      const roleType = this.state.roleType;
      const selectedPersona = this.state.personas.filter(function(x) { return x.roleType === roleType })[0];
      const planActors = selectedPersona.actors.items.filter(function(x) { return x.foreignType === "strata" });

      const now = new Date();
      const expiry = now.setMinutes(now.getMinutes() + config.AUTH_SESSION_DURATION_MINUTES);

      await Cache.setItem("roleType", roleType, { expires: expiry });
      await Cache.setItem("plans", planActors.length, { expires: expiry });

      if(planActors.length > 0) {
        await Cache.setItem("planId", planActors[0].foreignId, { expires: expiry });
        await Cache.setItem("planName", planActors[0].plan.planName, { expires: expiry });
      }
      else {
        await Cache.removeItem("planId");
        await Cache.removeItem("planName");
      }

      this.props.redirect("/dashboard");
    } catch (e) {
      this.setState({ disableSubmit: false });
      alert("ERROR: " + JSON.stringify(e));
    }
  }

  listPersonas = userId => {
    Amplify.configure(strataApiConfig);

    const params = { userId: userId };

    const cmd = `
      query(
        $userId: String!
      ){
        personas: listPersonas(
          filter: {
            userId: { eq: $userId }
          },
          limit: 10000
        ){
          items{ 
            userId
            roleType
            actors {
                items {
                    id
                    userId
                    roleType
                    foreignId
                    foreignType
                    plan {
                      planName
                    }
                }
            }
          }
        }
      }`;

    return API.graphql(graphqlOperation(cmd, params));
  }

  renderPanel = () => {
    if (this.state.personas == null) {
      return null;
    }

    if (this.state.personas.length == 0) {
      return (
        <React.Fragment>
          <div className="pdt text-center">
            Your account does not have any personas.
          </div>

          <div className="pdt-lg pdb text-center">
            <a href="/logout">Logout</a>
          </div>
        </React.Fragment>);
    }
    
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>

          <div className="pdb-sm">
            <FormControl fullWidth>
              <InputLabel id="role-label">Choose user role</InputLabel>
              <Select
                labelId="role-label"
                value={this.state.roleType ?? ""}
                onChange={(e) => this.handleChange("roleType", e.target.value)} 
              >
                {
                  this.state.roleType == null &&
                  <MenuItem key="-1" value="">
                    <em>...</em>
                  </MenuItem>
                }
                {
                  this.state.personas.map((persona, index) => 
                    <MenuItem key={index} value={persona.roleType}>{Enums.getRoleLabel(persona.roleType)}</MenuItem>)
                }
              </Select>
            </FormControl>
            <span className="text-danger">{this.state.inputErrors["roleType"]}</span>
          </div>

          <div className="pdt-lg text-center">
            <LoaderButton
              className="btn btn-success"
              type="submit"
              text="Select"
              loadingText="Processing..."
              disabled={this.state.disableSubmit}
              isLoading={this.state.disableSubmit}
            />
          </div>

          <div className="pdt-lg pdb text-center">
            <a href="/dashboard">Back to Dashboard</a>
          </div>

        </form>
      </React.Fragment>
    );
  }

  render() {
    // if (this.state.personas.length == 1 
    //   && Cache.getItem("roleType") != null
    //   && Cache.getItem("roleType") !== "") {
    //   return (<Redirect to="/dashboard" />);
    // }

    return (
      <div id="login-screen">
        <div className="login-container">

          <div className="login-header">
            <div className="login-logo">
              <a href="/"><img src="/images/fixxt_logo.png" /></a>
            </div>
            <div>What user role you would like to access?</div>
          </div>

          <div className="card light mgt-lg">
            <div className="card-body">

              <div className="login-form">
                {this.renderPanel()}
              </div>

            </div>
          </div>
          
          <Footer />

        </div>
      </div>
    );
  }
}

export default Personas;