import React from "react";

export default (props) => {
    if(props.isAuthenticated){
        document.body.classList.remove("green");
        document.body.classList.add("skyline");
    }
    else {
        document.body.classList.add("skyline", "green");
    }

    return (
        <React.Fragment>
            {props.children}
            <div id="loading" className={props.isLoading ? "active" : ""}></div>
        </React.Fragment>
    );
}