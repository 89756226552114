import React from "react";
import Header from './parts/Header';
import StatusPanel from './parts/StatusPanel';
import Sidebar from './parts/Sidebar';

export default (props) => {
    if(props.skyline === "true"){
        document.body.classList.add("skyline");
        document.body.classList.remove("green");
    }
    else {
        document.body.classList.remove("skyline", "green");
    }

    return (
        <div className={`wrapper ${props.sidebarCollapsed ? "sidebar-collapsed" : ""} ${props.sidebarFloat ? "sidebar-float" : ""}`}>
            <Sidebar {...props} />
            <Header {...props} />
            <div className="frame">
                <StatusPanel {...props} />
                <div className="main">
                    <div className="container">
                        {props.children}
                    </div>
                </div>
            </div>
            <div id="loading" className={props.isLoading ? "active" : ""}></div>
        </div>
    );
}