import React from "react";

class DashboardSupplier extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-title">
          <h1>Dashboard</h1>
        </div>

        <div className="row mgt">
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/quotes/workorders">
              <div className="icon">
                <img src="/images/icons/playlist_add_check-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">My Jobs</div>
              </div>
            </a>
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/quotes/requests">
              <div className="icon">
                <img src="/images/icons/attach_money-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Quotation Requests</div>
              </div>
            </a>
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/ratings">
              <div className="icon">
                <img src="/images/icons/grade-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">My Ratings</div>
              </div>
            </a>
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/reports">
              <div className="icon">
                <img src="/images/icons/insert_chart-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Reports</div>
              </div>
            </a>
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/settings">
              <div className="icon">
                <img src="/images/icons/settings_applications-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Settings</div>
              </div>
            </a>
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6">
            <a className="card dasboard" href="/help">
              <div className="icon">
                <img src="/images/icons/help_outline-24px.svg" />
              </div>
              <div className="card-body">
                <div className="dash-item-title">Help Centre</div>
              </div>
            </a>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default DashboardSupplier;