const dev = {
    STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
    GMAP_API_KEY: process.env.REACT_APP_GMAP_API_KEY,
    s3: {
      REGION: process.env.REACT_APP_REGION,
      BUCKET: process.env.REACT_APP_PRIVATE_BUCKET,
      IDENTITY_KEY: process.env.REACT_APP_STORAGE_IDENTITY_KEY
    },
    apiGateway: {
      REGION: process.env.REACT_APP_REGION,
      URL: "https://api.serverless-stack.seed-demo.club/dev"
    },
    cognito: {
      REGION: process.env.REACT_APP_REGION,
      USER_POOL_ID: process.env.REACT_APP_USER_POOLID,
      APP_CLIENT_ID: process.env.REACT_APP_CLIENTID,
      IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOLID,
    }
  };
  
const prod = {
    STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
    GMAP_API_KEY: process.env.REACT_APP_GMAP_API_KEY,
    s3: {
      REGION: "us-east-1",
      BUCKET: "notes-app-2-api-prod-attachmentsbucket-1h5n5ttet1hy0",
      IDENTITY_KEY: ""
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://api.serverless-stack.seed-demo.club/prod"
    },
    cognito: {
      REGION: process.env.REACT_APP_REGION,
      USER_POOL_ID: process.env.REACT_APP_USER_POOLID,
      APP_CLIENT_ID: process.env.REACT_APP_CLIENTID,
      IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOLID,
    }
  };
  
  // Default to dev if not set
  const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;
  
  export default {
    // Add common config values here
    ADMIN_USER_ID: "admin@fixxt.com.au",
    MAX_ATTACHMENT_SIZE_FILE: 5000000, // 5MB
    MAX_ATTACHMENT_SIZE_VIDEO: 200000000, // 200MB
    AUTH_SESSION_DURATION_MINUTES: 43200, // 30 Days
    ...config
  };
  