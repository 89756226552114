import React, { useState } from 'react';
import { Cache } from "aws-amplify";

export default (props) => {
  const name = Cache.getItem("name");
  const planId = Cache.getItem("planId");
  const url = window.location.pathname.toLowerCase();

  const [openItem1, setOpenItem1]= useState(url.startsWith("/issues"));

  return(
    <ul className="menu">
      <li>
        <a href="/profile">
          <span className="icon"><img src="/images/icons/person-24px.svg" alt="" /></span>
          <span className="desc">{name}</span>
        </a>
      </li>

      <li>
        <a href="/dashboard" className={url === "/dashboard" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/dashboard-24px.svg" alt="" /></span>
          <span className="desc">Dashboard</span>
        </a>
      </li>

      <li>
        <a href={planId == null ? "/account/plans" : "/plans/detail/" + planId} className={url.startsWith("/plans/detail/") ? "active" : ""}>
          <span className="icon"><img src="/images/icons/business-24px.svg" alt="" /></span>
          <span className="desc">My place</span>
        </a>
      </li>

      <li>
        <a href="#" className="arrow" onClick={(e) => {
          e.preventDefault(); 
          setOpenItem1(!openItem1);
        }}>
          <i className={`fa ${openItem1 ? "fa-caret-up" : "fa-caret-down"}`} />
        </a>
        <a href="/issues/list/pending"
          className={url.startsWith("/issues") ? "active" : ""}>
          <span className="icon"><img src="/images/icons/assignment-24px.svg" alt="" /></span>
          <span className="desc">Issues</span>
        </a>
        <ul className={openItem1 ? "open" : ""}>
          <li>
            <a href="/issues/create" 
              className={url.startsWith("/issues/create") ? "active" : ""}>
              <span className="short">CI</span>
              <span className="desc">Report an Issue</span>
            </a>
          </li>
          <li>
            <a href="/issues/list/pending"
              className={url.startsWith("/issues/list/pending") ? "active" : ""}>
              <span className="short">PI</span>
              <span className="desc">Pending Issues</span>
            </a>
          </li>
          <li>
            <a href="/issues/list/resolved"
              className={url.startsWith("/issues/list/resolved") ? "active" : ""}>
              <span className="short">RI</span>
              <span className="desc">Resolved Issues</span>
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a href="/announcements" className={url === "/announcements" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/stars-24px.svg" alt="" /></span>
          <span className="desc">Announcements</span>
        </a>
      </li>

      <li>
        <a href="/documents" className={url === "/documents" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/featured_play_list-24px.svg" alt="" /></span>
          <span className="desc">Documents</span>
        </a>
      </li>

      <li>
        <a href="/concierge" className={url === "/concierge" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/concierge-24px.svg" alt="" /></span>
          <span className="desc">Concierge</span>
        </a>
      </li>

      <li>
        <a href="/facilities" className={url === "/facilities" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/grade-24px.svg" alt="" /></span>
          <span className="desc">Facilities</span>
        </a>
      </li>

      <li>
        <a href="/settings" className={url === "/settings" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/settings_applications-24px.svg" alt="" /></span>
          <span className="desc">Settings</span>
        </a>
      </li>

      <li>
        <a href="/help" className={url === "/help" ? "active" : ""}>
          <span className="icon"><img src="/images/icons/help_outline-24px.svg" alt="" /></span>
          <span className="desc">Help Centre</span>
        </a>
      </li>

      <li>
        <a href="/logout">
          <span className="icon"><img src="/images/icons/lock-24px.svg" alt="" /></span>
          <span className="desc">Logout</span>
        </a>
      </li>
    </ul>
  );
};