import { Auth, Storage } from "aws-amplify";
import config from "config";

async function upload(file, prefix, name) {
  const filePath = prefix == null ? name : (prefix + "/" + name);

  const stored = await Storage.vault.put(filePath, file, {
    contentType: file.type
  });
  
  const uploadKey = stored.key;
  // const identityId = Auth.userPool.storage[config.s3.IDENTITY_KEY];
  // const privateFileKey = "private/" + identityId + "/" + uploadKey;
  // const privateFileURL = config.s3.BUCKET + ".s3-" + config.s3.REGION + ".amazonaws.com/" + privateFileKey;
  const privateFileKey = uploadKey;
  const privateFileURL = config.s3.BUCKET + ".s3-" + config.s3.REGION + ".amazonaws.com/" + privateFileKey;

  return {
    uploadKey,
    privateFileKey,
    privateFileURL
  };
}

export { 
  upload
}