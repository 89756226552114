import React from "react";
import Amplify, { API, Cache, Storage, graphqlOperation } from "aws-amplify";
import { Map, GoogleApiWrapper } from 'google-maps-react';
import Geocode from "react-geocode";
import * as Enums from "libs/Enums";
import * as Helpers from "libs/Helpers";
import * as Utils from "libs/Utils";
import * as FileS3 from "libs/FileS3";
import config from "config";
import strataApiConfig from "config-api-STRATA";
import utilsApiConfig from "config-api-UTILS";
import { OnUpdateMachine, OnDeleteMachine, OnCreateMachine } from "graphql/Subscriptions";
import { TextField, Button, InputLabel, MenuItem, Select, FormControl, FormHelperText, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import IssueItem from './parts/IssueItem';
import NoteItem from './parts/NoteItem';
import AttachmentItem from './parts/AttachmentItem';
import ManagerItem from './parts/ManagerItem';
import UserPanel from "views/shared/UserPanel";
import UnauthPanel from "views/shared/UnauthPanel";

Amplify.configure(utilsApiConfig);

class PropertyDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorized: true,
      id: this.props.match.params.id,
      trackingMachineIds: [],
      plan: null,
      noteComment: "",
      activeTab: 1,
      menuOpen: false,
      uploadOpen: false,
      albumOpen: false,
      clientModalOpen: false,
      assignClientOpen: false,
      assignManagerOpen: false,
      displayMedia: null,
      displayClient: null,
      displayClientRole:"",
      selectedFile: null,
      fileCaption: "",
      attachmentSubType: null,
      attachmentSubTypeOptions: [],
      ownerIndex: null,
      occupierIndex: null,
      managerIndex: null,
      ownerOptions: [],
      occupierOptions: [],
      managerOptions: [],
      addressGeoCoords: {
        address: null,
        latitude: null,
        longitude: null
      },
      inputStates: {
        noteComment: "",
        selectedFile: "",
        fileCaption: "",
        attachmentSubType: "",
        ownerIndex: "",
        occupierIndex: "",
        managerIndex: ""
      },
      inputErrors: {
        noteComment: "",
        selectedFile: "",
        fileCaption: "",
        attachmentSubType: "",
        ownerIndex: "",
        occupierIndex: "",
        managerIndex: ""
      }
    };
  }

  async componentDidMount() {
    const rolesHaveAccess = ["admin", "stratamanager", "propertymanager", "supplier", "owner", "owneroccupier", "tenant"];
    const roleType = Cache.getItem("roleType");

    if(!rolesHaveAccess.includes(roleType)){
      this.setState({ authorized: false });
      return;
    }

    if(roleType !== "admin") {
      const userId = Cache.getItem("email");
      const planIds = await Utils.getAccessPlanIds(userId, roleType);

      if(!planIds.includes(this.state.id)){
        const plan = await Utils.getPlan(this.state.id);
        if(plan.parentId == null || !planIds.includes(plan.parentId)){
          this.setState({ authorized: false });
          return;
        }
      }
    }

    this.props.setLoading(true);

    await this.loadPlan();
    //console.log(this.state);

    const attachmentSubTypes = await Enums.getAttachmentSubTypes();
    this.setState({ attachmentSubTypeOptions: attachmentSubTypes});

    const plan = this.state.plan;

    const users = await this.getUsers();

    let managerOptions = [];
    const strataManagers = users.filter(x => x.personas.find(y => y.roleType === "stratamanager"));
    for(let i = 0; i < strataManagers.length; i++){
      const item = {
        ...strataManagers[i],
        roleType: "stratamanager"
      };
      managerOptions.push(item);
    }

    const propertyManagers = users.filter(x => x.personas.find(y => y.roleType === "propertymanager"));
    for(let i = 0; i < propertyManagers.length; i++){
      const item = {
        ...propertyManagers[i],
        roleType: "propertymanager"
      };
      managerOptions.push(item);
    }

    for(let i = 0; i < managerOptions.length; i++){
      managerOptions[i].index = i;
    }

    this.setState({ managerOptions });

    let ownerOptions = [];
    const owners = users.filter(x => x.personas.find(y => y.roleType === "owner" || y.roleType === "owneroccupier")); // Clone array    
    for(let i = 0; i < owners.length; i++){
      const item = {
        ...owners[i],
        roleType: owners[i].personas.find(y => y.roleType === "owner" || y.roleType === "owneroccupier").roleType,
        index: i
      };
      
      ownerOptions.push(item);
    }
    this.setState({ ownerOptions });
    //console.log("ownerOptions: " + ownerOptions);

    if(plan.owner != null){
      this.setState({ ownerIndex: owners.findIndex(x => x.userId === plan.owner.userId) });
    }

    let occupierOptions = [];
    const occupiers = users.filter(x => x.personas.find(y => y.roleType === "tenant" || y.roleType === "owneroccupier")); 
    for(let i = 0; i < occupiers.length; i++){
      const item = {
        ...occupiers[i],
        roleType: occupiers[i].personas.find(y => y.roleType === "tenant" || y.roleType === "owneroccupier").roleType,
        index: i
      };
      
      occupierOptions.push(item);
    }
    this.setState({ occupierOptions });
    //console.log("occupierOptions: " + occupierOptions);

    if(plan.occupier != null){
      this.setState({ occupierIndex: occupiers.findIndex(x => x.userId === plan.occupier.userId) });
    }

    this.props.setLoading(false);

    this.createMachineSubscription = API.graphql(
        graphqlOperation(OnCreateMachine)
      ).subscribe({
        next: async (state) => await this.doRefresh(state.value.data.onCreateMachine, "create")
    });
  
    this.updateMachineSubscription = API.graphql(
        graphqlOperation(OnUpdateMachine)
      ).subscribe({
        next: async (state) => await this.doRefresh(state.value.data.onUpdateMachine, "update")
    });
  
    this.deleteMachineSubscription = API.graphql(
        graphqlOperation(OnDeleteMachine)
      ).subscribe({
        next: async (state) => await this.doRefresh(state.value.data.onDeleteMachine, "delete")
    });
  }

  componentWillUnmount() {
    this.createMachineSubscription.unsubscribe();
    this.updateMachineSubscription.unsubscribe();
    this.deleteMachineSubscription.unsubscribe();
  }

  async doRefresh(machine, trigger) {
    //console.log(machine);

    if(trigger === "create" && machine.parentForeignId === this.state.id){
      let trackingMachineIds = this.state.trackingMachineIds;
      trackingMachineIds.push(machine.id);
      this.setState({ trackingMachineIds: trackingMachineIds});
    }

    if(!this.state.trackingMachineIds.includes(machine.id)) 
      return;

    //this.props.setLoading(true);
    await this.loadPlan();
    //this.props.setLoading(false);
  }

  openDialogUpload  = () => {
    this.setState({ uploadOpen: true });
  };

  closeDialogUpload = () => {
    this.setState({ uploadOpen: false, selectedFile: null, fileCaption: "" });
  };

  openDialogAlbum = (mediaId) => {
    const media = this.state.plan.mediaAssets.find(x => x.id === mediaId);
    this.setState({ albumOpen: true, displayMedia: media });
  };

  closeDialogAlbum = () => {
    this.setState({ albumOpen: false, displayMedia: null });
  };

  openDialogModalClient = (client, role) => {
    this.setState({ clientModalOpen: true, displayClient: client, displayClientRole: role });
  };

  closeDialogModalClient = () => {
    this.setState({ clientModalOpen: false, displayClient: null, displayClientRole: "" });
  };

  openDialogAssignClient = () => {
    this.setState({ assignClientOpen: true });
  };

  closeDialogAssignClient = () => {
    const ownerIndex = this.state.plan.owner == null ? null : this.state.ownerOptions.findIndex(x => x.id === this.state.plan.owner.id);
    const occupierIndex = this.state.plan.occupier == null ? null : this.state.occupierOptions.findIndex(x => x.id === this.state.plan.occupier.id);
    this.setState({ assignClientOpen: false, ownerIndex: ownerIndex, occupierIndex: occupierIndex });
  };

  openDialogAssignManager = (client) => {
    this.setState({ assignManagerOpen: true });
  };

  closeDialogAssignManager = () => {
    this.setState({ assignManagerOpen: false });
  };

  handleAlbumPrev = () => {
    const assets = this.state.plan.mediaAssets;
    const current = this.state.displayMedia;
    const currentIndex = assets.findIndex(x => x.id === current.id);
    const prevIndex = currentIndex === 0 ? assets.length - 1 : currentIndex - 1;
    this.setState({ displayMedia: assets[prevIndex] });
    if(current.category == "video" || current.category == "audio") {
      const video = document.getElementById("video_" + current.id);
      if(video != null)
        video.pause();
    }
  };

  handleAlbumNext = () => {
    const assets = this.state.plan.mediaAssets;
    const current = this.state.displayMedia;
    const currentIndex = assets.findIndex(x => x.id === current.id);
    const nextIndex = currentIndex === assets.length - 1 ? 0 : currentIndex + 1;
    this.setState({ displayMedia: assets[nextIndex] });
    if(current.category == "video" || current.category == "audio") {
      const video = document.getElementById("video_" + current.id);
      if(video != null)
        video.pause();
    }
  };
  
  menuClickOpen = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  }
  
  activateTab = (event, index) => {
    event.preventDefault();
    this.setState({ activeTab: index });
  }
  
  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  handleMediaChange = (event) => {
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (event.target.files.length === 0) {
      inputStates["selectedFile"] = "";
      inputErrors["selectedFile"] = "";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      this.setState({selectedFile: null});
      return;
    } 

    if (event.target.files.length > 1) {
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = "Multiple files were slected. Please select one file";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    } 

    var file = event.target.files[0];
    
    if (!Helpers.checkLegitDocument(file.name)
      && !Helpers.checkLegitImage(file.name)
      && !Helpers.checkLegitAudio(file.name)
      && !Helpers.checkLegitVideo(file.name)) {
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = "This file format is not supported";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    const category = Helpers.getFileCategory(file.name);

    const maxFileSize = category === "video" 
      ? config.MAX_ATTACHMENT_SIZE_VIDEO
      : config.MAX_ATTACHMENT_SIZE_FILE;

    if (file.size > maxFileSize) {
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = `Please select a file smaller than ${maxFileSize / 1000000} MB.`;
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    } 

    inputStates["selectedFile"] = "success";
    inputErrors["selectedFile"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });
    this.setState({selectedFile: file});
  }
  
  handleAddMedia = async () => {
    let valid = true;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (this.state.selectedFile == null) {
      valid = false;
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = "Media file is required";
    } 

    if (this.state.fileCaption == null || this.state.fileCaption === "") {
      valid = false;
      inputStates["fileCaption"] = "error";
      inputErrors["fileCaption"] = "This field is required";
    } 

    if (this.state.attachmentSubType == null || this.state.attachmentSubType === "") {
      valid = false;
      inputStates["attachmentSubType"] = "error";
      inputErrors["attachmentSubType"] = "This field is required";
    } 

    if(!valid) {
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    inputStates["selectedFile"] = "success";
    inputErrors["selectedFile"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    this.props.setLoading(true);

    Amplify.configure(strataApiConfig);
    
    try {
      const planId = this.state.id;
      const fileCaption = this.state.fileCaption;
      const selectedFile = this.state.selectedFile;
      const formattedName = selectedFile.name
        .replace("-", " ")
        .replace("_", " ")
        .replace(/[^a-zA-Z0-9\. ]/g, "")
        .replace(/[ ]+/g, "-");
      const fileName = (Helpers.generateGuid() + "_" + formattedName).toLowerCase(); // must not have spaces in file names 

      const prefix = "plan/" + planId;
      var result = await FileS3.upload(selectedFile, prefix, fileName); 
      
      const annotation = 
        this.state.attachmentSubTypeOptions.find(x => x.value1 === this.state.attachmentSubType).value2 
        + " - " 
        + fileCaption;

      const respAttachment = await Utils.createAttachmentMedia(
        planId, 
        "plan",
        result.privateFileKey, 
        result.privateFileURL,
        fileCaption,
        selectedFile.type.toLowerCase(),
        selectedFile.size / (1024 * 1024),
        this.state.attachmentSubType,
        annotation);

      const attachmentId = respAttachment.data.createAttachment.id; 

      await Utils.createMachine(
        attachmentId, 
        "attach", 
        planId,
        "strata",
        "thinking", 
        "ticketed",
        config.ADMIN_USER_ID,
        "admin");

      // Set primary image
      if(this.state.plan.mediumId == null 
        && Helpers.getFileCategory(fileName) === "image"){
        await Utils.updatePlanMedium(planId, attachmentId);
      }
    } catch (e) {
      console.log(e);
      alert(JSON.stringify(e));
    }

    await this.loadPlan();
    this.props.setLoading(false);
    this.closeDialogUpload();
  }
  
  handleDeleteMedia = async (id) => {
    // event.preventDefault();
    // event.stopPropagation();

    if(!window.confirm('Are you sure you want to delete?')){
      return;
    }

    try {
      await Utils.deleteAttachment(id);
      // Set primary image
      if(this.state.plan.mediumId == id){
        await Utils.updatePlanMedium(this.state.id, null);
      }
      await this.loadPlan();
    }
    catch (e) {
      console.log(e);
    }
  }
  
  handleSetMedium = async (event, id) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      await Utils.updatePlanMedium(this.state.id, id);
      await this.loadPlan();
    }
    catch (e) {
      console.log(e);
    }
  }
  
  handleAddNote = async () => {
    let valid = true;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (this.state.noteComment == null || this.state.noteComment === "") {
      valid = false;
      inputStates["noteComment"] = "error";
      inputErrors["noteComment"] = "This field is required";
    } 

    if(!valid) {
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    inputStates["noteComment"] = "success";
    inputErrors["noteComment"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });
    
    this.props.setLoading(true);

    const planId = this.state.id;

    try{
      const respAttachment = await Utils.createAttachmentNote(planId, "plan", this.state.noteComment);

      const attachmentId = respAttachment.data.createAttachment.id;

      await Utils.createMachine(
        attachmentId, 
        "attach", 
        planId,
        "strata",
        "thinking", 
        "ticketed",
        config.ADMIN_USER_ID,
        "admin");

      await this.loadPlan();
      this.setState({ noteComment: "" });
    }
    catch{
      //
    }
    
    this.props.setLoading(false);
  }
  
  handleAssignClient = async () => {  
    let valid = true;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    const plan = this.state.plan;
    const currentOwnerId = plan.owner == null ? null : plan.owner.userId;
    const currentOccupierId = plan.occupier == null ? null : plan.occupier.userId;
    const selectedOwner = (this.state.ownerIndex ?? "") === "" ? null : this.state.ownerOptions[this.state.ownerIndex];
    const selectedOccupier = (this.state.occupierIndex ?? "") === "" ? null : this.state.occupierOptions[this.state.occupierIndex];

    if ((selectedOwner.roleType == "owneroccupier" || selectedOccupier.roleType == "owneroccupier") 
          && selectedOwner.userId != selectedOccupier.userId) {
      valid = false;
      inputStates["ownerIndex"] = "error";
      inputErrors["ownerIndex"] = "Owner occupier must be the same";
    } 

    if(!valid) {
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    inputStates["ownerIndex"] = "success";
    inputErrors["ownerIndex"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });
    
    this.props.setLoading(true);  

    try {
      // Manage owner
      if(selectedOwner != null && currentOwnerId != selectedOwner.userId) {
        if(plan.owner != null) {
          await this.deletePlanClients(plan.id, "owner");
        }

        //console.log(currentOwnerId + " -> " + selectedOwner.userId);
        //console.log(plan.owner);
        //console.log(selectedOwner);
        
        const duplicate = (await Utils.findActor(selectedOwner.userId, selectedOwner.roleType, "lot", plan.id)) != null;
  
        if(!duplicate) {
          console.log("adding: " + selectedOwner.userId);
          await Utils.createActor(selectedOwner.userId, selectedOwner.roleType, "lot", plan.id);
        }
      }

      if(selectedOwner == null && plan.owner != null) {
        await this.deletePlanClients(plan.id, "owner");
      }

      console.log(selectedOccupier);

      // Manage occupier
      if(selectedOccupier != null && currentOccupierId != selectedOccupier.userId) {
        if(plan.occupier != null) {
          await this.deletePlanClients(plan.id, "occupier");
        }

        console.log(currentOccupierId + " -> " + selectedOccupier.userId);
        console.log(plan.occupier);
        console.log(selectedOccupier);
        
        const duplicate = (await Utils.findActor(selectedOccupier.userId, selectedOccupier.roleType, "lot", plan.id)) != null;
  
        if(!duplicate) {
          console.log("adding: " + selectedOccupier.userId);
          await Utils.createActor(selectedOccupier.userId, selectedOccupier.roleType, "lot", plan.id);
        }
      }

      if(selectedOccupier == null && plan.occupier != null) {
        await this.deletePlanClients(plan.id, "occupier");
      }
      
      await this.loadPlan();

      this.setState({ assignClientOpen: false });
      //this.closeDialogAssignClient();
    }
    catch(e){
      console.log(e);
    }
    
    this.props.setLoading(false);
  }
  
  handleAssignManager = async () => {
    let valid = true;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (this.state.managerIndex == null || this.state.managerIndex === "") {
      valid = false;
      inputStates["managerIndex"] = "error";
      inputErrors["managerIndex"] = "This field is required";
    } 

    if(!valid) {
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    inputStates["managerIndex"] = "success";
    inputErrors["managerIndex"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });
    
    this.props.setLoading(true);

    try {
      const planId = this.state.id;

      const manager = this.state.managerOptions[this.state.managerIndex];

      const duplicate = (await Utils.findActor(manager.userId, manager.roleType, "strata", planId)) != null;

      //console.log(duplicate);

      if(!duplicate) {
        await Utils.createActor(manager.userId, manager.roleType, "strata", planId);
        await this.loadPlan();
      }

      this.setState({ managerIndex: null });
      this.closeDialogAssignManager();
    }
    catch(e){
      console.log(e);
    }
    
    this.props.setLoading(false);
  }
  
  handleDeleteManager = async (id) => {
    //console.log(id);

    if(!window.confirm('Are you sure you want to delete?')){
      return;
    }

    try {
      await Utils.deleteActor(id);
      await this.loadPlan();
    }
    catch (e) {
      console.log(e);
    }
  }

  loadPlan = async () => {
    const plan = await this.getPlan();

    if(plan == null){
      this.setState({ plan: "INVALID" });
      return;
    }

    let trackingMachineIds = [];
    plan.noteItems.forEach((x) => { trackingMachineIds.push(x.id) });
    plan.mediaItems.forEach((x) => { trackingMachineIds.push(x.id) });

    this.setState({ plan });
    this.setState({ trackingMachineIds });

    //console.log(this.state.trackingMachineIds);
    
    if(this.state.plan == null) 
    return;

    this.getAddressGeoCoords(this.state.plan.address);
  }

  getAddressGeoCoords = (address) => {
    if(address === this.state.addressGeoCoords.address)
      return;
    
    if(address == null || address === "") {
      var coords = {address: null, latitude: null, longitude: null};
      this.setState({ addressGeoCoords: coords }); 
      return;
    }

    Geocode.setApiKey(config.GMAP_API_KEY);
    Geocode.setLanguage("en");
    Geocode.setRegion("au");
    Geocode.enableDebug();
    // Get latitude & longitude from address.
    Geocode.fromAddress(address).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        var coords = {address: address, latitude: lat, longitude: lng};
        this.setState({ addressGeoCoords: coords });        
        //console.log(lat, lng);
      },
      error => {
        console.error(error);
      }
    );
  }

  getPlan = async () => {
    Amplify.configure(strataApiConfig);
    
    const params = { id: this.state.id };

    const cmd = `
      query(
        $id: ID!
      ) {
        plan: getPlan(
          id: $id
        ){
          id
          parentId
          planType
          description
          planName
          createdAt
          address_name
          address_number
          address_street
          address_suburb
          address_postcode
          address_state
          address_country
          lot_unitCount
          lot_parkingSpot
          lot_keyTag
          lot_keyTagCount
          lot_level
          lot_unit
          mediumId
          medium {
            id
            media_fileKey
            media_title
            media_mimeType
            media_annotation
          }
          parentPlan {
            id
            planName
          }
          subplans {
            id
          }
          txns {
            id
            refId
            txnType 
            ticket_subject 
            ticket_priorityRank 
            createdAt
            subplanIds
            machines {
              machineState {
                stateType
              }
            }
          }
          actors {
            id
            userId
            roleType
            foreignId
            user {
              person_firstName
              person_middleName
              person_lastName
              contact_email
              contact_mobile
            }
          }
          notes(
            sortDirection: DESC
            limit: 1000
          ) {
              items {
                  id
                  createdAt
                  note_comment
                  attachmentType
              }
          }
          media(
            sortDirection: ASC
            limit: 1000
          ) {
              items {
                id
                media_fileKey
                media_title
                media_mimeType
                media_annotation
              }
          }
        }
      }`;


    const result = await API.graphql(graphqlOperation(cmd, params));

    // alert(JSON.stringify(result));

    const plan = result.data.plan;
    //console.log(plan);

    const userId = Cache.getItem("email");
    const roleType = Cache.getItem("roleType");

    const restrictions = roleType !== "admin";
    const accessIssueIds = restrictions
      ? await Utils.getAccessIssueIds(userId, roleType)
      : null;

    //console.log(accessIssueIds);

    const completeStates = ["end", "finish"];

    const issues = plan.txns
      .filter(x => x.txnType === "ticket" 
        && (!restrictions || accessIssueIds.includes(x.id))
        && x.machines[0] != null 
        && x.machines[0].machineState !== null);

    // alert(JSON.stringify(issues));

    let data = {
      id: plan.id,
      parentId: plan.parentId,
      planType: plan.planType,
      planName: plan.planName,
      description: plan.description,
      address: (plan.address_name ?? "") + " " + (plan.address_number ?? "") + " " + (plan.address_street ?? "") + " " + (plan.address_suburb ?? "") + " " + (plan.address_postcode ?? "") + " " + (plan.address_state ?? ""),
      lot_parkingSpot: plan.lot_parkingSpot,
      lot_keyTag: plan.lot_keyTag,
      lot_keyTagCount: plan.lot_keyTagCount,
      lot_level: plan.lot_level,
      lot_unit: plan.lot_unit,
      // mediumId: plan.mediumId,
      mediumId: null,
      medium: plan.medium,
      primaryImage: null,
      owner: null,
      occupier: null,
      managers: [],
      lots: plan.subplans.length,
      actors: plan.actors,
      noteItems: plan.notes.items,
      mediaItems: plan.media.items,
      pendingIssuesCount: issues
        .filter(x => !completeStates.includes(x.machines[0].machineState.stateType)).length,
      resolvedIssuesCount: issues
        .filter(x => completeStates.includes(x.machines[0].machineState.stateType)).length,
      issueItems: issues
        .filter(x => !completeStates.includes(x.machines[0].machineState.stateType))
        .map((x) => {
          const machineState = x.machines[0] == null ? null : x.machines[0].machineState;
          return {
            id: x.id.trim().split("\n")[0],
            refId: x.refId,
            subject: x.ticket_subject,
            createdAt: x.createdAt,
            priority: Enums.getPriorityLabel(x.ticket_priorityRank),
            status: machineState == null ? null : machineState.stateType
          };
        }),
      assets: [],
      mediaAssets: [],
      documentAssets: []
    };

    data.address = data.address.trim();

    if(data.planType === "lot"){
      data.issueItems = await this.getLotPendingIssues(data.id, data.parentId, restrictions, accessIssueIds);
      data.pendingIssuesCount = data.issueItems.length;
    }

    data.issueItems = data.issueItems.sort((a,b) => (a.createdAt > b.createdAt) ? -1 : 1);
    data.issueItems = data.issueItems.slice(0, 5); 

    const ownerActor = plan.actors.filter(x => x.roleType === "owner" || x.roleType === "owneroccupier")[0];

    if(ownerActor != null && ownerActor.user != null){
      data.owner = {
          userId: ownerActor.userId,
          actorId: ownerActor.id,
          firstName: ownerActor.user.person_firstName,
          middleName: ownerActor.user.person_middleName,
          lastName: ownerActor.user.person_lastName,
          email: ownerActor.user.contact_email,
          mobile: ownerActor.user.contact_mobile,
          roleType: ownerActor.roleType
        };
    }

    const occupierActor = plan.actors.filter(x => x.roleType === "tenant" || x.roleType === "owneroccupier")[0];

    if(occupierActor != null && occupierActor.user != null){
      data.occupier = {
          userId: occupierActor.userId,
          actorId: occupierActor.id,
          firstName: occupierActor.user.person_firstName,
          middleName: occupierActor.user.person_middleName,
          lastName: occupierActor.user.person_lastName,
          email: occupierActor.user.contact_email,
          mobile: occupierActor.user.contact_mobile,
          roleType: occupierActor.roleType
        };
    }

    data.managers = plan.actors.filter(x => x.roleType === "stratamanager" || x.roleType === "propertymanager");


    if(plan.medium != null && plan.medium.media_fileKey != null) {
      const thumbKey = plan.medium.media_fileKey.replace("public/", "") + "-thumbnail." + Helpers.getFileExtension(plan.medium.media_fileKey);
      data.primaryImage = await Storage.get(thumbKey);
    }

    var assets = await Helpers.formatMediaToAssets(data.mediaItems);

    data.mediaAssets = assets.filter(x => x.category !== "document");
    data.documentAssets = assets.filter(x => x.category === "document");

    return data;
  }

  async getLotPendingIssues(lotId, parentId, restrictions, accessIssueIds) {
    Amplify.configure(strataApiConfig);

    let txns = [];
    
    try {
      const params = {
        txnType: "ticket",
        planId: parentId
      };

      const cmd = `
        query(
          $txnType: String!
          $planId: String!
        ) {
          txns: listTxns(
            filter: {
              txnType: { eq: $txnType }
              planId: { eq: $planId }
            },
            limit: 10000
          ){
            items {
              id
              refId
              txnType 
              ticket_subject 
              ticket_priorityRank 
              createdAt
              subplanIds
              machines {
                machineState {
                  stateType
                }
              }
            }
          }
        }`;

      const result = await API.graphql(graphqlOperation(cmd, params));

      //console.log(result);
      if (result.data != null && result.data.txns.items.length > 0) { 
        txns = result.data.txns.items; 
      }
    } catch (e) {
      console.log(e);
      alert(JSON.stringify(e));
      if (e.hasOwnProperty("data") && e.data != null) {
        txns = e.data.txns.items;
      }
    }

    if (restrictions) {
      txns = txns.filter(x => accessIssueIds.includes(x.id));
    }

    const completeStates = ["end", "finish"];

    txns = txns.filter(x => 
      x.machines[0] != null 
      && x.machines[0].machineState != null
      && x.subplanIds != null
      && x.subplanIds.includes(lotId)
      && !completeStates.includes(x.machines[0].machineState.stateType)
    );

    txns = txns.sort((a,b) => (a.createdAt > b.createdAt) ? -1 : 1); 

    return txns
      .map((x) => {
        const machine = x.machines[0];
        const machineState = machine == null ? null : machine.machineState;

        return {
          id: x.id.trim().split("\n")[0],
          refId: x.refId,
          subject: x.ticket_subject,
          createdAt: x.createdAt,
          priority: Enums.getPriorityLabel(x.ticket_priorityRank),
          status: machineState == null ? null : machineState.stateType
        };
      });
  }

  getUsers = async () => {
    Amplify.configure(strataApiConfig);

    const params = { };

    const cmd = `
      query {
        users: listUsers(
          limit: 10000
        ){
          items{ 
            id
            person_firstName
            person_middleName
            person_lastName
            contact_email
            contact_mobile
            personas {
              items {
                roleType
              }
            }
          }
        }
      }`;

    const result = await API.graphql(graphqlOperation(cmd, params));

    //console.log(result);

    let users = result.data.users.items.map((data) => {
      return {
        userId: data.id,
        name: data.person_firstName + " " + Helpers.isNull(data.person_middleName, "") + " " + data.person_lastName,
        mobile: data.contact_mobile,
        email: data.contact_email,
        personas: data.personas.items
      };
    });

    users = users.sort((a, b) => a.name > b.name ? 1 : -1); 

    return users;
  }

  getPlanClients = async (planId, roleType) => {
    Amplify.configure(strataApiConfig);

    const params = { 
      roleType: roleType,
      foreignType: "lot",
      foreignId: planId
    };

    const cmd = `
      query(
        $roleType: String
        $foreignType: String
        $foreignId: String
      ){
        actors: listActors(
            filter: {
                roleType: { eq: $roleType }
                foreignType: { eq: $foreignType }
                foreignId: { eq: $foreignId }
            }
            limit: 1000
        ){
            items {
                id
                userId
                roleType
                foreignType
                foreignId
            }
        }
      }`;

    const respActors = await API.graphql(graphqlOperation(cmd, params));

    return respActors.data.actors.items;
  }

  deletePlanClients = async (planId, role) => {
    Amplify.configure(strataApiConfig);

    let actors = await this.getPlanClients(planId, "owneroccupier");

    console.log(actors);

    actors.forEach(async (x) => {
      console.log("deleting: " + x.id);
      await Utils.deleteActor(x.id);
    });

    actors = await this.getPlanClients(planId, (role === "owner" ? "owner" : "tenant"));

    console.log(actors);

    actors.forEach(async (x) => {
      console.log("deleting: " + x.id);
      await Utils.deleteActor(x.id);
    });
  }

  renderIssues = items => {
    return items.map((item, index) => 
        <IssueItem 
          key={index} 
          data={item} />);
  }

  renderNotes = items => {
    return items.map((item, index) => 
        <NoteItem 
          key={index} 
          data={item} />);
  }

  renderAttachments = (items, canManage) => {
    return items.map((item, index) => 
        <AttachmentItem 
          key={index} 
          data={item}
          canManage={canManage}
          handleDeleteMedia={(e) => {this.handleDeleteMedia(item.id);} } />);
  }

  renderManagers = items => {
    return items.map((item, index) => 
        <ManagerItem 
          key={index} 
          data={item}
          handleDeleteManager={(e) => {this.handleDeleteManager(item.id);} } />);
  }

  renderMap() {
    if(this.state.addressGeoCoords.latitude == null || this.state.addressGeoCoords.longitude == null)
      return null;

    return (
      <div className="prop-map-wrap">
        <div className="data-label">Location</div>
        <div className="map clearfix">
          <Map 
            google={this.props.google}
            zoom={14}
            style={{ width:"100%", height:"100%" }}
            initialCenter={{ lat: this.state.addressGeoCoords.latitude, lng: this.state.addressGeoCoords.longitude }}
          />
        </div>
      </div>
    );
  }

  render() {
    if(this.state.authorized === false){
      return (<UnauthPanel />);
    }

    if(this.state.plan == null){
      return null;
    }

    if(this.state.plan == "INVALID"){
      return (<div className="pdt-lg">Invalid property</div>);
    }

    const roleType = Cache.getItem("roleType");

    const plan = this.state.plan;
    const lot = plan.planType === "lot";
    const canManage = roleType === "admin" || roleType === "stratamanager" || roleType === "propertymanager";
    const managersTabAccess = !lot && roleType === "admin";
    
    return (
      <React.Fragment>
        <div className="page-title">
        <a href="/issues/create" className="btn btn-success solid pull-right hidden-xs">Report an Issue</a>
        <a href="/issues/create" className="btn btn-success solid pull-right visible-xs btn-issue-report">
          <img src="/images/icons/error_outline-24px.svg" alt=""/>
        </a>
          <h1>{lot ? "Lot" : "Property"}</h1>
        </div>
        <div className="row">
          <div className="col-lg-6">
            
            <div className="issue-item active clearfix">
                {
                  canManage && 
                  <React.Fragment>
                    <a className="menu-icon" href="#" 
                        onClick={(e) => { e.preventDefault(); this.menuClickOpen(); }}>
                        <img src="/images/icons/more_vert-24px.svg" alt=""/>
                    </a>
                    <div className={`context-menu ${this.state.menuOpen ? "open" : ""}`}>
                        <ul>
                            <li><a href={`/plans/edit/${plan.id}`}>Edit Property</a></li>
                            <li><a href="#" onClick={(e) => { e.preventDefault(); this.openDialogUpload(); this.menuClickOpen(); }}>Upload File</a></li>
                            {
                              lot &&
                              <li><a href="#" onClick={(e) => { e.preventDefault(); this.openDialogAssignClient(); this.menuClickOpen(); }}>
                                Manage Owner/Occupier</a>
                              </li>
                            }
                        </ul>
                    </div>
                  </React.Fragment>
                }

                <div className="clearfix">
                  <div className="ref">
                  <div className="code color-grey text-bold">{ Helpers.isNull(plan.id, "---") }</div>
                      {
                        plan.primaryImage != null &&
                        <a href="#" onClick={(e) => { e.preventDefault(); this.openDialogAlbum(plan.mediumId); }}>
                            <img className="issue-img" src={plan.primaryImage} alt=""/>
                        </a>
                      }
                      
                      {
                        plan.primaryImage == null && 
                        plan.mediumId != null &&
                        <a href="#" onClick={(e) => e.preventDefault()}>
                            <img className="issue-img proc" src="/images/processing.svg" alt=""/>
                        </a>
                      }
                      
                      {
                        plan.primaryImage == null &&
                        plan.mediumId == null &&
                        <a href="#" onClick={(e) => e.preventDefault()}>
                            <img className="issue-img" src="/images/noimage.png" alt=""/>
                        </a>
                      }
                  </div>
                  <div className="info">
                      <div className="issue-title">
                          { Helpers.isNull(plan.planName, "---") }
                      </div>
                      <div className="issue-address">
                          <img src="/images/icons/location_on-24px.svg" alt=""/>
                          {plan.address}
                      </div>
                      {
                        !lot &&
                        <React.Fragment>
                          <div className="pdt row">
                            <div className="col-xs-6">
                                <div className="data-label">Resolved Issues</div>
                                <div className="text-light">
                                  <a href={"/issues/list/resolved/" + plan.id}>{ plan.resolvedIssuesCount }</a>
                                </div>
                            </div>
                            <div className="col-xs-6">
                                <div className="data-label">Pending Issues</div>
                                <div className="text-light">
                                  <a href={"/issues/list/pending/" + plan.id}>{ plan.pendingIssuesCount }</a>
                                </div>
                            </div>
                          </div>
                          <div className="pdt row">
                            <div className="col-xs-6 pdb">
                                <div className="data-label">Lots/Units</div>
                                <div className="text-light">
                                  <a href={"/plans/lots/" + plan.id}>{ plan.lots }</a>
                                </div>
                            </div>
                          </div>
                        </React.Fragment>
                      }

                      {
                        lot &&
                        <React.Fragment>
                          <div className="pdt row">
                            <div className="col-xs-6">
                                <div className="data-label">Owner</div>
                                <div className="text-light">
                                  { 
                                    plan.owner == null
                                    ? <span>---</span>
                                    : <a href="#" onClick={(e) => {e.preventDefault(); this.openDialogModalClient(plan.owner, "owner"); }}>
                                        {plan.owner == null ? "---" : (plan.owner.firstName + " " + plan.owner.lastName)}
                                      </a>
                                  }
                                </div>
                            </div>
                            <div className="col-xs-6">
                                <div className="data-label">Occupier</div>
                                <div className="text-light">
                                  { 
                                    plan.occupier == null
                                    ? <span>---</span>
                                    : <a href="#" onClick={(e) => {e.preventDefault(); this.openDialogModalClient(plan.occupier, "occupier"); }}>
                                        {plan.occupier == null ? "---" : (plan.occupier.firstName + " " + plan.occupier.lastName)}
                                      </a>
                                  }
                                </div>
                            </div>
                          </div>
                          <div className="pdt row">
                            <div className="col-xs-6">
                                <div className="data-label">Parking space number</div>
                                <div className="text-light">{ Helpers.isNull(plan.lot_parkingSpot, "---") }</div>
                            </div>
                            <div className="col-xs-6">
                                <div className="data-label">Electronic Key tag number</div>
                                <div className="text-light">{ Helpers.isNull(plan.lot_keyTag, "---") }</div>
                            </div>
                          </div>
                          <div className="pdt pdb row">
                            <div className="col-xs-6">
                                <div className="data-label">Number of tag issued</div>
                                <div className="text-light">{ Helpers.isNull(plan.lot_keyTagCount, "---") }</div>
                            </div>
                            <div className="col-xs-6">
                                <div className="data-label">Level</div>
                                <div className="text-light">{ Helpers.isNull(plan.lot_level, "---") }</div>
                            </div>
                          </div>
                        </React.Fragment>
                      }
                  </div>
                </div>
                
                <div className="desc">
                  <div className="data-label">Property Description</div>
                  <div className="text-light">{Helpers.isNull(plan.description, "---")}</div>
                </div>
                
                <div className={`issue-section-wrap ${plan.assets.length === 0 ? "hidden" : ""}`}>
                  <div className="data-label">Media Files</div>
                  <div className="row media-files">
                      {
                        plan.mediaAssets.map((item, index) => 
                          item.category === "image" 
                          ? (<div className="col-sm-2 col-xs-4 preview-img" key={index} data-id={item.id}>
                                {
                                  canManage && 
                                  <React.Fragment>
                                    <a href="#" className="delete" 
                                      onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.handleDeleteMedia(item.id);} } >
                                      <img src="/images/icons/delete-24px.svg" alt=""/>
                                    </a>
                                    <a href="#" className={`medium ${plan.mediumId === item.id ? "active" : ""}`} 
                                      onClick={(e) => { this.handleSetMedium(e, item.id);} } >
                                      <img src="/images/icons/done-24px.svg" alt=""/>
                                    </a>
                                  </React.Fragment>
                                }
                                <a href="#" className={`photo ${item.thumbnail == null ? "proc" : ""}`} 
                                  onClick={(e) => { e.preventDefault(); this.openDialogAlbum(item.id); }}>
                                  <img src={item.thumbnail == null ? "/images/processing.svg" : item.thumbnail} alt=""/>
                                </a>
                              </div>)
                          : (<div className="col-sm-2 col-xs-4 preview-img" key={index} data-id={item.id}>
                              {
                                canManage && 
                                <React.Fragment>
                                  <a href="#" className="delete" 
                                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.handleDeleteMedia(item.id);} } >
                                    <img src="/images/icons/delete-24px.svg" alt=""/>
                                  </a>
                                </React.Fragment>
                              }
                              <a href="#" className="photo video" 
                                onClick={(e) => { e.preventDefault(); this.openDialogAlbum(item.id); }}>
                                <img src="/images/icons/movie-24px.svg" alt=""/>
                              </a>
                            </div>)
                        )
                      }   
                  </div>                  
                </div>
                
                <div className={`prop-issues-wrap ${plan.issueItems.length == 0 ? "hidden" : ""}`}>
                  <div className="data-label">Pending Issues</div>
                  <div className="prop-issues">
                    {this.renderIssues(plan.issueItems)}
                  </div>
                  <div className="view-issues pdt pdb">
                    <a href={`/issues/list/pending/${plan.id}`}>
                      <img src="/images/icons/remove_red_eye-24px.svg" className="pull-left mgr-sm" alt=""/>
                      View all {plan.pendingIssuesCount} pending issues
                    </a>
                  </div>
                </div>
                
                { this.renderMap() }
            </div>

          </div>

          <div className="col-lg-6">
            <div className="tabs-wrap">
              <ul className={`tabs clearfix ${managersTabAccess ? "c3" : ""}`}>
                <li className={this.state.activeTab == 1 ? "active" : ""}>
                  <a href="#" onClick={(e) => { this.activateTab(e, 1); }}>
                    <img src="/images/icons/featured_play_list-24px.svg" alt="" className="icon" />
                    Documents
                  </a>
                </li>
                <li className={this.state.activeTab == 2 ? "active" : ""}>
                  <a href="#" onClick={(e) => { this.activateTab(e, 2); }}>
                    <img src="/images/icons/notifications-24px.svg" alt="" className="icon" />
                    Notes
                  </a>
                </li>
                {
                  managersTabAccess &&
                  <li className={this.state.activeTab == 3 ? "active" : ""}>
                    <a href="#" onClick={(e) => { this.activateTab(e, 3); }}>
                      <img src="/images/icons/person-24px.svg" alt="" className="icon" />
                      Managers
                    </a>
                  </li>
                }
              </ul>
            </div>

            <div className={`tab-view ${this.state.activeTab == 1 ? "active" : ""}`}> 
              <div className="prop-docs">
                {this.renderAttachments(plan.documentAssets, canManage)}  
              </div>
              
              {
                canManage && 
                <div className="pdt-lg pdb-lg clearfix">
                  <a href="#" className="action-btn white pull-right" onClick={(e) => { e.preventDefault(); this.openDialogUpload() }}>
                    <img src="/images/icons/cloud_upload-24px.svg" alt="" className="icon" />
                    Upload a file
                  </a>
                </div>
              }
            </div>

            <div className={`tab-view ${this.state.activeTab == 2 ? "active" : ""}`}>
              <div className="issue-control-wrap">
                  <textarea 
                    rows="3" 
                    placeholder="note..." 
                    value={this.state.noteComment}
                    onChange={(e) => this.handleChange("noteComment", e.target.value) } />
              </div>
              
              <span className="text-danger small">{this.state.inputErrors["noteComment"]}</span>
              
              <div className="pdt pdb-lg clearfix">
                <a href="#" className="action-btn pull-right" onClick={(e) => { e.preventDefault(); this.handleAddNote(); }}>
                  <img src="/images/icons/send-24px.svg" alt="" className="icon" />
                  Add a note
                </a>
              </div>
                    
              { this.renderNotes(plan.noteItems) }
            </div>

            <div className={`tab-view ${this.state.activeTab == 3 ? "active" : ""}`}> 
              <div className="prop-docs">
                {this.renderManagers(plan.managers)}  
              </div>
              
              <div className="pdt-lg pdb-lg clearfix">
                <a href="#" className="action-btn white pull-right" onClick={(e) => { e.preventDefault(); this.openDialogAssignManager() }}>
                  <img src="/images/icons/person_add-24px.svg" alt="" className="icon" />
                  Assign Manager
                </a>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.uploadOpen}
          onClose={() => this.closeDialogUpload()}
          disableBackdropClick={false}
          aria-labelledby="form-dialog-title">
            <div className="modal-popup sm">
              <DialogTitle
                id="form-dialog-title"
                className="text-bold">
                  Upload File
              </DialogTitle>
              <DialogContent className="pdb-lg">
                <div className="pdb-lg">
                  <label>File</label>
                  <div className="clearfix">
                    <label htmlFor="media_input" className="fu-wrap">
                      <span>{this.state.selectedFile == null ? "Browse..." : this.state.selectedFile.name}</span>
                      <input 
                        type="file" 
                        id="media_input" 
                        onChange={(e) => this.handleMediaChange(e)} />
                    </label>
                  </div>
                  <span className="text-danger small">{this.state.inputErrors.selectedFile}</span>
                </div>
                
                <div className="pdb-lg">
                  <FormControl fullWidth>
                    <InputLabel
                      error={this.state.inputStates.attachmentSubType === "error"}
                      id="attach-subtype-label">
                        Category
                    </InputLabel>
                    <Select
                      labelId="attach-subtype-label"
                      value={this.state.attachmentSubType ?? ""}
                      onChange={(e) => this.handleChange("attachmentSubType", e.target.value, true)}
                    >
                      <MenuItem key="-1" value="">
                        <em>...</em>
                      </MenuItem>
                      {
                        this.state.attachmentSubTypeOptions.map((item, index) => 
                          <MenuItem key={index} value={item.value1}>{item.value2}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                  <FormHelperText error>{this.state.inputErrors.attachmentSubType}</FormHelperText>
                </div>

                <div className="pdb-lg">
                  <TextField
                    fullWidth
                    error={this.state.inputStates.fileCaption === "error"}
                    label="Caption"
                    value={this.state.fileCaption}
                    onChange={(e) => this.handleChange("fileCaption", e.target.value, true)}
                    autoComplete= "off"
                    helperText={this.state.inputErrors.fileCaption} />
                </div>
                
                <div className="clearfix">
                  <Button
                    className="btn btn-secondary mgt pull-left"
                    onClick={() => this.closeDialogUpload()}>
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary mgt pull-right"
                    onClick={() => this.handleAddMedia()}>
                    Upload
                  </Button>
                </div>
              </DialogContent>
            </div>
        </Dialog>

        <Dialog
          open={this.state.albumOpen}
          onClose={() => this.closeDialogAlbum()}
          disableBackdropClick={false}
          aria-labelledby="form-dialog-title">
            <div className="modal-popup album">
              <DialogContent>
                  {
                    plan.mediaAssets.length > 1 &&
                    <React.Fragment>
                      <a href="#" className={`prev ${this.state.displayMedia?.category}`} onClick={(e) => { e.preventDefault(); this.handleAlbumPrev(); }}>
                        <span>
                          <img src="/images/icons/chevron_left-24px.svg" />
                        </span>
                      </a>
                      <a href="#" className={`next ${this.state.displayMedia?.category}`} onClick={(e) => { e.preventDefault(); this.handleAlbumNext(); }}>
                        <span>
                          <img src="/images/icons/chevron_right-24px.svg" />
                        </span>
                      </a>
                    </React.Fragment>
                  }
                  <div className="album-photos">
                      {
                        plan.mediaAssets.map((item, index) => {
                          const active = (this.state.displayMedia == null && index == 0) 
                            || this.state.displayMedia?.id == item.id 
                              ? "active" 
                              : "";

                          return item.file == null
                            ? (<img src="/images/noimage.png" key={index} alt="" className={active} />)
                            : item.category === "image" 
                              ? (<img src={item.file} key={index} alt="" className={active} />)
                              : (
                                  <video key={index} className={active} id={`video_${item.id}`} preload="auto" controls>
                                    <source src={item.file} type={item.mimeType} />
                                  </video>
                                )
                        })
                      }
                  </div>
              </DialogContent>
            </div>
        </Dialog>

        <Dialog
          open={this.state.clientModalOpen}
          onClose={() => this.closeDialogModalClient()}
          disableBackdropClick={false}
          aria-labelledby="form-dialog-title">
            <div className="modal-popup sm">
              <DialogTitle
                id="form-dialog-title"
                className="text-bold">
                  {this.state.displayClientRole === "owner" ? "Owner" : "Occupier"}
              </DialogTitle>
              <DialogContent>
                <div className="bordered">
                  {
                    this.state.displayClient != null &&
                    <UserPanel data={this.state.displayClient} />
                  }
                </div>
              </DialogContent>
            </div>
        </Dialog>

        <Dialog
          open={this.state.assignClientOpen}
          onClose={() => this.closeDialogAssignClient()}
          disableBackdropClick={false}
          aria-labelledby="form-dialog-title">
            <div className="modal-popup sm">
              <DialogTitle
                id="form-dialog-title"
                className="text-bold">
                  Manage Owner/Occupier
              </DialogTitle>
              <DialogContent>
                <div className="pdb-lg">
                  <FormControl fullWidth>
                    <InputLabel
                      error={this.state.inputStates.ownerIndex === "error"}
                      id="client-owner-label">
                        Owner
                    </InputLabel>
                    <Select
                      labelId="client-owner-label"
                      value={this.state.ownerIndex ?? ""}
                      onChange={(e) => this.handleChange("ownerIndex", e.target.value, true)}
                    >
                      <MenuItem key="-1" value="">
                        <em>...</em>
                      </MenuItem>
                      {
                        this.state.ownerOptions.map((item, index) => 
                          <MenuItem key={index} value={item.index}>{item.name + " - " + item.roleType.toUpperCase()}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                  <FormHelperText error>{this.state.inputErrors.ownerIndex}</FormHelperText>
                </div>

                <div className="pdb-lg">
                  <FormControl fullWidth>
                    <InputLabel
                      error={this.state.inputStates.occupierIndex === "error"}
                      id="client-occupier-label">
                        Occupier
                    </InputLabel>
                    <Select
                      labelId="client-occupier-label"
                      value={this.state.occupierIndex ?? ""}
                      onChange={(e) => this.handleChange("occupierIndex", e.target.value, true)}
                    >
                      <MenuItem key="-1" value="">
                        <em>...</em>
                      </MenuItem>
                      {
                        this.state.occupierOptions.map((item, index) => 
                          <MenuItem key={index} value={item.index}>{item.name + " - " + item.roleType.toUpperCase()}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                  <FormHelperText error>{this.state.inputErrors.occupierIndex}</FormHelperText>
                </div>
                
                <div className="clearfix">
                  <Button
                    className="btn btn-secondary mgt pull-left"
                    onClick={() => this.closeDialogAssignClient()}>
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary mgt pull-right"
                    onClick={() => this.handleAssignClient()}>
                    Save
                  </Button>
                </div>
              </DialogContent>
            </div>
        </Dialog>

        <Dialog
          open={this.state.assignManagerOpen}
          onClose={() => this.closeDialogAssignManager()}
          disableBackdropClick={false}
          aria-labelledby="form-dialog-title">
            <div className="modal-popup sm">
              <DialogTitle
                id="form-dialog-title"
                className="text-bold">
                  Assign Manager
              </DialogTitle>
              <DialogContent>
                <div className="pdb-lg">
                  <FormControl fullWidth>
                    <InputLabel
                      error={this.state.inputStates.managerIndex === "error"}
                      id="manager-user-label">
                        Manager
                    </InputLabel>
                    <Select
                      labelId="manager-user-label"
                      value={this.state.managerIndex ?? ""}
                      onChange={(e) => this.handleChange("managerIndex", e.target.value, true)}
                    >
                      <MenuItem key="-1" value="">
                        <em>...</em>
                      </MenuItem>
                      {
                        this.state.managerOptions.map((item, index) => 
                          <MenuItem key={index} value={item.index}>{ (item.roleType === "stratamanager" ? "Strata Manager" : "Property Manager") + " - " + item.name }</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                  <FormHelperText error>{this.state.inputErrors.managerIndex}</FormHelperText>
                </div>
                
                <div className="clearfix">
                  <Button
                    className="btn btn-secondary mgt pull-left"
                    onClick={() => this.closeDialogAssignManager()}>
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary mgt pull-right"
                    onClick={() => this.handleAssignManager()}>
                    Save
                  </Button>
                </div>
              </DialogContent>
            </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: config.GMAP_API_KEY
})(PropertyDetail);
