import React from "react";
import Amplify, { API, Cache, graphqlOperation } from "aws-amplify";
import * as Helpers from "libs/Helpers";
import strataApiConfig from "config-api-STRATA";
import UnauthPanel from "views/shared/UnauthPanel";
import { TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

class UserDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorized: true,
      id: this.props.match.params.id,
      user: null,
      activeTab: 1,
    };
  }

  async componentDidMount() {
    const rolesHaveAccess = ["admin", "stratamanager", "propertymanager"];
    const roleType = Cache.getItem("roleType");

    if(!rolesHaveAccess.includes(roleType)){
      this.setState({ authorized: false });
      return;
    }

    this.props.setLoading(true);
    await this.loadUser();
    this.props.setLoading(false);
  }

  loadUser = async () => {
    const user = await this.getUser();

    if(user == null){
      this.setState({ user: "INVALID" });
      this.props.setLoading(false);
      return;
    }

    this.setState({ user });
  }

  getUser = async () => {
    Amplify.configure(strataApiConfig);
    
    const params = { id: this.state.id };

    const cmd = `
      query(
        $id: ID!
      ) {
        user: getUser(
          id: $id
        ){
          id
          contact_mobile
          contact_telephone
          contact_email
          person_firstName
          person_middleName
          person_lastName
          address_name
          address_number
          address_street
          address_suburb
          address_postcode
          address_state
          personas {
            items {
              roleType
              roleSubType
              supplier_availabilityHour
              supplier_companyName
              supplier_website
              supplier_licenceExpiryAt
              actors {
                items {
                  foreignId
                  foreignType
                  plan {
                    id
                    planName
                    address_name
                    address_number
                    address_street
                    address_suburb
                    address_postcode
                    address_state
                  }
                }
              }
            }
          }
        }
      }`;

    const result = await API.graphql(graphqlOperation(cmd, params));

    const user = result.data.user;

    let data = {
      id: user.id,
      firstName: user.person_firstName,
      middleName: user.person_middleName,
      lastName: user.person_lastName,
      email: user.contact_email,
      mobile: user.contact_telephone,
      phone: user.contact_mobile,
      supplierInfo: user.personas.items.find(x => x.roleType === "supplier"),
      streetAddress: ((user.address_name ?? "") + " " + (user.address_number ?? "") + " " + (user.address_street ?? "")).trim(),
      suburb: user.address_suburb,
      state: user.address_state,
      postcode: user.address_postcode,
      personas: user.personas
    };

    console.log(data);

    return data;
  }

  renderPlanItem = (plan) => {
    return (
      <div className="prop-issue-item clearfix">
        <div className="subject">
          {plan.planName}
        </div>
        <div className="actions">
          <a href={`/plans/detail/${plan.id}`}>
            <img src="/images/icons/open_in_new-24px.svg" alt=""/>
          </a>
        </div>
      </div>
    );
  }

  renderPersonaPlans = (persona) => {
    const roleType = persona.roleType;

    if(roleType == "stratamanager" || roleType == "propertymanager") {
      const plans = persona.actors.items.filter(x => x.foreignType == "strata");

      return (
        <div className="issue-item">
          <div className="pdl pdr">
            {
              roleType == "stratamanager" 
                ? (<div className="data-label">Strata Manager - Managing Properties</div>)
                : (<div className="data-label">Property Manager - Managing Properties</div>)
            }
          </div>
          <div className="pdl pdr">
            {
              plans.map((item, index) => 
                this.renderPlanItem(item.plan)
              )
            }
          </div>
        </div>
      );
    }
    
    if(roleType == "owner" || roleType == "owneroccupier" || roleType == "tenant") {
      const plans = persona.actors.items.filter(x => x.foreignType == "lot");

      return (
        <div className="issue-item pdh">
          <div>
            {
              roleType == "owner" 
              ? (<div className="data-label">Owner</div>)
              : roleType == "owneroccupier" 
                ? (<div className="data-label">Owner Occupier</div>)
                : (<div className="data-label">Tenant</div>)
            }
          </div>
          <div>
            {
              plans.map((item, index) => 
                this.renderPlanItem(item.plan)
              )
            }
          </div>
        </div>
      );
    }
  }

  activateTab = (event, index) => {
    event.preventDefault();
    this.setState({ activeTab: index });
  };
  
  render() {
    if(this.state.authorized === false){
      return (<UnauthPanel />);
    }

    if(this.state.user == null){
      return null;
    }

    if(this.state.user == "INVALID"){
      return (<div className="pdt-lg">Invalid property</div>);
    }

    const user = this.state.user;
    console.log('user', user);
    
    return (
      <React.Fragment>
        <div className="page-title clearfix">
            <h1 className="pull-left">{user.firstName + " " + (user.middleName ?? "") + " " + user.lastName}</h1>
        </div>
        <div className="row">
          <div className="col-lg-6 issue-list col-issue-left">
            <div className="issue-item active clearfix">
              <div className="card-body pdl pdr disabled-view-only-white mg">
              <form>
                <div className="">
                  <div className="row">
                    <div className="col-sm-3 col-xs-12 pdb-lg color-grey">
                      <TextField
                        fullWidth
                        disabled={true}
                        label="First Name"
                        value={Helpers.isNullOrBlank(user.firstName, "---")}
                      />
                    </div>
                    <div className="col-sm-3 col-xs-12 pdb-lg">
                      <TextField
                        disabled={true}
                        fullWidth
                        label="Middle Name"
                        value={Helpers.isNullOrBlank(user.middleName, "---")}
                      />
                    </div>
                    <div className="col-sm-6 col-xs-12 pdb-lg">
                        <TextField
                          disabled={true}
                          fullWidth
                          label="Last Name"
                          value={Helpers.isNullOrBlank(user.lastName, "---")}
                        />
                      </div>
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-sm-6 col-xs-12 pdb-lg">
                      <TextField
                        disabled={true}
                        fullWidth
                        label="Company Name"
                        value={Helpers.isNullOrBlank(user.supplierInfo?.supplier_companyName, "---")}
                      />
                    </div>
                    <div className="col-sm-6 col-xs-12 pdb-lg">
                        <TextField
                          disabled={true}
                          fullWidth
                          label="Website"
                          value={Helpers.isNullOrBlank(user.supplierInfo?.supplier_website, "---")}
                        />
                      </div>
                  </div>
                </div>

                <div className="">
                  <div className="row">
                    <div className="col-sm-6 col-xs-12 pdb-lg">
                      <TextField
                        disabled={true}
                        fullWidth
                        label="Email"
                        value={Helpers.isNullOrBlank(user.email, "---")}
                      />
                    </div>
                    <div className="col-sm-6 col-xs-12 pdb-lg">
                        <TextField
                          disabled={true}
                          fullWidth
                          label="Main Contact"
                          value={Helpers.isNullOrBlank(user.mobile, "---")}
                        />
                      </div>
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-sm-6 col-xs-12 pdb-lg">
                      <TextField
                        disabled={true}
                        fullWidth
                        label="Mobile No."
                        value={Helpers.isNullOrBlank(user.mobile, "---")}
                      />
                    </div>
                    <div className="col-sm-6 col-xs-12 pdb-lg">
                        <TextField
                          disabled={true}
                          fullWidth
                          label="Phone No."
                          value={Helpers.isNullOrBlank(user.phone, "---")}
                        />
                      </div>
                  </div>
                </div>

                {
                  user.supplierInfo != null &&
                  <div className="">
                    <div className="row">
                      <div className="col-sm-6 col-xs-12 pdb-lg">
                        <TextField
                          disabled={true}
                          fullWidth
                          label="Avaibility Hours"
                          value={Helpers.isNullOrBlank(user.supplierInfo?.supplier_availabilityHour, "---")}
                        />
                      </div>
                    </div>
                  </div>
                }

                <div className="">
                  <div className="row">
                    <div className="col-xs-12 pdb-lg">
                      <TextField
                        disabled={true}
                        fullWidth
                        label="Street Address"
                        value={Helpers.isNullOrBlank(user.streetAddress, "---")}
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-sm-3 col-xs-12 pdb-lg color-grey">
                      <TextField
                        fullWidth
                        disabled={true}
                        label="Suburb"
                        value={Helpers.isNullOrBlank(user.suburb, "---")}
                      />
                    </div>
                    <div className="col-sm-3 col-xs-12 pdb-lg">
                      <TextField
                        disabled={true}
                        fullWidth
                        label="State"
                        value={Helpers.isNullOrBlank(user.state, "---")}
                      />
                    </div>
                    <div className="col-sm-6 col-xs-12 pdb-lg">
                        <TextField
                          disabled={true}
                          fullWidth
                          label="Postcode"
                          value={Helpers.isNullOrBlank(user.postcode, "---")}
                        />
                      </div>
                  </div>
                </div>
                {
                  user.supplierInfo != null &&
                  <div className="">
                    <div className="row">
                      <div className="col-sm-6 col-xs-12 pdb-lg">
                        <TextField
                          disabled={true}
                          fullWidth
                          label="Category"
                          value={Helpers.isNullOrBlank(user.supplierInfo.roleSubType, "---")}
                        />
                      </div>
                    </div>
                  </div>
                }
                <div className="">
                    <div className="row">
                      <div className="col-sm-6 col-xs-12 pdb-lg color-grey">
                        {
                          user.supplierInfo != null &&
                          user.supplierInfo.supplier_licenceExpiryAt == null &&
                          <TextField
                            disabled={true}
                            fullWidth
                            label="Expiry data of insurance"
                            value={"---"}
                          />
                        }
                        {
                          user.supplierInfo != null &&
                          user.supplierInfo.supplier_licenceExpiryAt != null &&
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk
                              fullWidth
                              disableToolbar
                              disabled={true}
                              label="Expiry data of insurance"
                              format="dd/MM/yyyy"
                              variant="inline"
                              value={user.supplierInfo?.supplier_licenceExpiryAt}
                              keyboardIcon={null}
                              autoComplete="off"
                            />
                          </MuiPickersUtilsProvider>
                        }
                       
                      </div>
                    </div>
                  </div>
              </form>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-issue-right">
            <div className="tabs-wrap">
              <ul className="tabs clearfix">
                <li className={this.state.activeTab == 1 ? "active" : ""}>
                  <a href="#"
                    onClick={e => {
                      this.activateTab(e, 1);
                    }}
                  >
                    <img
                      src="/images/icons/featured_play_list-24px.svg"
                      alt=""
                      className="icon"
                    />
                    Documents
                  </a>
                </li>
                <li className={this.state.activeTab == 2 ? "active" : ""}>
                  <a
                    href="#"
                    onClick={e => {
                      this.activateTab(e, 2);
                    }}
                  >
                    <img
                      src="/images/icons/star-24px.svg"
                      alt=""
                      className="icon"
                    />
                    Reviews
                  </a>
                </li>
              </ul>
            </div>

            <div
              className={`tab-view ${
                this.state.activeTab == 1 ? "active" : ""
              }`}
            >
              <div className="prop-docs">
               
              </div>
            </div>

            <div
              className={`tab-view ${
                this.state.activeTab == 2 ? "active" : ""
              }`}
            >
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserDetail;
