import React from "react";
import { Cache } from "aws-amplify";
import SidebarAdmin from './SidebarAdmin';
import SidebarStrataManager from './SidebarStrataManager';
import SidebarPropertyManager from './SidebarPropertyManager';
import SidebarOwner from './SidebarOwner';
import SidebarTenant from './SidebarTenant';
import SidebarSupplier from './SidebarSupplier';

class Sidebar extends React.Component {
  renderSidebar = props => {
    const roleType = Cache.getItem("roleType");

    if (roleType === "admin") {
      return <SidebarAdmin {...props} />;
    }
    else if (roleType === "stratamanager") {
      return <SidebarStrataManager {...props} />;
    }
    else if (roleType === "propertymanager") {
      return <SidebarPropertyManager {...props} />;
    }
    else if (roleType === "owner" || roleType === "owneroccupier") {
      return <SidebarOwner {...props} />;
    }
    else if (roleType === "tenant") {
      return <SidebarTenant {...props} />;
    }
    else if (roleType === "supplier") {
      return <SidebarSupplier {...props} />;
    }
    
    return null;
  }

  render() {
    //console.log(this.props.isLoading);

    return (
      <React.Fragment>
        <div className="sidebar">
            <a href="#" id="sidebar-toggle" onClick={(e) => { this.props.toggleSidebar(e); } }>
              <img src="/images/icons/menu-24px.svg" className="mico" alt="" />
            </a>
  
            <div className="bar" onMouseEnter={() => this.props.floatSidebar(true) } onMouseLeave={() => this.props.floatSidebar(false) }>
              {this.renderSidebar(this.props)}

              <ul className="links">
                <li><span className="text-bold">FIXXT &copy;2020</span></li>
                <li><a href="/system-status">System Status</a></li>
                <li><a href="/terms">Terms of Use</a></li>
                <li><a href="/privacy">Privacy Policy</a></li>
              </ul>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Sidebar;