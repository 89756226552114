import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Cache } from "aws-amplify";

export default ({ props: baseProps, component: Component, layout: Layout, ...rest }) => {
  //console.log(baseProps.isAuthenticated);
  const roleType = Cache.getItem("roleType");

  return (
    <Route
      {...rest}
      render={ props =>
        baseProps.isAuthenticated
          ? (roleType == null || roleType === "") 
            && props.location.pathname.toLowerCase() !== "/account/personas" 
            //&& props.location.pathname.toLowerCase() !== "/authcache"
              ? <Redirect to="/account/personas" />
              : Layout != null
                ? <Layout {...baseProps} {...rest}><Component {...baseProps} {...props} {...rest} /></Layout>
                : <Component {...baseProps} {...props} {...rest} />
          : <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />
      }
    />
  );
};
