import React from "react";

class HoldingPanel extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="holding-panel">
          <div className="row">
            <div className="col-sm-7">
              <div className="holding-desc">
                We're doing some work on this page, so come back soon.
              </div>
              <div className="pdb hidden-xs">
                Thank you for being patient.
              </div>
            </div>
            <div className="col-sm-5">
              <img src="/images/bulb.svg" className="holding-icon" alt="" />
              <div className="pdt-lg visible-xs">
                Thank you for being patient.
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default HoldingPanel;