import React from "react";
import Footer from "views/layouts/parts/Footer";

class Terms extends React.Component {
  render() {
    return (
      <div className="generic-panel-container">

        <div className="generic-panel-header">
          <div className="generic-panel-logo">
            <a href="/"><img src="/images/fixxt_logo.png" /></a>
          </div>
        </div>

        <div className="card light mgt-lg">
          <div className="card-body nopdt">
            <h1>Privacy Policy</h1>

          </div>
        </div>
        
        <Footer />

      </div>
    );
  }
}
  
export default Terms;