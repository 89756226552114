import React from "react";
import { Auth } from "aws-amplify";
import * as Helpers from "libs/Helpers";
import { TextField } from "@material-ui/core";
import LoaderButton from "views/shared/LoaderButton";
import Footer from "views/layouts/parts/Footer";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
      codeSent: false,
      confirmed: false,
      disableSubmit: false,
      inputStates: {
        email: "",
        code: "",
        password: "",
        confirmPassword: ""
      },
      inputErrors: {
        email: "",
        code: "",
        password: "",
        confirmPassword: ""
      }
    };
  }

  validateEmail = value => {
    let valid = false;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (Helpers.verifyEmail(value)) {
      valid = true;
      inputStates["email"] = "success";
      inputErrors["email"] = "";
    } else {
      valid = false;
      inputStates["email"] = "error";
      inputErrors["email"] = "Invalid email address";
    }

    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    return valid;
  }

  validateCode = value => {
    let valid = false;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (!Helpers.verifyLength(value, 1)) {
      valid = false;
      inputStates["code"] = "error";
      inputErrors["code"] = "This field is required";
    } else {
      valid = true;
      inputStates["code"] = "success";
      inputErrors["code"] = "";
    }

    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    return valid;
  }

  validatePassword = value => {
    let valid = false;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (!Helpers.verifyLength(value, 1)) {
      valid = false;
      inputStates["password"] = "error";
      inputErrors["password"] = "This field is required";
    } else {
      valid = true;
      inputStates["password"] = "success";
      inputErrors["password"] = "";
    }

    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    return valid;
  }

  validateConfirmPassword = value => {
    let valid = false;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (!Helpers.verifyLength(value, 1)) {
      valid = false;
      inputStates["confirmPassword"] = "error";
      inputErrors["confirmPassword"] = "This field is required";
    } 
    else if (value !== this.state.password) {
      valid = false;
      inputStates["confirmPassword"] = "error";
      inputErrors["confirmPassword"] = "Passwords do not match";
    } 
    else {
      valid = true;
      inputStates["confirmPassword"] = "success";
      inputErrors["confirmPassword"] = "";
    }

    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    return valid;
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });

    if (name === 'email') {
      this.validateEmail(value);
    }
    else if (name === 'password') {
      this.validatePassword(value);
    }
    else if (name === 'confirmPassword') {
      this.validateConfirmPassword(value);
    }
    else if (name === 'code') {
      this.validateCode(value);
    }
  }

  handleSendCode = async event => {
    event.preventDefault();

    if (this.state.disableSubmit) {
      return false;
    }

    // Done like this to avoid logical shortcut
    let valid = true;
    valid = this.validateEmail(this.state.email) && valid;

    if (!valid) {
      return false;
    }

    this.setState({ disableSubmit: true });

    try {
      await Auth.forgotPassword(this.state.email);
      this.setState({ codeSent: true });
    } catch (e) {
      alert(e.message);
    }

    this.setState({ disableSubmit: false });
  }

  handleConfirm = async event => {
    event.preventDefault();

    if (this.state.disableSubmit) {
      return false;
    }

    // Done like this to avoid logical shortcut
    let valid = true;
    valid = this.validateEmail(this.state.email) && valid;
    valid = this.validateCode(this.state.code) && valid;
    valid = this.validatePassword(this.state.password) && valid;
    valid = this.validateConfirmPassword(this.state.confirmPassword) && valid;

    if (!valid) {
      return false;
    }

    this.setState({ disableSubmit: true });

    try {
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.code,
        this.state.password
      );
      this.setState({ confirmed: true });
    } catch (e) {
      alert(e.message);
    }
    
    this.setState({ disableSubmit: false });
  }

  renderRequestCodeForm() {
    return (
      <form onSubmit={this.handleSendCode}>
        <div className={`text-danger text-center pdt pdb ${this.state.genericError === "hidden" ? "" : ""}`}>{this.state.genericError}</div>

        <div className="pdb-lg">
          <TextField
            fullWidth
            error={this.state.inputStates.email === "error"}
            label="Email"
            onChange = {(e) => this.handleChange("email", e.target.value)}
            autoComplete = "off"
            helperText={this.state.inputErrors.email}
          />
        </div>

        <div className="pdt text-center">
          <LoaderButton
            className="btn btn-success"
            type="submit"
            text="Send Confirmation"
            loadingText="Sending..."
            disabled={this.state.disableSubmit}
            isLoading={this.state.disableSubmit}
          />
        </div>
      </form>
    );
  }

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirm}>
        <div className={`text-danger text-center pdt pdb ${this.state.genericError === "hidden" ? "" : ""}`}>{this.state.genericError}</div>

        <div className="pdb-lg">
          <TextField
            fullWidth
            error={this.state.inputStates.code === "error"}
            label="Confirmation Code"
            onChange = {(e) => this.handleChange("code", e.target.value)}
            value={this.state.code}
            autoComplete ="off"
            helperText={this.state.inputErrors.code}
          />
          <div className="text-info small pdt-sm">
            Please check your email ({this.state.email}) for the confirmation code.
          </div>
        </div>

        <div className="pdb-lg">
          <TextField
            fullWidth
            error={this.state.inputStates.password === "error"}
            label="Password"
            onChange= {(e) => this.handleChange("password", e.target.value)}
            type= "password"
            autoComplete= "off"
            helperText={this.state.inputErrors.password}
          />
        </div>

        <div className="pdb-lg">
          <TextField
            fullWidth
            error={this.state.inputStates.confirmPassword === "error"}
            label="Password"
            onChange= {(e) => this.handleChange("confirmPassword", e.target.value)}
            type= "password"
            autoComplete= "off"
            helperText={this.state.inputErrors.confirmPassword}
          />
        </div>

        <div className="pdt text-center">
          <LoaderButton
            className="btn btn-success"
            type="submit"
            text="Confirm"
            loadingText="Submitting..."
            disabled={this.state.disableSubmit}
            isLoading={this.state.disableSubmit}
          />
        </div>
      </form>
    );
  }

  renderSuccessMessage() {
    return (
      <div className="success">
        <div className="pdt">Your password has been reset.</div>
        <div className="pdt pdb">
          <a href="/login">Click here</a> to login with your new credentials.
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="login-screen">
        <div className="login-container">

          <div className="login-header">
            <div className="login-logo">
              <a href="/"><img src="/images/fixxt_logo.png" alt="" /></a>
            </div>
            <div>Please enter details to reset your password</div>
          </div>

          <div className="card light mgt-lg">
            <div className="card-body nopdt">
              {
                this.state.confirmed
                  ? this.renderSuccessMessage()
                  : this.state.codeSent
                    ? this.renderConfirmationForm()
                    : this.renderRequestCodeForm()
              }

              <div className="pdt-lg pdb text-center">
                <a href="/login">Back to login</a>
              </div>
            </div>
          </div>
          
          <Footer />

        </div>
      </div>
    );
  }
}

export default ForgotPassword;