import React from "react";

class Logout extends React.Component {
  constructor(props) {
    super(props);

    this.props.logout();
  }
  
  render() {
    return null;
  }
}
  
export default Logout;