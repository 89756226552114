import React from "react";
import moment from 'moment';
import Amplify, { API, Cache, graphqlOperation } from "aws-amplify";
import * as Enums from "libs/Enums";
import * as Helpers from "libs/Helpers";
import MUIDataTable from "mui-datatables";
import UnauthPanel from "views/shared/UnauthPanel";

import strataApiConfig from "config-api-STRATA";

class IssueIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorized: true,
      txns: null
    };
  }

  async componentDidMount() {
    const rolesHaveAccess = ["admin", "supplier"];
    const roleType = Cache.getItem("roleType");

    if(!rolesHaveAccess.includes(roleType)){
      this.setState({ authorized: false });
      return;
    }

    await this.loadTxns();
  }

  async loadTxns() {
    //this.props.setLoading(true);
    const txns = await this.getTxns();
    // console.log('txns', txns);
    this.setState({ txns });
    //this.props.setLoading(false);
  }

  async getTxns() {
    Amplify.configure(strataApiConfig);

    let txns = [];
    
    try {
      const params = {
        txnType: "ticket"
      };

      const cmd = `
        query(
          $txnType: String!
        ) {
          txns: listTxns(
            filter: {
              txnType: { eq: $txnType }
            },
            limit: 10000
          ){
            items {
              id
              refId 
              txnType
              planId
              createdAt
              updatedAt
              ticket_subject 
              ticket_abnSearch
              ticket_amount
              ticket_description 
              ticket_statusType 
              ticket_categoryType 
              ticket_subCategoryType 
              ticket_locationType 
              ticket_priorityRank 
              ticket_impactType 
              createdUser {
                person_firstName
                person_lastName
              }
              plan {
                  id
                  planType
                  planName
              }
              machines {
                machineState {
                    machineType
                    stateType
                    ignoreStream
                    updatedBy
                    updatedRoleType
                    updatedAt
                    transitionActionType
                    description
                    behaviorType
                }
                machineStates {
                    machineType
                    stateType
                    ignoreStream
                    updatedBy
                    updatedRoleType
                    updatedAt
                    transitionActionType
                    description
                    behaviorType
                }
              }
              actors {
                userId
                roleType
                foreignType
              }
              childTxns{
                id
                refId
                txnType
                createdBy
                quote_isSelected
                quote_amount
                machines {
                  machineState {
                      machineType
                      stateType
                  }
                }
              }
            }
          }
        }`;

      const result = await API.graphql(graphqlOperation(cmd, params));

      // console.log(result);
      if (result.data != null && result.data.txns.items.length > 0) { 
        txns = result.data.txns.items;
      }
    } catch (e) {
      console.log(e);
      //alert(JSON.stringify(e));
      if (e.hasOwnProperty("data") && e.data != null) {
        txns = e.data.txns.items;
      }
    }

    const userId = Cache.getItem("email");
    const roleType = Cache.getItem("roleType");
    // const planId = Cache.getItem("planId");

    if (roleType !== "admin") {
      txns = txns.filter(x =>
        x.actors.find(y => y.foreignType === "ticket")
        // && x.planId == planId
      );
    }

    txns = txns.filter(x =>
        x.machines[0] != null 
        && x.machines[0].machineState != null
        // && x.machines[0].machineState.stateType === "quote"
        && x.actors.find(y => y.userId === userId && y.foreignType === "ticket")
        && x.childTxns.find(y => y.createdBy === userId && y.quote_isSelected === true && y.txnType === "quote")
      );

    txns = txns.sort((a,b) => (a.createdAt > b.createdAt) ? -1 : ((b.createdAt > a.createdAt) ? 1 : 0));
    
    console.log('txns', txns);

    return txns.map((x) => {
      const machine = x.machines[0];
      const machineState = machine == null ? null : machine.machineState;
      const submittedQuote = x.childTxns[0];
      console.log('x.quotes[0]', x.quotes);

      return {
        id: x.id.trim().split("\n")[0],
        // refId: x.refId,
        refId: submittedQuote.refId,
        subject: x.ticket_subject,
        description: x.ticket_description,
        property: x.plan?.planName ?? "",
        area: x.ticket_locationType,
        category: x.ticket_categoryType,
        priority: Enums.getPriorityLabel(x.ticket_priorityRank),
        estimate: submittedQuote?.quote_amount,
        issueDate: x.createdAt,
        issuedBy: x.createdUser == null ? "---" : (x.createdUser.person_firstName ?? "") + " " + (x.createdUser.person_lastName ?? ""),
        statusLabel:  machineState == null ? "---" : (Enums.getIssueStateStatusLabel(machineState.stateType) ?? "").toUpperCase(),
        statusDesc: machineState == null ? "---" : machineState.description,
        statusUpdatedAt: machineState == null ? null : machineState.updatedAt
      };
    }); 
  }

  render() {
    if(this.state.authorized === false){
      return (<UnauthPanel />);
    }

    const columns = [
      {
       name: "id",
       label: "---",
       options: {
        display: 'excluded',
        filter: false,
        sort: false
       }
      },
      {
       name: "planId",
       label: "---",
       options: {
        display: 'excluded',
        filter: false,
        sort: false
       }
      },
      {
       name: "refId",
       label: "ID",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `ID: ${v}` },
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a href={`/issues/detail/${tableMeta.rowData[0]}`}>{value}</a>
          );
        }
       }
      },
      {
       name: "subject",
       label: "Subject",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Subject: ${v}` },
        filterType: "textField"
       }
      },
      {
       name: "property",
       label: "Property",
       options: {
        filter: false,
        sort: true,
        customFilterListOptions: { render: v => `Property: ${v}` },
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   const planId = tableMeta.rowData[1];

        //   if(planId == null)
        //     return "";

        //   return (
        //     <a href={`/issues/list/pending/${planId}`}>{value}</a>
        //   );
        // }
       }
      },
      {
       name: "area",
       label: "Area",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Area: ${v}` }
       }
      },
      {
       name: "category",
       label: "Category",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Category: ${v}` }
       }
      },
      {
       name: "statusLabel",
       label: "Status",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Status: ${v}` },
        filterType: "multiselect"
       }
      },
      {
       name: "priority",
       label: "Priority",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Priority: ${v}` }
       }
      },
      {
       name: "issueDate",
       label: "Issue Date",
       options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(new Date(value)).format("DD MMM YYYY");
        }
       }
      },
      {
       name: "statusUpdatedAt",
       label: "Last Update",
       options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(new Date(value)).format("DD MMM YYYY");
        }
       }
      },
      {
       name: "estimate",
       label: "Estimate",
       options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return Helpers.formatCurrency(value);
        }
       }
      }
     ];

    const options = {
      filterType: "dropdown",
      selectableRows: false,
      responsive: "stacked",
      elevation: 0,
      rowHover: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10,50,100],
      searchOpen: false,
      print: false,
      download: false,
      viewColumns: false
    };

    return (
      <React.Fragment>
        <div className="page-title">
          <h1>My jobs</h1>
        </div>
        <div className="card">
          <div className="card-body nopdt">
            {
              this.state.txns == null 
              ? <div className="pdt-lg pdb-sm text-center"><img src="/images/loading.svg" alt="" /></div>
              : <MUIDataTable
                  data={this.state.txns}
                  columns={columns}
                  options={options}
                />
            }
          </div>
        </div>
      </React.Fragment>      
    );
  }
}

export default IssueIndex;
