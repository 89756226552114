import React from "react";
import Amplify, { API, Cache, graphqlOperation } from "aws-amplify";
import strataApiConfig from "config-api-STRATA";

class Own extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      parentId: this.props.match.params.id,
      expandUser: null,
      plans: []
    };
  }

  async componentDidMount() {
    await this.loadPlans();
    //console.log(Cache.getItem("roleType"));
  }

  async loadPlans() {
    this.props.setLoading(true);
    const plans = await this.getPlans();
    console.log(plans);
    this.setState({ plans });
    this.props.setLoading(false);
  }

  getPlans = async () => {
    Amplify.configure(strataApiConfig);

    const userId = Cache.getItem("email");
    const roleType = Cache.getItem("roleType");

    const params = { 
      userId: userId, 
      roleType: roleType, 
      foreignType: "strata" 
    };

    const cmd = `
      query (
        $userId: String
        $roleType: String
        $foreignType: String
      ) {
        actors: listActors(
            filter: {
                userId: { eq: $userId }
                roleType: { eq: $roleType }
                foreignType: { eq: $foreignType }
            }
            limit: 1000
        ) {
            items {
                id
                userId
                roleType
                foreignType
                foreignId
                plan {
                  id
                  description
                  planName
                  address_name
                  address_number
                  address_street
                  address_suburb
                  address_postcode
                  address_state
                }
            }
          }
      }`;

    console.log(cmd);

    const response = await API.graphql(graphqlOperation(cmd, params));

    console.log(response);

    let plans = response.data.actors.items;
    plans = plans.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1); 

    return plans.map((data) => {
      const plan = data.plan;

      return {
        id: plan.id,
        name: plan.planName,
        description: plan.description,
        streetAddress: plan.address_number + " " + plan.address_street,
        suburb: plan.address_suburb,
        postcode: plan.address_postcode,
        state: plan.address_state
      };
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-title">
          <h1>{ Cache.getItem("roleType") === "tenant" ? "My Place" : "My Places" }</h1>
        </div>

        <div className="row mgt">
          
          {
            this.state.plans.map((plan, index) => 
              <div className="col-lg-3 col-md-4 col-sm-6 pdb-lg">
                <a className="card dasboard" href={`/plans/detail/${plan.id}`}>
                  <div className="icon">
                    <img src="/images/icons/business-24px.svg" alt="" />
                  </div>
                  <div className="card-body">
                    <div className="dash-item-title nomgb">
                      <div>{plan.streetAddress}</div>
                      <div className="pdt-sm">{plan.suburb}</div>
                    </div>
                  </div>
                  <div className="dash-line">
                    {plan.name}
                  </div>
                </a>
              </div>
            )
          }

        </div>
      </React.Fragment>
    );
  }
}

export default Own;