import React from "react";
import { Route, Switch } from 'react-router-dom';
import AnonymousRoute from 'routes/AnonymousRoute';
import AuthorizedRoute from 'routes/AuthorizedRoute';
import LayoutPrivate from 'views/layouts/Private';
import LayoutPublic from 'views/layouts/Public';
import LayoutFluid from 'views/layouts/Fluid';
import PageIndex from 'views/Index';
import PageTerms from 'views/page/Terms';
import PagePrivacy from 'views/page/Privacy';
import PageSystemStatus from 'views/page/SystemStatus';
import PageLogin from 'views/account/Login';
import PageLogout from 'views/account/Logout';
import PageForgotPassword from 'views/account/ForgotPassword';
import PageAccountPersonas from 'views/account/Personas';
import PageAccountPlans from 'views/account/Plans';
import PageProfile from 'views/account/Profile';
import PageDashboard from 'views/dashboard/Index';
import PageIssuesIndex from 'views/issue/Index';
import PageIssuesList from 'views/issue/List';
import PageIssueDetail from 'views/issue/Detail';
import PageIssueCreate from 'views/issue/Create';
import PageIssueEdit from 'views/issue/Edit';
import PagePlanIndex from 'views/plan/Index';
import PagePlanLots from 'views/plan/Lots';
import PagePlanDetail from 'views/plan/Detail';
import PagePlanEdit from 'views/plan/Edit';
import PagePlanOwn from 'views/plan/Own';
import PagePlanImport from 'views/plan/Import';
import PageQuoteRequests from 'views/quote/Requests';
import PageQuoteWorkOrders from 'views/quote/WorkOrders';
import PageQuoteDetail from 'views/quote/Detail';
import PageUserIndex from 'views/user/Index';
import PageUserDetail from 'views/user/Detail';
import PageSettings from 'views/system/Settings';
import PageAuthCache from 'views/system/AuthCache';
import PageReports from 'views/report/Index';
import PageConcierge from 'views/concierge/Index';
import PageAnnouncements from 'views/announcement/Index';
import PageCalendar from 'views/calendar/Index';
import PageDocuments from 'views/document/Index';
import PageFacilities from 'views/facility/Index';
import PageMaintenance from 'views/maintenance/Index';
import PageHelp from 'views/help/Index';
import PageError404 from 'views/error/Error404';

export default ({props}) => {
  return(
    <Switch>
      <AnonymousRoute exact path="/" props={props} component={PageIndex} layout={LayoutFluid} />
      <AnonymousRoute exact path="/terms" props={props} component={PageTerms} layout={LayoutPublic} />
      <AnonymousRoute exact path="/privacy" props={props} component={PagePrivacy} layout={LayoutPublic} />
      <AnonymousRoute exact path="/system-status" props={props} component={PageSystemStatus} layout={LayoutPublic} />
      <AnonymousRoute exact path="/login" props={props} component={PageLogin} layout={LayoutFluid} />
      <AnonymousRoute exact path="/logout" props={props} component={PageLogout}/>
      <AnonymousRoute exact path="/forgot-password" props={props} component={PageForgotPassword} layout={LayoutFluid} />
      <AuthorizedRoute exact path="/account/personas" props={props} component={PageAccountPersonas} layout={LayoutFluid} />
      <AuthorizedRoute exact path="/account/plans" props={props} component={PageAccountPlans} layout={LayoutFluid} />
      <AuthorizedRoute exact path="/profile" props={props} component={PageProfile} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/dashboard" props={props} component={PageDashboard} layout={LayoutPrivate} skyline="true" />
      <AuthorizedRoute exact path="/issues/pending" props={props} status="PENDING" component={PageIssuesIndex} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/issues/resolved" props={props} status="RESOLVED" component={PageIssuesIndex} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/issues/list/pending" props={props} status="PENDING" component={PageIssuesList} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/issues/list/pending/:id" props={props} status="PENDING" component={PageIssuesList} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/issues/list/resolved" props={props} status="RESOLVED" component={PageIssuesList} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/issues/list/resolved/:id" props={props} status="RESOLVED" component={PageIssuesList} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/issues/list/store/:id" props={props} status="ALL" component={PageIssuesList} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/issues/list" props={props} status="ALL" component={PageIssuesList} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/issues/detail/:id" props={props} component={PageIssueDetail} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/issues/create/:id" props={props} component={PageIssueCreate} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/issues/create" props={props} component={PageIssueCreate} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/issues/edit/:id" props={props} component={PageIssueEdit} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/issues" props={props} status="ALL" component={PageIssuesIndex} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/plans/lots/:id" props={props} component={PagePlanLots} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/plans/detail/:id" props={props} component={PagePlanDetail} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/plans/edit/:id" props={props} component={PagePlanEdit} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/plans/own" props={props} component={PagePlanOwn} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/plans/import" props={props} component={PagePlanImport} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/plans" props={props} component={PagePlanIndex} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/quotes/requests" props={props} status="ALL" component={PageQuoteRequests} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/quotes/workorders" props={props} status="ALL" component={PageQuoteWorkOrders} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/quotes/detail/:id" props={props} status="ALL" component={PageQuoteDetail} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/admins" props={props} type="ADMIN" component={PageUserIndex} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/strata-managers" props={props} type="STRATA_MANAGER" component={PageUserIndex} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/property-managers" props={props} type="PROPERTY_MANAGER" component={PageUserIndex} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/clients" props={props} type="CLIENT" component={PageUserIndex} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/suppliers" props={props} type="SUPPLIER" component={PageUserIndex} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/users/detail/:id" props={props} component={PageUserDetail} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/settings" props={props} component={PageSettings} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/authcache" props={props} component={PageAuthCache} layout={LayoutPublic} />
      <AuthorizedRoute exact path="/reports" props={props} component={PageReports} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/concierge" props={props} component={PageConcierge} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/announcements" props={props} component={PageAnnouncements} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/calendar" props={props} component={PageCalendar} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/documents" props={props} component={PageDocuments} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/facilities" props={props} component={PageFacilities} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/maintenance" props={props} component={PageMaintenance} layout={LayoutPrivate} />
      <AuthorizedRoute exact path="/help" props={props} component={PageHelp} layout={LayoutPrivate} />
      { /* Finally, catch all unmatched routes */ }
      <AnonymousRoute component={PageError404} layout={LayoutPublic} />
    </Switch>
  );
};
