import React from "react";
import HoldingPanel from 'views/shared/HoldingPanel';

class HelpIndex extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-title">
          <h1>Help Centre</h1>
        </div>
        <HoldingPanel />
      </React.Fragment>
    );
  }
}

export default HelpIndex;