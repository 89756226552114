import React from "react";
import { Cache } from "aws-amplify";
import * as Utils from "libs/Utils";
import config from "config";
import { InputLabel, MenuItem, FormControl, Select } from "@material-ui/core";
import LoaderButton from "views/shared/LoaderButton";
import Footer from "views/layouts/parts/Footer";


class Plans extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      planId: Cache.getItem("planId"),
      disableSubmit: false,
      planOptions: null,
      inputStates: {
        planId: ""
      },
      inputErrors: {
        planId: ""
      }
    };
  }

  async componentDidMount() { 
    try {
      const userId = Cache.getItem("email");
      const roleType = Cache.getItem("roleType");

      const plans = await Utils.getPlans(userId, roleType);
  
      console.log(plans);

      this.setState({ planOptions: plans });
    } catch (e) {
      alert("ERROR: " + JSON.stringify(e));
    }
  }

  validatePlan = () => {
    const name = "planId";
    let valid = false;
    const value = this.state[name];
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (value == null || value === "") {
      inputStates[name] = "error";
      inputErrors[name] = "This field is required";
    } else {
      valid = true;
      inputStates[name] = "success";
      inputErrors[name] = "";
    }
    
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    return valid;
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });

    if (name == 'planId') {
      this.validatePlan();
    }
  }

  handleSubmit = async event => {
    event.preventDefault();

    if (this.state.disableSubmit) {
      return false;
    }

    let valid = this.validatePlan();

    //console.log(valid);

    if (!valid) {
      //alert('Please fix the errors');
      return false;
    }

    //this.props.setLoading(true);
    this.setState({ disableSubmit: true });

    try {
      const planId = this.state.planId;
      const selectedPlan = this.state.planOptions.filter(function(x) { return x.id == planId })[0];

      //console.log(selectedPlan);

      if(selectedPlan != null) {
        const now = new Date();
        const expiry = now.setMinutes(now.getMinutes() + config.AUTH_SESSION_DURATION_MINUTES);

        await Cache.setItem("planId", selectedPlan.id, { expires: expiry });
        await Cache.setItem("planName", selectedPlan.planName, { expires: expiry });
      }

      this.props.redirect("/dashboard");
    } catch (e) {
      this.setState({ disableSubmit: false });
      console.error(e);
      alert("ERROR: " + JSON.stringify(e));
    }
  }

  renderPanel = () => {
    if (this.state.planOptions == null) {
      return null;
    }

    if (this.state.planOptions.length == 0) {
      return (
        <React.Fragment>
          <div className="pdt text-center">
            Your account does not have any plans.
          </div>

          <div className="pdt-lg pdb text-center">
            <a href="/logout">Logout</a>
          </div>
        </React.Fragment>);
    }
    
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>

          <div className="pdb-sm">
            <FormControl fullWidth>
              <InputLabel id="role-label">Choose property</InputLabel>
              <Select
                labelId="role-label"
                value={this.state.planId ?? ""}
                onChange={(e) => this.handleChange("planId", e.target.value)} 
              >
                {
                  this.state.planId == null &&
                  <MenuItem key="-1" value="">
                    <em>...</em>
                  </MenuItem>
                }
                {
                  this.state.planOptions.map((plan, index) => 
                    <MenuItem key={index} value={plan.id}>{plan.planName}</MenuItem>)
                }
              </Select>
            </FormControl>
            <span className="text-danger">{this.state.inputErrors["planId"]}</span>
          </div>

          <div className="pdt-lg text-center">
            <LoaderButton
              className="btn btn-success"
              type="submit"
              text="Select"
              loadingText="Processing..."
              disabled={this.state.disableSubmit}
              isLoading={this.state.disableSubmit}
            />
          </div>

          <div className="pdt-lg pdb text-center">
            <a href="/dashboard">Back to Dashboard</a>
          </div>

        </form>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div id="login-screen">
        <div className="login-container">

          <div className="login-header">
            <div className="login-logo">
              <a href="/"><img src="/images/fixxt_logo.png" /></a>
            </div>
            <div>Which plan you would like to visit?</div>
          </div>

          <div className="card light mgt-lg">
            <div className="card-body">

              <div className="login-form">
                {this.renderPanel()}
              </div>

            </div>
          </div>
          
          <Footer />

        </div>
      </div>
    );
  }
}

export default Plans;