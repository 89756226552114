import React from "react";

class ManagerItem extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <React.Fragment>
        <div className="prop-doc-item clearfix">
          <a href="#" className="doc-act" onClick={(e) => { e.preventDefault(); this.props.handleDeleteManager(e) }}>
            <img src="/images/icons/delete-24px.svg" alt="" />
          </a>
          <a href={`/users/detail/${data.userId}`} target="_blank" className="doc-name">
            {data.roleType === "stratamanager" ? "Strata Manager - " : "Property Manager - " }
            {data.user.person_firstName + " " + data.user.person_lastName}
          </a>
        </div>
      </React.Fragment>
    )
  }
}

export default ManagerItem;