import React from "react";
import { TextField, Button, Dialog, DialogTitle, DialogContent } from "@material-ui/core";

class CreateStep3 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="pdb-lg">
          <TextField
            fullWidth
            error={this.props.inputStates.subject === "error"}
            label="Subject*"
            id="subject"
            value= {this.props.subject}
            onChange= {(e) => this.props.handleChange("subject", e.target.value, true)}
            autoComplete= "off"
            helperText={this.props.inputErrors.subject}
          />
        </div>

        <div className="pdb-lg mgb-lg">
          <TextField
            fullWidth
            error={this.props.inputStates.description === "error"}
            label="Issue description*"
            id="description"
            placeholder="Please enter the description of your issue"
            multiline={true}
            rows={4}
            rowsMax={4}
            value={this.props.description}
            onChange={(e) => this.props.handleChange("description", e.target.value, true)}
            helperText={this.props.inputErrors.description}
          />
        </div>

        <div className={`pdt-lg issue-media-preview ${this.props.media.length == 0 ? "hidden" : ""}`}>
          <div className="text-bold pdb">Attached Files</div>
          {
            this.props.media.map((item, index) => 
              <div className="preview-item clearfix" key={index}>
                <a href="#" className="delete" onClick={(e) => this.props.removeMedia(e, index)}>
                  <img src="/images/icons/delete-24px.svg" alt=""/>
                </a>
                {
                  item.category == "image"
                  ? <img src={item.previewURL} className="photo" alt="" />
                  : item.category == "video" || item.category == "audio"
                    ? <img src="/images/icons/movie-24px.svg" className="photo video" alt=""/>
                    : <img src="/images/icons/featured_play_list-24px.svg" alt="" className="photo doc" />
                }
                <div className="caption">{item.caption}</div>
              </div>
            )
          }
        </div>
        
        <div className="pdb-lg">
          <label htmlFor="raised-button-file"> 
            <Button
              component="span"
              className="btn btn-secondary solid"
              onClick={() => this.props.openDialog()}>
                Attach Media
            </Button> 
          </label>
        </div>

        <Dialog
            open={this.props.modalOpen}
            onClose={() => this.props.closeDialog()}
            disableBackdropClick={false}
            aria-labelledby="form-dialog-title">
              <div className="modal-popup sm">
                <DialogTitle
                  id="form-dialog-title"
                  className="text-bold">
                    Attach Media
                </DialogTitle>
                <DialogContent className="pdb-lg">
                  <div className="pdb-lg">
                    <label>File*</label>
                    <div className="clearfix">
                      <label htmlFor="media_input" className="fu-wrap">
                        <span>{this.props.selectedFile == null ? "Browse..." : this.props.selectedFile.name}</span>
                        <input 
                          type="file" 
                          id="media_input" 
                          onChange={(e) => this.props.handleMediaChange(e)} />
                      </label>
                    </div>
                    <span className="text-danger small">{this.props.inputErrors.selectedFile}</span>
                  </div>
                  
                  <div className="pdb-lg">
                    <TextField
                      fullWidth
                      error={this.props.inputStates.fileCaption === "error"}
                      label="Caption*"
                      value={this.props.fileCaption}
                      onChange={(e) => this.props.handleChange("fileCaption", e.target.value, true)}
                      autoComplete= "off"
                      helperText={this.props.inputErrors.fileCaption} />
                  </div>
                  
                  <div className="clearfix">
                    <Button
                      className="btn btn-secondary mgt pull-left"
                      onClick={() => this.props.closeDialog()}>
                      Cancel
                    </Button>
                    <Button
                      className="btn btn-primary mgt pull-right"
                      onClick={() => this.props.attachMedia()}>
                      Attach
                    </Button>
                  </div>
                </DialogContent>
              </div>
          </Dialog>
      </React.Fragment>
    );
  }
}

export default CreateStep3;