import React from "react";
import * as Helpers from "libs/Helpers";

class QuoteListItem extends React.Component {
  render() {
    const data = this.props.data;
    return (
      <React.Fragment>
        <div className="prop-doc-item clearfix">
            <div className="row">
              <div className="col-sm-6">
                  <span className="pull-left">
                    {data.persona.supplier_companyName ?? (data.persona.user.person_firstName + " " + data.persona.user.person_lastName)}
                  </span>
              </div>
              <div className="col-sm-6">
                  <span className="pull-left ">Typical Availability</span>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <span className="pull-left text-bold">
                  {
                    data.quoteSubmitted 
                      ? "Quoted " + (data.quoteAmount == null ? "" : Helpers.formatCurrencyNoFractions(data.quoteAmount)) 
                      : data.raci_isResponsible 
                        ? "Quote Requested" 
                        : ""
                  }
                </span>
              </div>
              <div className="col-sm-6">
                  <span className="pull-left text-bold">
                  {
                    data.persona.supplier_availabilityHour != null 
                      ? data.persona.supplier_availabilityHour + (data.persona.supplier_availabilityHour == 1 ? " hour" : " hours")
                      : "---"
                  }
              </span>
              </div>
            </div>
        </div>
      </React.Fragment>
    )
  }
}

export default QuoteListItem;