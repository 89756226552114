import React from "react";
import Amplify, { API, Cache, graphqlOperation } from "aws-amplify";
import * as Helpers from "libs/Helpers";
import strataApiConfig from "config-api-STRATA";
import UnauthPanel from "views/shared/UnauthPanel";

class PlanImport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorized: true,
      processed: false,
      selectedFile: null,
      dataKeys: null,
      dataTypes: null,
      dataRows: null,
      inputStates: {
        selectedFile: ""
      },
      inputErrors: {
        selectedFile: ""
      }
    };
  }

  async componentDidMount() {
    const rolesHaveAccess = ["admin"];
    const roleType = Cache.getItem("roleType");

    if(!rolesHaveAccess.includes(roleType)){
      this.setState({ authorized: false });
      return;
    }
  }

  handleFileChange = async (event) => {
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (event.target.files.length === 0) {
      inputStates["selectedFile"] = "";
      inputErrors["selectedFile"] = "";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      this.setState({selectedFile: null});
      this.setState({ dataKeys: null, dataRows: null });
      return;
    } 
    
    var file = event.target.files[0];    

    const ext = Helpers.getFileExtension(file.name).toLowerCase();

    if (ext !== "csv") {
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = "Please select a csv file";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    inputStates["selectedFile"] = "success";
    inputErrors["selectedFile"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });
    
    this.setState({selectedFile: file});

    this.props.setLoading(true);
    await this.previewFile(file);
    this.props.setLoading(false);
  }

  handleImport = async (event) => {
    const dataRows = this.state.dataRows;
    const errors = dataRows == null ? 0 : dataRows.filter(x => x.status !== "ok").length;

    if(errors > 0){
      alert("There are errors in the data file. Please fix those and then try again.")
      return;
    }

    this.props.setLoading(true);

    dataRows.forEach(async (item) => {
      try {
        await this.createPlan(item);
      }
      catch(e) {
        console.error(e);
        item.status = "error";
        item.errors.push("Server side error");
        //item.errors = e.errors.map(x => x.message);
      }
    });

    this.setState({ processed: true });

    this.props.setLoading(false);
  }

  previewFile = async (file) => {
    var reader = new FileReader();

    reader.onload = async (e) => {
      // Use reader.result
      const lines = reader.result.trim().split(/\r\n|\n/);
      let dataKeys = lines[0].split(",");
      let dataTypes = lines[1].split(",");

      let importingPlanIds =[];
      let dataRows = [];

      let rows = lines.splice(2).map((e) => {
        return e.split(",");
      });

      var existingPlanIds = await this.getPlanIds();

      //console.log('rows', rows);

      rows.forEach(row => {
        let item = {};
        let errors = [];

        dataKeys.forEach((key, index) => {
          item[key] = row[index];
        });

        if(item.id === "") 
          errors.push("id is required");

        if(existingPlanIds.includes(item.id)) 
          errors.push("id already exists");

        if(importingPlanIds.includes(item.id)) 
          errors.push("duplicate id in the file");

        if(item.planType === "") 
          errors.push("planType is required");

        if(item.planType === "lot" && item.parentId === "") 
          errors.push("Lot must have a parent plan");

        if(item.planName === "") 
          errors.push("planName is required");

        item["status"] = errors.length === 0 ? "ok" : "fault";
        item["errors"] = errors;

        dataRows.push(item);
        importingPlanIds.push(item.id);
      });

      let validParentIds = importingPlanIds.concat(existingPlanIds);
      //console.log('validParentIds', validParentIds);

      // Check parent ID
      dataRows.forEach(item => {
        if(item.parentId !== "" && !validParentIds.includes(item.parentId)) 
          item.errors.push("invalid parentId");
      });

      this.setState({ dataKeys: dataKeys, dataTypes: dataTypes, dataRows: dataRows });
      //console.log('dataKeys', dataKeys);
      //console.log('dataRows', dataRows);
    }

    reader.readAsText(file);
  }

  createPlan = (data) => {
    Amplify.configure(strataApiConfig);

    //const ignoreFields = ["status", "errors"];
    const dataKeys = this.state.dataKeys;
    const dataTypes = this.state.dataTypes;
    let cmdFields = "";

    dataKeys.forEach((x, index) => {
      if(dataTypes[index].toLowerCase() === "int"){
        cmdFields += data[x] == "" ? "" : ("\n" + x + ": " + data[x]);
      }
      else{
        cmdFields += data[x] == "" ? "" : ("\n" + x + ": \"" + data[x] + "\"");
      }
    });

    const cmd = `
      mutation {
        createPlan(input: {
          ${cmdFields}
          createdBy: "${Cache.getItem("email")}"
        }) {
          id
        }
      }`;

    //console.log(cmd);

    return API.graphql(graphqlOperation(cmd));
  }

  async getPlanIds() {
    Amplify.configure(strataApiConfig);

    let plans = [];
    
    try {
      const params = { };

      const cmd = `
        query {
          plans: listPlans(
            limit: 10000
          ){
            items {
              id
            }
          }
        }`;

      const result = await API.graphql(graphqlOperation(cmd, params));

      //console.log(result);

      if (result.data != null && result.data.plans.items.length > 0) { 
        plans = result.data.plans.items; 
      }
    } catch (e) {
      console.log(e);
      //alert(JSON.stringify(e));
      if (e.hasOwnProperty("data") && e.data != null && e.data.plans != null) {
        plans = e.data.plans.items;
      }
    }

    plans = plans.map(item => {
      return item.id
    })

    return plans;
  }

  renderTableHeader() {
    let header = this.state.dataKeys;

    return (
      <tr>
        <th key="-1">&nbsp;</th>
        {
          header.map((key, index) => {
            return key === "status" || key === "errors" 
              ? null 
              : <th key={index}>{key}</th>
          })
        }
      </tr>
    )
  }

  renderTableData() {
    return this.state.dataRows.map((row, index) => {
      var dataKeys = this.state.dataKeys;
      //return null;

      return (
        <tr key={index} >
          <td key="-1" className={ row.status }>
            {
              row.status === "ok"
              ? <span>OK</span>
              : <span title={row.errors.join(", ")}>ERROR</span>
            }
          </td>
          {
            dataKeys.map((key, index) => {
              return <td key={index}>{row[key]}</td>
            })
          }
        </tr>
      );
    })
  }

  render() {
    if(this.state.authorized === false){
      return (<UnauthPanel />);
    }

    var rows = this.state.dataRows == null ? 0 : this.state.dataRows.length;
    var errors = this.state.dataRows == null ? 0 : this.state.dataRows.filter(x => x.status !== "ok").length;

    return (
      <React.Fragment>
        <div className="page-title">
          <h1>Import Properties</h1>
        </div>
        <div className="card">
          <div className="card-body nopdt">
            {
              this.state.processed 
              ? <div className="pdt pdb">The import has been done.</div>
              : <div className="pdt pdb clearfix">
                  <div className="pdb-lg">
                    <label>1. <a href="/files/plan-Data.csv">Download</a> the import CSV format</label>
                  </div>
                  <div className="pdb-lg">
                    <label>2. Select the CSV file containing properties</label>
                    <div className="clearfix pdt-10">
                      <label htmlFor="csv_upload" className="fu-wrap inline">
                        <span>{this.state.selectedFile == null ? "Browse..." : this.state.selectedFile.name}</span>
                        <input
                          type="file"
                          id="csv_upload"
                          onChange={(e) => this.handleFileChange(e)} />
                      </label>
                      <div>
                        <span className="text-danger small">{this.state.inputErrors.selectedFile}</span>
                      </div>
                    </div>
                  </div>
                  {
                    rows > 0 &&
                    <div className="clearfix">
                      <div className="pdb-sm">
                        <label>3. Review and run the import process</label>
                      </div>
                      <div className="clearfix">
                        <div className="pull-right pdt">
                          <span className="mgl">
                            { rows === 1 ? (rows + " Row") : (rows + " Rows") }
                          </span>
                          <span className="mgl">|</span>
                          <span className={`mgl ${errors === 0 ? "" : "text-danger"}`}>
                            { errors === 0 ? "No Errors" : errors === 1 ? (errors + " Error") : (errors + " Errors") }
                          </span>
                        </div>
                        {
                          errors === 0
                          ? <button type="button" className="btn btn-success" onClick={(e) => this.handleImport()}>Import</button>
                          : <button type="button" className="btn btn-secondary">Import</button>
                        }
                      </div>
                    </div>
                  }
                </div>
            }

            {
              this.state.dataKeys != null &&
              <div className="pdb">
                <div className="table-import-wrap">
                  <table className="table-bordered table-import">
                    <thead>
                      {this.renderTableHeader()}
                    </thead>
                    <tbody>
                      {this.renderTableData()}
                    </tbody>
                  </table>
                </div>
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PlanImport;
