import React from "react";

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) =>
  <button
    className={`loader-button ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading && <img src="/images/processing.svg" alt="" />}
    {!isLoading ? text : loadingText}
  </button>;
