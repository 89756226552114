import React from "react";
import { Cache, Auth,Storage } from "aws-amplify";

class AuthCache extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      image: null
    };
  }

  async componentDidMount() {
    const image = await Storage.get("plan/8120/7acd9a1c-1342-4ede-907f-55b20de6c544_648af098-199a-4a97-8ff5-3a470e4832bb.jpg-thumbnail.jpg");

    this.setState({ image: image });
  }

  render() {
    console.log(Auth);

    return (
      <React.Fragment>
        <div className="page-title">
          <h1>Cache</h1>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="pdb">Email: {Cache.getItem("email")}</div>
            <div className="pdb">Role: {Cache.getItem("roleType")}</div>
            <div className="pdb">name: {Cache.getItem("name")}</div>
            <div className="pdb">Personas: {Cache.getItem("personas")}</div>
            <div className="pdb">Plans: {Cache.getItem("plans")}</div>
            <div className="pdb">Plan ID: {Cache.getItem("planId")}</div>
            <div className="pdb">Plan Name: {Cache.getItem("planName")}</div>
            <img src={this.state.image} alt=""/>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AuthCache;