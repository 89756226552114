import React from "react";
import Amplify, { API, Cache, graphqlOperation } from "aws-amplify";
import * as Enums from "libs/Enums";
import * as Helpers from "libs/Helpers";
import * as Utils from "libs/Utils";
import strataApiConfig from "config-api-STRATA";
import MUIDataTable from "mui-datatables";
import UnauthPanel from "views/shared/UnauthPanel";

class UserIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorized: true,
      type: this.props.type,
      users: null,
      downloadFileName: ""
    };
  }

  async componentDidMount() {
    const rolesHaveAccess = ["admin", "stratamanager", "propertymanager"];
    const roleType = Cache.getItem("roleType");

    if(!rolesHaveAccess.includes(roleType)){
      this.setState({ authorized: false });
      return;
    }

    if(this.state.type === "ADMIN") {
      this.setState({ authorized: (roleType == "admin") });
    }
    else if(this.state.type === "STRATA_MANAGER") {
      this.setState({ authorized: (roleType == "admin") });
    }
    else if(this.state.type === "PROPERTY_MANAGER") {
      this.setState({ authorized: (roleType == "admin" || roleType == "stratamanager") });
    }
    else if(this.state.type === "SUPPLIER") {
      this.setState({ authorized: (roleType == "admin" || roleType == "stratamanager" || roleType == "propertymanager") });
    }
    else if(this.state.type === "CLIENT") {
      this.setState({ authorized: (roleType == "admin" || roleType == "stratamanager" || roleType == "propertymanager") });
    }
    else {
      this.setState({ authorized: (roleType == "admin") });
    }
    
    if(!this.state.authorized)
      return;

    await this.loadUsers();
  }

  async loadUsers() {
    //this.props.setLoading(true);
    const users = await this.getUsers();
    this.setState({ users });
    //this.props.setLoading(false);
  }

  async getUsers() {
    Amplify.configure(strataApiConfig);

    let users = [];
    
    try {
      const params = {};

      const cmd = `
        query {
          users: listUsers(
            limit: 10000
          ){
            items {
              id
              contact_mobile
              contact_email
              person_firstName
              person_middleName
              person_lastName
              personas {
                items {
                  roleType
                  roleSubType
                  actors {
                    items {
                      foreignId
                      foreignType
                      plan {
                        id
                        parentId
                        planName
                        parentPlan {
                          id
                          planName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`;

      const result = await API.graphql(graphqlOperation(cmd, params));

      console.log(result);

      if (result.data != null && result.data.users.items.length > 0) { 
        users = result.data.users.items; 
      }
    } catch (e) {
      console.log(e);
      //alert(JSON.stringify(e));
      if (e.hasOwnProperty("data") && e.data != null && e.data.users != null) {
        users = e.data.users.items;
      }
    }

    const userId = Cache.getItem("email");
    const roleType = Cache.getItem("roleType");

    if(this.state.type === "ADMIN") {
      this.setState({ downloadFileName: "admins.csv" });
      const listRoleType = "admin";
      users = users.filter(x => x.personas.items.find(x => x.roleType === listRoleType));
    
      if(roleType !== "admin") {
        users = [];
      }
    }
    else if(this.state.type === "STRATA_MANAGER") {
      this.setState({ downloadFileName: "strata-managers.csv" });
      const listRoleType = "stratamanager";
      users = users.filter(x => x.personas.items.find(x => x.roleType === listRoleType));
    
      if(roleType !== "admin") {
        const planIds = await Utils.getAccessPlanIds(userId, roleType);

        users = users
          .filter(x => 
            x.personas.items.find(y => 
              y.roleType === listRoleType &&
              y.actors.items.find(z => z.foreignType === "strata" && planIds.includes(z.foreignId))
            )
          );
      }
    }
    else if(this.state.type === "PROPERTY_MANAGER") {
      this.setState({ downloadFileName: "property-managers.csv" });
      const listRoleType = "propertymanager";
      users = users.filter(x => x.personas.items.find(x => x.roleType === listRoleType));
    
      if(roleType !== "admin") {
        const planIds = await Utils.getAccessPlanIds(userId, roleType);

        users = users
          .filter(x => 
            x.personas.items.find(y => 
              y.roleType === listRoleType &&
              y.actors.items.find(z => z.foreignType === "strata" && planIds.includes(z.foreignId))
            )
          );
      }
    }
    else if(this.state.type === "SUPPLIER") {
      this.setState({ downloadFileName: "suppliers.csv" });
      const listRoleType = "supplier";
      users = users.filter(x => x.personas.items.find(x => x.roleType === listRoleType));
    
      if(roleType !== "admin") {
        const planIds = await Utils.getAccessPlanIds(userId, roleType);

        users = users
          .filter(x => 
            x.personas.items.find(y => 
              y.roleType === listRoleType &&
              y.actors.items.find(z => z.foreignType === "strata" && planIds.includes(z.foreignId))
            )
          );
      }
    }
    else if(this.state.type === "CLIENT") {
      this.setState({ downloadFileName: "clients.csv" });
      users = users
        .filter(x => x.personas.items.find(x => Helpers.isClientRole(x.roleType)));
    
      if(roleType !== "admin") {
        const planIds = await Utils.getAccessPlanIds(userId, roleType);

        users = users
          .filter(x => 
            x.personas.items.find(y => 
              Helpers.isClientRole(y.roleType) &&
              y.actors.items.find(z => z.foreignType === "lot" && z.plan != null && planIds.includes(z.plan.parentId))
            )
          );
      }
    }
    else{      
      this.setState({ downloadFileName: "users.csv" });
    
      if(roleType !== "admin") {
        users = [];
      }
    }

    users = users.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1); 

    return users.map((data) => {
      return {
        id: data.id,
        name: data.person_firstName + " " + Helpers.isNull(data.person_middleName, "") + " " + data.person_lastName,
        mobile: data.contact_mobile,
        email: data.contact_email,
        roleTypes: data.personas.items.filter(x => x.roleType !== "guest").map(x => Enums.getRoleLabel(x.roleType)).join(", "),
        roleSubTypes: data.personas.items.filter(x => (x.roleSubType ?? "") !== "").map(x => Enums.getSubRoleLabel(x.roleSubType)).join(", ")
      };
    });
  }

  listActors = (userId, roleType, foreignType) => {
    Amplify.configure(strataApiConfig);

    const params = { 
      userId: userId,
      roleType: roleType,
      foreignType: foreignType
     };

    const cmd = `
      query(
        $userId: String
        $roleType: String
        $foreignType: String
      ){
        actors: listActors(
            filter: {
                userId: { eq: $userId }
                roleType: { eq: $roleType }
                foreignType: { eq: $foreignType }
            }
            limit: 1000
        ){
            items {
                foreignId
                foreignType
            }
        }
      }`;

    return API.graphql(graphqlOperation(cmd, params));
  }

  render() {
    if(this.state.authorized === false){
      return (<UnauthPanel />);
    }

    const supplierList = this.state.type === "SUPPLIER";
    const clientList = this.state.type === "CLIENT";

    const columns = [
      {
       name: "id",
       label: "---",
       options: {
        display: "excluded",
        filter: false,
        sort: false
       }
      },
      {
       name: "name",
       label: "Name",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Name: ${v}` },
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <a href={`/users/detail/${tableMeta.rowData[0]}`}>{value}</a>
            );
          }
        }
      },
      {
       name: "email",
       label: "Email",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Email: ${v}` },
        filterType: "textField"
       }
      },
      {
       name: "mobile",
       label: "Mobile",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Mobile: ${v}` },
        filterType: "textField"
       }
      },
      {
       name: "roleTypes",
       label: "Roles",
       options: {
        display: clientList ? "true" : "excluded",
        filter: clientList,
        sort: clientList,
        customFilterListOptions: { render: v => `Role: ${v}` },
        filterType: 'multiselect',
        filterOptions: {
          logic: (value, filters) => {
            console.log(value);
            if (filters.length) 
              return !filters.find(x => value.split(", ").map(x => x.toLowerCase()).includes(x.toLowerCase()));
            return false;
          },
          names: ['Owner', 'Owner Occupier', 'Tenant', 'Committee']
        }
       }
      },
      {
       name: "roleSubTypes",
       label: "Category",
       options: {
        display: supplierList ? "true" : "excluded",
        filter: supplierList,
        sort: supplierList,
        customFilterListOptions: { render: v => `Category: ${v}` }
       }
      }
     ];

    const options = {
      filterType: "dropdown",
      selectableRows: false,
      responsive: "stacked",
      elevation: 0,
      rowHover: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10,50,100],
      searchOpen: false,
      print: false,
      download: false,
      viewColumns: false,
      hint: "",
      downloadOptions: {
        filename: this.state.downloadFileName, 
        separator: ','
      }
    };

    return (
      <React.Fragment>
        <div className="page-title">
          <h1>
            {
              this.state.type === "ADMIN"
                ? "Admins"
                : this.state.type === "STRATA_MANAGER"
                  ? "Strata Managers" 
                  : this.state.type === "PROPERTY_MANAGER"
                    ? "Property Manager"
                    : this.state.type === "CLIENT"
                      ? "Clients"
                      : this.state.type === "SUPPLIER"
                        ? "Suppliers"
                        : "Users"
            }
          </h1>
        </div>
        <div className="card">
          <div className="card-body nopdt">
            {
              this.state.users == null 
              ? <div className="pdt-lg pdb-sm text-center"><img src="/images/loading.svg" /></div>
              : <MUIDataTable
                  data={this.state.users}
                  columns={columns}
                  options={options}
                />
            }
          </div>
        </div>
      </React.Fragment>      
    );
  }
}

export default UserIndex;
