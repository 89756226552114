import React from "react";

export default ({ props }) => {
  return(
    <div className="footer">
      <div className="container clearfix">
        <ul>
          <li><a href="#">System Status</a></li>
          <li>|</li>
          <li><a href="/terms">Terms of Use</a></li>
          <li>|</li>
          <li><a href="/privacy">Privacy Policy</a></li>
        </ul>
      </div>
    </div>
  );
};