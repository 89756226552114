import React from "react";

class UnauthPanel extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-title">
          <h1>Unauthorized Access</h1>
        </div>
        <div className="card">
          <div className="card-body">
            You are not authorized to view this page
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UnauthPanel;