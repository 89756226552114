import React from "react";
import moment from 'moment';

class NoteItem extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <React.Fragment>
        <div className="issue-note-item clearfix">
          <img src="/images/green-dot.png" className="issue-timeline-dot" alt=""/>
          <div className="status">
            <div className="color-grey text-bold">
              <div className="text-light issue-status-date">{moment(new Date(data.createdAt)).format("ddd, DD MMM YYYY hh:mm A")}</div>
            </div>
            <img src="/images/icons/chat_bubble-24px.svg" className="pull-left issue-status-img mgr mgt-sm"  alt=""/>
            <div className="issue-status-label mgt-sm">{data.note_comment}</div>
            <div className="text-light color-grey issue-status-text">by ---</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default NoteItem;