import React from "react";
import Amplify, { API, Cache, graphqlOperation } from "aws-amplify";
import * as Helpers from "libs/Helpers";
import * as Enums from "libs/Enums";
import * as Utils from "libs/Utils";
import * as FileS3 from "libs/FileS3";
import config from "config";
import strataApiConfig from "config-api-STRATA";
import utilsApiConfig from "config-api-UTILS";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { TextField, Button, Dialog, DialogTitle, DialogContent, InputLabel, MenuItem, Select, FormControl, FormHelperText } from "@material-ui/core";
import NoteItem from './parts/NoteItem';
import AttachmentItem from './parts/AttachmentItem';

Amplify.configure(utilsApiConfig);

class QuoteDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      workOrder: null,
      activeTab: 1,
      uploadOpen: false,
      selectedFile: null,
      attachmentSubType: null,
      attachmentSubTypeOptions: [],
      fileCaption: "",
      noteComment: "",
      inputStates: {
        noteComment: "",
        selectedFile: "",
        fileCaption: "",
        attachmentSubType: ""
      },
      inputErrors: {
        noteComment: "",
        selectedFile: "",
        fileCaption: "",
        attachmentSubType: ""
      }
    };
  }

  async componentDidMount() {
    this.props.setLoading(true);
    await this.loadWorkOrder();

    const attachmentSubTypes = await Enums.getAttachmentSubTypes();
    this.setState({ attachmentSubTypeOptions: attachmentSubTypes});

    this.props.setLoading(false);
  }

  getPersona = async (userId, roleType) => {
    Amplify.configure(strataApiConfig);

    const params = {
      userId: userId,
      roleType: roleType
    };

    const cmd = `
    query(
      $userId: String
      $roleType: String
    ){ 
      personas: listPersonas(
        filter: {
          userId: { eq: $userId }
          roleType: { eq: $roleType }
        }
        limit: 1000
      )
      {
        items {
          id
          supplier_companyName
          supplier_website
          contact_email
          contact_mobile
          contact_telephone
          supplier_availabilityHour
          user {
            person_firstName
            person_lastName
            contact_email
            contact_mobile
            contact_telephone
          }
        } 
      }
    }`;

    const result = await API.graphql(graphqlOperation(cmd, params));

    console.log("result", result);

    return result.data.personas.items[0];
  };

  loadWorkOrder = async () => {
    Amplify.configure(strataApiConfig);

    const cmd = `
      query(
        $id: ID!
      ) {
        txn: getTxn(
          id: $id
        ){
          id
          refId 
          txnType
          quote_amount
          quote_description
          work_proposedStartAt
          createdBy
          mediumId
          createdUser {
            person_firstName
            person_lastName
          }
          medium {
            id
            media_fileKey
            media_title
            media_mimeType
          }
          notes(
            sortDirection: DESC
            limit: 1000
          ) {
              items {
                  id
                  createdAt
                  note_comment
                  attachmentType
                  text_type
              }
          }
          media(
            sortDirection: ASC
            limit: 1000
          ) {
              items {
                id
                media_fileKey
                media_title
                media_mimeType
                attachmentSubType
              }
          }
        }
      }`;

    const result = await API.graphql(
      graphqlOperation(cmd, { id: this.state.id })
    );

    console.log("result", result);

    const workOrder = result.data.txn;

    var persona = await this.getPersona(workOrder.createdBy, "supplier");
    
    var userFullName = Helpers.isNullOrBlank(persona.user.person_firstName, "") + " " + Helpers.isNullOrBlank(persona.user.person_lastName, "") 
    const workOrderViewModel = {
      workOrderId: workOrder.refId,
      amount: workOrder.quote_amount == undefined ? null : workOrder.quote_amount,
      companyName:  Helpers.isNullOrBlank(persona.supplier_companyName, userFullName),
      website: persona.supplier_website,
      email: Helpers.isNullOrBlank(persona.contact_email, persona.user.contact_email),
      mediumId : workOrder.mediumId,
      tradesman: userFullName,
      mobileNo: Helpers.isNullOrBlank(persona.contact_mobile, persona.user.contact_mobile),
      phoneNo: Helpers.isNullOrBlank(persona.contact_telephone, persona.user.contact_telephone),
      proposedStartDate: workOrder.work_proposedStartAt,
      proposedStartTime: workOrder.work_proposedStartAt,
      desc: workOrder.quote_description
    };

    workOrderViewModel.documentAssets = await Helpers.formatMediaToAssets(workOrder.media.items);
    workOrderViewModel.noteItems =  workOrder.notes.items;
    
    this.setState({ workOrder: workOrderViewModel });
  };

  activateTab = (event, index) => {
    event.preventDefault();
    this.setState({ activeTab: index });
  };
  
  openDialogUpload  = () => {
    this.setState({ uploadOpen: true });
  };

  closeDialogUpload = () => {
    this.setState({ uploadOpen: false, selectedFile: null, fileCaption: "" });
  };
  
  closeDialog = () => {
    this.setState({ modalOpen: false, selectedFile: null, fileCaption: "" });
  }

  handleChange = async (name, value, required) => {
    this.setState({ [name]: value }, () => {
      if (required) {
        this.validateRequired(name);
      }
    });
  }

  validateRequired = (name) => {
    let valid = false;
    const value = this.state[name];
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (value == null || value === "") {
      inputStates[name] = "error";
      inputErrors[name] = "This field is required";
    } else {
      valid = true;
      inputStates[name] = "success";
      inputErrors[name] = "";
    }
    
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    return valid;
  }
  
  handleMediaChange = (event) => {
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (event.target.files.length === 0) {
      inputStates["selectedFile"] = "";
      inputErrors["selectedFile"] = "";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      this.setState({selectedFile: null});
      return;
    } 

    if (event.target.files.length > 1) {
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = "Multiple files were slected. Please select one file";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    } 

    var file = event.target.files[0];
    
    if (!Helpers.checkLegitImage(file.name)
      && !Helpers.checkLegitAudio(file.name)
      && !Helpers.checkLegitVideo(file.name)) {
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = "This file format is not supported";
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    const category = Helpers.getFileCategory(file.name);

    const maxFileSize = category === "video" 
      ? config.MAX_ATTACHMENT_SIZE_VIDEO
      : config.MAX_ATTACHMENT_SIZE_FILE;

    if (file.size > maxFileSize) {
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = `Please select a file smaller than ${maxFileSize / 1000000} MB.`;
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    } 

    inputStates["selectedFile"] = "success";
    inputErrors["selectedFile"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });
    this.setState({selectedFile: file});
  }

  handleAddMedia = async () => {
    let valid = true;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (this.state.selectedFile == null) {
      valid = false;
      inputStates["selectedFile"] = "error";
      inputErrors["selectedFile"] = "Media file is required";
    } 

    if (this.state.fileCaption == null || this.state.fileCaption === "") {
      valid = false;
      inputStates["fileCaption"] = "error";
      inputErrors["fileCaption"] = "This field is required";
    } 

    if(!valid) {
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    inputStates["selectedFile"] = "success";
    inputErrors["selectedFile"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    this.props.setLoading(true);

    Amplify.configure(strataApiConfig);
    
    try {
      const quoteId = this.state.id;
      const fileCaption = this.state.fileCaption;
      const selectedFile = this.state.selectedFile;
      const formattedName = selectedFile.name
        .replace("-", " ")
        .replace("_", " ")
        .replace(/[^a-zA-Z0-9\. ]/g, "")
        .replace(/[ ]+/g, "-");
      const fileName = (Helpers.generateGuid() + "_" + formattedName).toLowerCase(); // must not have spaces in file names

      const prefix = "quote/" + quoteId;
      var result = await FileS3.upload(selectedFile, prefix, fileName);     
      
      const annotation = fileCaption;
      const attachmentSubType = "";

      const respAttachment = await Utils.createAttachmentMedia(
        quoteId, 
        "strata",
        result.privateFileKey, 
        result.privateFileURL,
        fileCaption,
        selectedFile.type.toLowerCase(),
        selectedFile.size / (1024 * 1024),
        attachmentSubType,
        annotation); 

      const attachmentId = respAttachment.data.createAttachment.id;

      console.log("respAttachment" ,respAttachment);
      const userId = Cache.getItem("email");
      const roleType = Cache.getItem("roleType");

      await Utils.createMachine(
        attachmentId, 
        "attach", 
        quoteId,
        "strata",
        "thinking", 
        "ticketed",
        userId,
        roleType);

    } catch (e) {
      console.log("Upload Log",e);
      alert(JSON.stringify(e));
    }

    await this.loadWorkOrder();
    this.props.setLoading(false);
    this.closeDialogUpload();
  }
  
  handleAddNote = async () => {
    let valid = true;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (this.state.noteComment == null || this.state.noteComment === "") {
      valid = false;
      inputStates["noteComment"] = "error";
      inputErrors["noteComment"] = "This field is required";
    } 

    if(!valid) {
      this.setState({ inputStates: inputStates, inputErrors: inputErrors });
      return;
    }

    inputStates["noteComment"] = "success";
    inputErrors["noteComment"] = "";
    this.setState({ inputStates: inputStates, inputErrors: inputErrors });
    
    this.props.setLoading(true);

    const quoteId = this.state.id;

    try {
      const respAttachment = await Utils.createAttachmentNote(quoteId, "strata", this.state.noteComment);
      console.log("respAttachment", respAttachment);
      const attachmentId = respAttachment.data.createAttachment.id;
      const userId = Cache.getItem("email");
      const roleType = Cache.getItem("roleType");

      var machineResp = await Utils.createMachine(
        attachmentId, 
        "attach", 
        quoteId,
        "strata",
        "thinking", 
        "ticketed",
        userId,
        roleType);

      await this.loadWorkOrder();
      this.setState({ noteComment: "" });
    }
    catch (e) {
      //
    }

    this.props.setLoading(false);
  }

  handleDeleteMedia = async (id) => {
   
    if(!window.confirm('Are you sure you want to delete?')){
      return;
    }

    try {
      await Utils.deleteAttachment(id);
      // Set primary image
      if(this.state.workOrder.mediumId == id){
        await Utils.updateTxnMedium(this.state.id, null);
      }
      await this.loadWorkOrder();
    }
    catch (e) {
      console.log(e);
    }
  }
  
  renderNotes = items => {
    return items.map((item, index) => 
        <NoteItem 
          key={index} 
          data={item} />);
  }
  
  renderAttachments = (items, canManage) => {
    return items.map((item, index) => 
        <AttachmentItem 
          key={index} 
          data={item}
          canManage={canManage}
          handleDeleteMedia={(e) => {this.handleDeleteMedia(item.id);} } />);
  }

  render() {

    const workOrder = this.state.workOrder;
    if (workOrder == null && this.props.isLoading) {
      return null;
    }

    if (workOrder == null && !this.props.isLoading) {
      return <div className="pdt-lg">Invalid work order</div>;
    }

    const roleType = Cache.getItem("roleType");
    const canManage = roleType === "admin" || roleType === "stratamanager";
    console.log('workOrder.documentAssets', workOrder.documentAssets);

    return (
      <React.Fragment>
        <div className="page-title clearfix">
          <h1 className="pull-left">Work Order</h1>
        </div>
        <div className="row">
          <div className="col-lg-6 issue-list col-issue-left">
            <div className="issue-item active clearfix">
              <div className="card-body pdl pdr disabled-view-only-white mg">
                <form>
                  <div className="">
                    <div className="row">
                      <div className="col-sm-6 col-xs-12 pdb-lg color-grey">
                        <TextField
                          fullWidth
                          disabled={true}
                          label="Work Order Number"
                          value={Helpers.isNullOrBlank(workOrder.workOrderId, "---")}
                        />
                      </div>
                      <div className="col-sm-6 col-xs-12 pdb-lg">
                        <TextField
                          disabled={true}
                          fullWidth
                          label="Amount (GST inc)."
                          value={workOrder.amount == null ?  "---" : Helpers.formatCurrency(workOrder.amount)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-sm-6 col-xs-12 pdb-lg color-grey">
                        <TextField
                          fullWidth
                          disabled={true}
                          label="Company Name"
                          value={Helpers.isNullOrBlank(workOrder.companyName, "---")}
                        />
                      </div>
                      <div className="col-sm-6 col-xs-12 pdb-lg">
                        <TextField
                          disabled={true}
                          fullWidth
                          label="Website"
                          value={Helpers.isNullOrBlank( workOrder.website, "---")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-sm-6 col-xs-12 pdb-lg color-grey">
                        <TextField
                          fullWidth
                          disabled={true}
                          label="Email"
                          value={Helpers.isNullOrBlank(workOrder.email, "---")}
                        />
                      </div>
                      <div className="col-sm-6 col-xs-12 pdb-lg">
                        <TextField
                          fullWidth
                          disabled={true}
                          label="Tradesman"
                          value={Helpers.isNullOrBlank(workOrder.tradesman, "---")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-sm-6 col-xs-12 pdb-lg color-grey">
                        <TextField
                          fullWidth
                          disabled={true}
                          label="Mobile No"
                          value={Helpers.isNullOrBlank(workOrder.mobileNo, "---")}
                        />
                      </div>
                      <div className="col-sm-6 col-xs-12 pdb-lg">
                        <TextField
                          fullWidth
                          disabled={true}
                          label="Phone No"
                          value={Helpers.isNullOrBlank(workOrder.phoneNo, "---")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-sm-6 col-xs-12 pdb-lg color-grey">
                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            fullWidth
                            disableToolbar
                            disabled={true}
                            label="Proposed Start Date"
                            format="dd/MM/yyyy"
                            variant="inline"
                            value={workOrder.proposedStartDate}
                            keyboardIcon={null}
                            autoComplete="off"
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-sm-6 col-xs-12 pdb-lg">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            autoOk
                            fullWidth
                            disabled={true}
                            label="Start Time"
                            variant="inline"
                            value={workOrder.proposedStartTime}
                            keyboardIcon={null}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-sm-12 pdb-lg color-grey">
                        <TextField
                          fullWidth
                          disabled={true}
                          multiline={true}
                          label="Description"
                          value={Helpers.isNullOrBlank(workOrder.desc, "---")}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-issue-right">
            <div className="tabs-wrap">
              <ul className="tabs clearfix">
                <li className={this.state.activeTab == 1 ? "active" : ""}>
                  <a href="#"
                    onClick={e => {
                      this.activateTab(e, 1);
                    }}
                  >
                    <img
                      src="/images/icons/featured_play_list-24px.svg"
                      alt=""
                      className="icon"
                    />
                    Documents
                  </a>
                </li>
                <li className={this.state.activeTab == 2 ? "active" : ""}>
                  <a
                    href="#"
                    onClick={e => {
                      this.activateTab(e, 2);
                    }}
                  >
                    <img
                      src="/images/icons/notifications-24px.svg"
                      alt=""
                      className="icon"
                    />
                    Notes
                  </a>
                </li>
              </ul>
            </div>

            <div
              className={`tab-view ${
                this.state.activeTab == 1 ? "active" : ""
              }`}
            >
              <div className="prop-docs">
                {this.renderAttachments(workOrder.documentAssets, canManage)}
              </div>

              {canManage && (
                <div className="pdt-lg pdb-lg clearfix">
                  <a
                    href="#"
                    className="action-btn white pull-right"
                    onClick={e => {
                      e.preventDefault();
                      this.openDialogUpload();
                    }}
                  >
                    <img
                      src="/images/icons/cloud_upload-24px.svg"
                      alt=""
                      className="icon"
                    />
                    Upload a file
                  </a>
                </div>
              )}
            </div>

            <div
              className={`tab-view ${
                this.state.activeTab == 2 ? "active" : ""
              }`}
            >
              <div className="issue-control-wrap">
                <textarea
                  rows="3"
                  placeholder="note..."
                  value={this.state.noteComment}
                  onChange={(e) => this.handleChange("noteComment", e.target.value) }
                />
              </div>

              <span className="text-danger small">
                {this.state.inputErrors["noteComment"]}
              </span>

              <div className="pdt pdb-lg clearfix">
                <a href="#"
                  className="action-btn pull-right"
                  onClick={e => {
                    e.preventDefault(); this.handleAddNote();
                  }}>
                  <img
                    src="/images/icons/send-24px.svg"
                    alt=""
                    className="icon"
                  />
                  Add a note
                </a>
              </div>
              { this.renderNotes(workOrder.noteItems) }
            </div>
          </div>
        </div>
        
        <Dialog
          open={this.state.uploadOpen}
          onClose={() => this.closeDialogUpload()}
          disableBackdropClick={false}
          aria-labelledby="form-dialog-title">
            <div className="modal-popup sm">
              <DialogTitle
                id="form-dialog-title"
                className="text-bold">
                  Upload File
              </DialogTitle>
              <DialogContent className="pdb-lg">
                <div className="pdb-lg">
                  <label>File</label>
                  <div className="clearfix">
                    <label htmlFor="media_input" className="fu-wrap">
                      <span>{this.state.selectedFile == null ? "Browse..." : this.state.selectedFile.name}</span>
                      <input 
                        type="file" 
                        id="media_input" 
                        onChange={(e) => this.handleMediaChange(e)} />
                    </label>
                  </div>
                  <span className="text-danger small">{this.state.inputErrors.selectedFile}</span>
                </div>
                
                <div className="pdb-lg">
                  <FormControl fullWidth>
                    <InputLabel
                      error={this.state.inputStates.attachmentSubType === "error"}
                      id="attach-subtype-label">
                        Category
                    </InputLabel>
                    <Select
                      labelId="attach-subtype-label"
                      value={this.state.attachmentSubType ?? ""}
                      onChange={(e) => this.handleChange("attachmentSubType", e.target.value, true)}
                    >
                      <MenuItem key="-1" value="">
                        <em>...</em>
                      </MenuItem>
                      {
                        this.state.attachmentSubTypeOptions.map((item, index) => 
                          <MenuItem key={index} value={item.value1}>{item.value2}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                  <FormHelperText error>{this.state.inputErrors.attachmentSubType}</FormHelperText>
                </div>

                <div className="pdb-lg">
                  <TextField
                    fullWidth
                    error={this.state.inputStates.fileCaption === "error"}
                    label="Caption"
                    value={this.state.fileCaption}
                    onChange={(e) => this.handleChange("fileCaption", e.target.value, true)}
                    autoComplete= "off"
                    helperText={this.state.inputErrors.fileCaption} />
                </div>
                
                <div className="clearfix">
                  <Button
                    className="btn btn-secondary mgt pull-left"
                    onClick={() => this.closeDialogUpload()}>
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary mgt pull-right"
                    onClick={() => this.handleAddMedia()}>
                    Upload
                  </Button>
                </div>
              </DialogContent>
            </div>
        </Dialog>
      
      </React.Fragment>
    );
  }
}

export default QuoteDetail;
