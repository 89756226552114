// API
const config = {
    API: {
      graphql_endpoint: process.env.REACT_APP_STRATA_URL,
      graphql_headers: async () => ({
        'x-api-key': process.env.REACT_APP_STRATA_KEY,
        'tenantId': process.env.REACT_APP_TENANTID
      })
    }
};
export default config;
