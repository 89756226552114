import React from "react";
import Amplify, { API, Auth, Cache, graphqlOperation } from "aws-amplify";
import * as Helpers from "libs/Helpers";
import config from "config";
import strataApiConfig from "config-api-STRATA";
import { TextField } from "@material-ui/core";
import LoaderButton from "views/shared/LoaderButton";
import Footer from "views/layouts/parts/Footer";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      genericError: "",
      disableSubmit: false,
      collapsed: Helpers.queryString("land") != null,
      inputStates: {
        email: "",
        password: ""
      },
      inputErrors: {
        email: "",
        password: ""
      }
    };
  }

  async componentDidMount(){
    await Auth.signOut();
    await Cache.clear();

    this.props.setLoading(false);

    if(this.state.collapsed) {
      setTimeout(() => {
          this.setState({collapsed: false})
      }, 0);
    }
  }

  validateEmail = value => {
    let valid = false;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (Helpers.verifyEmail(value)) {
      valid = true;
      inputStates["email"] = "success";
      inputErrors["email"] = "";
    } else {
      valid = false;
      inputStates["email"] = "error";
      inputErrors["email"] = "Invalid email address";
    }

    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    return valid;
  }

  validatePassword = value => {
    let valid = false;
    const inputStates = this.state.inputStates;
    const inputErrors = this.state.inputErrors;

    if (!Helpers.verifyLength(value, 1)) {
      valid = false;
      inputStates["password"] = "error";
      inputErrors["password"] = "This field is required";
    } else {
      valid = true;
      inputStates["password"] = "success";
      inputErrors["password"] = "";
    }

    this.setState({ inputStates: inputStates, inputErrors: inputErrors });

    return valid;
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });

    if (name == 'email') {
      this.validateEmail(value);
    }
    else if (name == 'password') {
      this.validatePassword(value);
    }
  }

  handleSubmit = async event => {
    event.preventDefault();

    if (this.state.disableSubmit) {
      return false;
    }

    // Done like this to avoid logical shortcut
    let valid = true;
    valid = this.validateEmail(this.state.email) && valid;
    valid = this.validatePassword(this.state.password) && valid;

    //console.log(valid);

    if (!valid) {
      //alert('Please fix the errors');
      return false;
    }

    await Cache.clear();

    //this.props.setLoading(true);
    this.setState({ disableSubmit: true });

    const email = this.state.email.toLowerCase();
    const redirectUrl = Helpers.queryString("redirect");

    try {
      // Test credentials against Cognito IDM
      await Auth.signIn(email, this.state.password);

      const respUser = await this.getUser(email);
      const user = respUser.data.user;
      //console.log(user);

      const personas = user.personas.items;
      const name = user.person_firstName + " " + user.person_lastName;

      const now = new Date();
      const expiry = now.setMinutes(now.getMinutes() + config.AUTH_SESSION_DURATION_MINUTES);

      //console.log(expiry);

      await Cache.setItem("email", email, { expires: expiry });
      await Cache.setItem("name", name, { expires: expiry });
      await Cache.setItem("personas", personas.length, { expires: expiry });

      if(personas.length == 1) {
        const roleType = personas[0].roleType;

        const planActors = Helpers.isClientRole(roleType)
          ? personas[0].actors.items.filter(function(x) { return x.foreignType === "lot"; })
          : personas[0].actors.items.filter(function(x) { return x.foreignType === "strata"; });

        await Cache.setItem("roleType", roleType, { expires: expiry });
        await Cache.setItem("plans", planActors.length, { expires: expiry });

        if(planActors.length > 0) {
          await Cache.setItem("planId", planActors[0].foreignId, { expires: expiry });
          await Cache.setItem("planName", planActors[0].plan.planName, { expires: expiry });
        }

        await this.props.setAuthenticated(true);
        this.props.redirect(redirectUrl == null ? "/dashboard" : redirectUrl);
      }
      else {
        await this.props.setAuthenticated(true);
        this.props.redirect("/account/personas");
      }
    } catch (e) {
      console.error(e);
      await Cache.clear();
      this.props.setAuthenticated(false);
      this.setState({ disableSubmit: false });
      if(e.code === "NotAuthorizedException") {
        this.setState({ genericError: e.message });
      }
      else {
        this.setState({ genericError: "There was an error. Please try again." });
        //alert("ERROR: " + JSON.stringify(e));
      }
    }
  }

  getUser = userId => {
    Amplify.configure(strataApiConfig);

    const params = { id: userId };

    const cmd = `
      query(
        $id: ID!
      ){ 
          user: getUser(
              id: $id
          ) {
              id
              person_firstName
              person_lastName
              personas {
                  items {
                      id
                      userId
                      roleType
                      actors {
                          items {
                              id
                              userId
                              roleType
                              foreignId
                              foreignType
                              plan {
                                planName
                              }
                          }
                      }
                  }
              }
      
          } 
      }`;

    return API.graphql(graphqlOperation(cmd, params));
  }

  render() {
    // if (this.props.isAuthenticated) {
    //   return (<Redirect to="/dashboard" />);
    // }

    return (
      <div id="login-screen" className={this.state.collapsed ? "collapsed" : ""}>
        <div className="login-container">

          <div className="login-header">
            <div className="login-logo">
              <a href="/"><img src="/images/fixxt_logo.png" /></a>
            </div>
            <div>Please login to your account</div>
          </div>

          <div className="card light mgt-lg">
            <div className="card-body nopdt">

              <div className="login-form">
                <form onSubmit={this.handleSubmit}>

                  <div className={`text-danger text-center pdt pdb ${this.state.genericError === "hidden" ? "" : ""}`}>{this.state.genericError}</div>

                  <div className="pdb-lg">
                    <TextField
                      fullWidth
                      error={this.state.inputStates.email === "error"}
                      label="Email"
                      id="email"
                      onChange={(e) => this.handleChange("email", e.target.value)}
                      autoComplete="off"
                      helperText={this.state.inputErrors.email}
                    />
                  </div>

                  <div className="pdb-lg">
                    <TextField
                      fullWidth
                      error={this.state.inputStates.password === "error"}
                      label="Password"
                      id="password"
                      onChange={(e) => this.handleChange("password", e.target.value)}
                      type="password"
                      autoComplete="off"
                      helperText={this.state.inputErrors.password}
                    />
                  </div>

                  <div className="pdt text-center">
                    <LoaderButton
                      className="btn btn-success"
                      type="submit"
                      text="Login"
                      loadingText="Logging in..."
                      disabled={this.state.disableSubmit}
                      isLoading={this.state.disableSubmit}
                    />
                  </div>

                  <div className="pdt-lg pdb text-center">
                    <a href="/forgot-password">Forgot Password?</a>
                  </div>

                </form>
              </div>

            </div>
          </div>
          
          <Footer />

        </div>
      </div>
    );
  }
}

export default Login;