import React from "react";
import Amplify, { API, Cache, graphqlOperation } from "aws-amplify";
import * as Helpers from "libs/Helpers";
import * as Utils from "libs/Utils";
import config from "config";
import strataApiConfig from "config-api-STRATA";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import UserPanel from "views/shared/UserPanel";
import UnauthPanel from "views/shared/UnauthPanel";

class PropertyLots extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorized: true,
      parentId: this.props.match.params.id,
      parentName: null,
      selectedPlanName: null,
      expandUser: null,
      expandUserRole: null,
      modalOpen: false,
      plans: null
    };
  }

  async componentDidMount() {
    const rolesHaveAccess = ["admin", "stratamanager", "propertymanager", "supplier", "owner"];
    const roleType = Cache.getItem("roleType");

    if(!rolesHaveAccess.includes(roleType)){
      this.setState({ authorized: false });
      return;
    }

    if(roleType !== "admin") {
      const userId = Cache.getItem("email");
      const planIds = await Utils.getAccessPlanIds(userId, roleType);

      if(!planIds.includes(this.state.parentId)){
        this.setState({ authorized: false });
        return;
      }
    }

    await this.loadPlans();
  }

  openDialog  = async (userId, role, selectedPlanName) => {
    this.setState({ modalOpen: true });
    const user = await this.getUser(userId);
    this.setState({ expandUser: user, expandUserRole: role, selectedPlanName: selectedPlanName });
  };

  closeDialog = () => {
    this.setState({ expandUser: null, modalOpen: false });
  };

  async loadPlans() {
    //this.props.setLoading(true);
    const plans = await this.getPlans();
    this.setState({ plans });

    const parentId = this.state.parentId;
    const parent = await Utils.getPlan(parentId);

    this.setState({ parentName: parent.planName });    

    if(!Helpers.isClientRole(Cache.getItem("roleType")) 
      && parentId !== Cache.getItem("planId")){
      const now = new Date();
      const expiry = now.setMinutes(now.getMinutes() + config.AUTH_SESSION_DURATION_MINUTES);

      await Cache.setItem("planId", parentId, { expires: expiry });
      await Cache.setItem("planName", parent.planName, { expires: expiry });
    }

    //this.props.setLoading(false);
  }

  async getPlans() {
    Amplify.configure(strataApiConfig);

    let plans = [];
    
    try {
      const params = { planType: "lot", parentId: this.state.parentId };

      const cmd = `
        query(
          $planType: String!
          $parentId: String!
        ) {
          plans: listPlans(
            filter: {
              planType: { eq: $planType }
              parentId: { eq: $parentId }
            },
            limit: 10000
          ){
            items {
              id
              parentId
              planType
              description
              planName
              createdAt
              address_name
              address_number
              address_street
              address_suburb
              address_postcode
              address_state
              address_country
              lot_parkingSpot
              lot_keyTag
              lot_keyTagCount
              lot_level
              lot_unit
              parentPlan {
                id
                planName
              }
              txns {
                txnType
                machines {
                  machineState {
                    stateType
                  }
                }
              }
              actors {
                userId
                roleType
                user {
                  person_firstName
                  person_lastName
                }
              }
            }
          }
        }`;

      const result = await API.graphql(graphqlOperation(cmd, params));

      console.log(result);

      if (result.data != null && result.data.plans.items.length > 0) { 
        plans = result.data.plans.items; 
      }

      const userId = Cache.getItem("email");
      const roleType = Cache.getItem("roleType");

      if(roleType === "propertymanager") {
        const respActors = await this.listActors(userId, roleType, "strata");
        const planIds = respActors.data.actors.items.map(x => x.foreignId);
        plans = plans.filter(x => planIds.includes(x.parentId));
      }
    } catch (e) {
      console.log(e);
      //alert(JSON.stringify(e));
      if (e.hasOwnProperty("data") && e.data != null && e.data.plans != null) {
        plans = e.data.plans.items;
      }
    }

    plans = plans.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1); 

    return plans.map((data) => {
      const owner = data.actors.filter(x => x.roleType === "owner" || x.roleType === "owneroccupier")[0];
      const occupier = data.actors.filter(x => x.roleType === "tenant" || x.roleType === "owneroccupier")[0];

      return {
        id: data.id.trim().split("\n")[0],
        name: data.planName,
        address: ((data.address_name ?? "") + " " + (data.address_number ?? "") + " " + (data.address_street ?? "") + " " + (data.address_suburb ?? "") + " " + (data.address_postcode ?? "") + " " + (data.address_state ?? "")).trim(),
        ownerId: owner == null ? null : owner.userId,
        ownerName: owner == null || owner.user == null ? "" : owner.user.person_firstName + " " + owner.user.person_lastName,
        occupierId: occupier == null ? null : occupier.userId,
        occupierName: occupier == null || occupier.user == null ? "" : occupier.user.person_firstName + " " + occupier.user.person_lastName
      };
    });
  }

  getPlan = async (id) => {
    Amplify.configure(strataApiConfig);
    
    const params = { id: id };

    const cmd = `
      query(
        $id: ID!
      ) {
        plan: getPlan(
          id: $id
        ){
          id
          planName
        }
      }`;

    const result = await API.graphql(graphqlOperation(cmd, params));

    return result.data.plan;
  }

  listActors = (userId, roleType, foreignType) => {
    Amplify.configure(strataApiConfig);

    const params = { 
      userId: userId,
      roleType: roleType,
      foreignType: foreignType
     };

    const cmd = `
      query(
        $userId: String
        $roleType: String
        $foreignType: String
      ){
        actors: listActors(
            filter: {
                userId: { eq: $userId }
                roleType: { eq: $roleType }
                foreignType: { eq: $foreignType }
            }
            limit: 1000
        ){
            items {
                foreignId
                foreignType
            }
        }
      }`;

    return API.graphql(graphqlOperation(cmd, params));
  }

  getUser = async (userId) => {
    Amplify.configure(strataApiConfig);
    
    const params = { id: userId };

    const cmd = `
      query(
        $id: ID!
      ) {
        user: getUser(
          id: $id
        ){
          id
          person_firstName
          person_middleName
          person_lastName
          contact_mobile
          contact_email
          address_name
          address_number
          address_street
          address_suburb
          address_postcode
          address_state
          personas {
            items {
              roleType
            }
          }
        }
      }`;

    const result = await API.graphql(graphqlOperation(cmd, params));

    const user = result.data.user;

    let data = {
      id: user.id,
      firstName: user.person_firstName,
      middleName: user.person_middleName,
      lastName: user.person_lastName,
      email: user.contact_email,
      mobile: user.contact_mobile,
      streetAddress: ((user.address_name ?? "") + " " + (user.address_number ?? "") + " " + (user.address_street ?? "")).trim(),
      suburb: user.address_suburb,
      state: user.address_state,
    };

    return data;
  }

  render() {
    if(this.state.authorized === false){
      return (<UnauthPanel />);
    }
    
    const columns = [
      {
       name: "id",
       label: "---",
       options: {
        display: 'excluded',
        filter: false,
        sort: false
       }
      },
      {
       name: "ownerId",
       label: "---",
       options: {
        display: 'excluded',
        filter: false,
        sort: false
       }
      },
      {
       name: "occupierId",
       label: "---",
       options: {
        display: 'excluded',
        filter: false,
        sort: false
       }
      },
      {
       name: "name",
       label: "Lot/Unit",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Lot: ${v}` },
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <a href={`/plans/detail/${tableMeta.rowData[0]}`}>{value}</a>
            );
          }
        }
      },
      {
       name: "address",
       label: "Address",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Address: ${v}` },
        filterType: "textField"
       }
      },
      {
       name: "ownerName",
       label: "Owner",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Owner: ${v}` },
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
            const userId = tableMeta.rowData[1];
            return (
              userId == null
              ? value
              : <a href="#" onClick={(e) => {e.preventDefault(); this.openDialog(userId, "owner", tableMeta.rowData[3]); }}>{value}</a>
            );
          }
        }
      },
      {
       name: "occupierName",
       label: "Occupier",
       options: {
        filter: true,
        sort: true,
        customFilterListOptions: { render: v => `Occupier: ${v}` },
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
            const userId = tableMeta.rowData[2];
            return (
              userId == null
              ? value
              : <a href="#" onClick={(e) => {e.preventDefault(); this.openDialog(userId, "occupier", tableMeta.rowData[3]); }}>{value}</a>
            );
          }
        }
      }
     ];

    const options = {
      filterType: "dropdown",
      selectableRows: false,
      responsive: "stacked",
      elevation: 0,
      rowHover: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10,50,100],
      searchOpen: false,
      print: false,
      download: false,
      viewColumns: false,
      hint: ""
    };

    return (
      <React.Fragment>
        <div className="page-title">
        <h1>{this.state.parentName == null ? "Lots" : ("Lots - " + this.state.parentName)}</h1>
        </div>
        <div className="card">
          <div className="card-body nopdt">
            {
              this.state.plans == null 
              ? <div className="pdt-lg pdb-sm text-center"><img src="/images/loading.svg" /></div>
              : <MUIDataTable
                  data={this.state.plans}
                  columns={columns}
                  options={options}
                />
            }
          </div>
        </div>

        <Dialog
          open={this.state.modalOpen}
          onClose={() => this.closeDialog()}
          disableBackdropClick={false}
          aria-labelledby="form-dialog-title">
            <div className="modal-popup sm">
              <DialogTitle
                id="form-dialog-title"
                className="text-bold">
                  {this.state.expandUserRole === "owner" ? "Owner" : "Occupier"} - {this.state.selectedPlanName}
              </DialogTitle>
              <DialogContent>
                <div className="bordered">
                  {
                    this.state.expandUser != null
                    ? (<UserPanel data={this.state.expandUser} />)
                    : (<div className="pdt-lg pdb-lg text-center"><img src="/images/loading.svg" alt="" /></div>)
                  }
                </div>
              </DialogContent>
            </div>
        </Dialog>
      </React.Fragment>      
    );
  }
}

export default PropertyLots;
