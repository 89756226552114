import React from "react";

export default () => {
  return(
    <div className="generic-panel-container">

      <div className="generic-panel-header">
        <div className="generic-panel-logo">
          <a href="/"><img src="/images/fixxt_logo.png" /></a>
        </div>
      </div>

      <div className="card light mgt-lg">
        <div className="card-body nopdt">
          <h1>404</h1>
          <div>Page not found</div>
        </div>
      </div>

      <div className="footer">
        <div className="container clearfix">
          <ul>
            <li><a href="/">Go to Home</a></li>
          </ul>
        </div>
      </div>

    </div>
  );
};
