import React from "react";
import { Redirect } from "react-router-dom";

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      landed: false,
      collapsed: true
    }
  }

  componentDidMount() {
    try {
      if(document.referrer 
        && document.referrer.indexOf(window.location.host) > -1) {
          this.setState({ redirect: true });
      }
      else{
        // Show loading
        this.setState({ landed: true });
        
        setTimeout(() => { this.setState({collapsed: false}) }, 0);

        setTimeout(() => this.setState({ redirect: true }), 2500);
      }
    }
    catch {
      this.setState({ redirect: true });
    }
  }

  render() {
    if (this.state.redirect) {
      var url = this.props.isAuthenticated ? "/dashboard" : "/login";

      if(this.state.landed){
        url += "?land=true"
      }

      return (<Redirect to={url} />);
    }

    return (
      <div className={`splash-screen ${this.state.collapsed ? "collapsed" : ""}`}>
        <img className="logo" src="/images/fixxt_logo.png" />
        <div className="desc">Solutions for tenants, owners, managers and tradesmen</div>
      </div>
    );
  }
}

export default Index;