import {default as UUID} from "uuid";
import Amplify, { Storage } from "aws-amplify";

function generateGuid() {
    return UUID.v4();
}

function queryString(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    
    if (!results[2]) {
        return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function verifyEmail (value) {
  if (value === "") 
    return false;

  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  return emailRex.test(value);
}

function verifyLength (value, length) {
  if (value.length >= length) {
    return true;
  }
  return false;
}

function capitalize(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

function isNullOrBlank(value, placeholder = "") {
  if(value != null && value !== ""){
    return value;
  }
  return placeholder;
}

function isNull(value, placeholder = "") {
  if(value != null){
    return value;
  }
  return placeholder;
}

function isBlank(value, placeholder) {
  if(value !== ""){
    return value;
  }
  return placeholder;
}

function formatCurrency(value) {
  if(value == null)
    return "";

  const nf = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return nf.format(value);
}

function formatCurrencyNoFractions(value) {
  if(value == null)
    return "";

  const nf = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  return nf.format(value);
}

function formatDate(date) {
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

function formatDateTime(date) {
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  var hour = date.getHours();
  var minute = date.getMinutes();

  return day + ' ' + monthNames[monthIndex] + ' ' + year + ' ' + hour + ':' + minute;
}

function calculateDateDiffInMin(startDate, endDate) {
  startDate.setSeconds(0);
  if(endDate == null)
  {
    endDate = new Date()
    endDate.setSeconds(0);
  }
  
  var difference = endDate.getTime() - startDate.getTime();
  return Math.round(difference / 60000);
}

function formatDateDiffMinutesToText(totalMinutes) {
  // debugger;
  const minutesInDay = 60 * 24;
  const days = Math.floor(totalMinutes / minutesInDay); 
  const hours = Math.floor((totalMinutes % minutesInDay) / 60);
  const minutes = Math.round(totalMinutes % 60);
 
  //console.log("dd/hh/mm", days + ":" + hours + ":" + minutes);

  let label = "";
  let daysLabel = days === 0
            ? ""
            : days === 1
                ? days + " day "
                :  days + " days ";

  label += daysLabel;

  let hoursLabel = hours === 0
            ?  ""
            : hours === 1
                ? hours + " hour "
                : hours + " hours ";

  label += hoursLabel;

  let minutesLabel = totalMinutes > 59 && minutes === 0
            ? ""
            : minutes === 1
                ? minutes + " min"
                : minutes + " mins";

  label += minutesLabel;

  return label.trim();
}

function getFileExtension(fileName) {
  return fileName.split('.').pop();
}

function getFileCategory(fileName) {
  return checkLegitImage(fileName) 
    ? "image"
    : checkLegitVideo(fileName) 
      ? "video"
      : checkLegitAudio(fileName) 
        ? "audio"
        : checkLegitDocument(fileName) 
          ? "document"
          : "unknown";
}

function checkLegitDocument(fileName) {
  const ext = getFileExtension(fileName).toLowerCase();
  const types = ["pdf", "txt", "doc", "docx", "xls", "xlsx"];
  return types.includes(ext);
}

function checkLegitImage(fileName) {
  const ext = getFileExtension(fileName).toLowerCase();
  const types = ["png", "jpg", "jpeg", "bmp", "gif", "tif"];
  return types.includes(ext);
}

function checkLegitAudio(fileName) {
  const ext = getFileExtension(fileName).toLowerCase();
  const types = ["mp3", "wav", "3gp"];
  return types.includes(ext);
}

function checkLegitVideo(fileName) {
  const ext = getFileExtension(fileName).toLowerCase();
  const types = ["avi", "wmv", "mpg", "mpeg", "mp4", "m4v", "m4p", "mov", "flv"];
  return types.includes(ext);
}

function isClientRole(roleType) {
  const clientRoleTypes = ["owner", "owneroccupier", "tenant", "committee"];
  return clientRoleTypes.includes(roleType);
}

async function  formatMediaToAssets(mediaItems){
  var assets = [];
  for(var i = 0; i < mediaItems.length; i++) {
    
    if(mediaItems[i].media_fileKey == null
      || mediaItems[i].media_fileKey === "") {

       if(mediaItems[i].media_mimeType == null)
       {
         continue;
       } 
        assets.push({
          id: mediaItems[i].id,
          title: mediaItems[i].media_title,
          category: mediaItems[i].media_mimeType.startsWith("image") ? "image" : "document",
          annotation: mediaItems[i].media_annotation,
          mimeType: mediaItems[i].media_mimeType,
          attachmentSubType: mediaItems[i].attachmentSubType,
          file: null,
          thumbnail: null
        });
        continue;
    }

    const fileKey = mediaItems[i].media_fileKey.replace("public/", "");
    const file =  await Storage.get(fileKey);
    const category = getFileCategory(fileKey);

    let thumbnail = null;

    if(category === "image"){
      const thumbKey = fileKey + "-thumbnail." + getFileExtension(fileKey);
      thumbnail =  await Storage.get(thumbKey);
    }

    assets.push({
      id: mediaItems[i].id,
      title: mediaItems[i].media_title,
      category: category,
      annotation: mediaItems[i].media_annotation,
      mimeType: mediaItems[i].media_mimeType,
      attachmentSubType: mediaItems[i].attachmentSubType,
      file: file,
      thumbnail: thumbnail
    });
  }
  
  return assets;
}

export { 
    generateGuid,
    queryString,
    verifyEmail,
    verifyLength,
    capitalize,
    isNullOrBlank,
    isNull,
    isBlank,
    formatCurrency,
    formatCurrencyNoFractions,
    formatDate,
    formatDateTime,
    getFileExtension,
    getFileCategory,
    checkLegitDocument,
    checkLegitImage,
    checkLegitAudio,
    checkLegitVideo,
    isClientRole,
    formatMediaToAssets,
    calculateDateDiffInMin,
    formatDateDiffMinutesToText
}

